import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { globalOrderDrafts } from '../state/globalOrderDrafts';
import { Page } from '../components/layout/Page/Page';
import { useFetchOrderDrafts } from '../hooks/fetch/useFetchOrderDrafts';
import { ListNavigator } from '../components/common/ListNavigator';
import { useOrderDraftNavigation } from '../hooks/useOrderDraftNavigation';
import { KeywordContext } from '../contexts/useKeywordContext';
import {
  DetailPanelContentContext,
  DetailPanelContentType,
} from '../contexts/useDetailPanelContentContext';
import {
  DetailPanelWidthContext,
  DetailPanelWidthType,
} from '../contexts/useDetailPanelWidthContext';
import { OrderProvider } from '../contexts/useOrderContext';
import { PlayRecordingProvider } from '../contexts/usePlayRecordingIndex';
import { TeamMembersProvider } from '../contexts/useTeamMembersContext';
import { globalUser } from '../state/globalUser';
import {
  MessageProviderType,
  MessagesProvider,
} from '../contexts/useMessagesContext';
import { BusinessSettingsProvider } from '../contexts/useBusinessSettingsContext';
import { SchemasProvider } from '../contexts/useSchemasContext';
import { ProcessOrderDrafts } from '../components/order/order-draft/ProcessOrderDrafts';

interface Props {
  navigateToHome: () => void;
}

const ProcessOrderDraftsPage = observer(({ navigateToHome }: Props) => {
  const {
    orderDrafts,
    setOrderDrafts,
    isLoading: isOrderDraftsLoading,
    loadOrderDrafts,
    reloadOrderDrafts,
  } = useFetchOrderDrafts({});

  const {
    currentIndex,
    setCurrentIndex,
    selectNextOrderDraft,
    selectPrevOrderDraft,
    onOrderDraftProcessed,
  } = useOrderDraftNavigation({ orderDrafts, setOrderDrafts, loadOrderDrafts });

  const [orderId, setOrderId] = useState<string>('');

  const [hoverKeyword, setHoverKeyword] = useState<string>('');
  const [scrollKeyword, setScrollKeyword] = useState<string>('');

  const [detailPanelWidth, setDetailPanelWidth] = useState<DetailPanelWidthType>(DetailPanelWidthType.SMALL);
  const [detailPanelContent, setDetailPanelContent] = useState<DetailPanelContentType>(
    DetailPanelContentType.ORDER_DRAFT_PROCESSING,
  );

  const keywordContextValue = useMemo(
    () => ({
      hoverKeyword,
      setHoverKeyword,
      scrollKeyword,
      setScrollKeyword,
    }),
    [hoverKeyword, scrollKeyword],
  );

  const detailPanelWidthContextValue = useMemo(
    () => ({
      detailPanelWidth,
      setDetailPanelWidth,
    }),
    [detailPanelWidth],
  );

  const detailPanelContentContextValue = useMemo(
    () => ({
      detailPanelContent,
      setDetailPanelContent,
    }),
    [detailPanelContent],
  );

  useEffect(() => {
    if (currentIndex !== -1) {
      setOrderId(orderDrafts[currentIndex]?.id);
    } else {
      setOrderId(null);
    }
  }, [orderDrafts, currentIndex]);

  // if not draft is left, go to home
  useEffect(() => {
    if (!isOrderDraftsLoading && globalOrderDrafts.count === 0) {
      navigateToHome();
    }

    if (globalOrderDrafts.isLoaded && globalOrderDrafts.count === 0) {
      navigateToHome();
    }
  }, [isOrderDraftsLoading, navigateToHome]);

  useEffect(() => {
    if (!globalOrderDrafts.isLoaded && globalOrderDrafts.count === 0) {
      globalOrderDrafts.setUserId(globalUser.id);
      globalOrderDrafts.loadOrderDraftsCount();
    }
  }, []);

  const onOrderDraftRetried = async (retriedOrderId: string) => {
    const latestOrderDrafts = await reloadOrderDrafts();
    const updatedIndex = latestOrderDrafts.findIndex((draft) => draft.id === retriedOrderId);
    if (updatedIndex !== -1) {
      setCurrentIndex(updatedIndex);
    }
  };

  return (
    <Page isLoading={false} contentWithBorder>
      <ListNavigator
        listLength={globalOrderDrafts.count || orderDrafts.length}
        currentIndex={currentIndex}
        onPrev={() => selectPrevOrderDraft()}
        onNext={() => selectNextOrderDraft()}
      />
      <KeywordContext.Provider value={keywordContextValue}>
        <PlayRecordingProvider>
          <DetailPanelWidthContext.Provider
            value={detailPanelWidthContextValue}
          >
            <DetailPanelContentContext.Provider
              value={detailPanelContentContextValue}
            >
              <MessagesProvider
                providerType={MessageProviderType.BY_REFERENCE_ID}
                referenceId={orderId}
              >
                <BusinessSettingsProvider>
                  <OrderProvider>
                    <SchemasProvider>
                      <TeamMembersProvider>
                        <ProcessOrderDrafts
                          orderId={orderId}
                          onOrderProcessed={onOrderDraftProcessed}
                          onOrderRetried={onOrderDraftRetried}
                        />
                      </TeamMembersProvider>
                    </SchemasProvider>
                  </OrderProvider>
                </BusinessSettingsProvider>
              </MessagesProvider>
            </DetailPanelContentContext.Provider>
          </DetailPanelWidthContext.Provider>
        </PlayRecordingProvider>
      </KeywordContext.Provider>
    </Page>
  );
});

export { ProcessOrderDraftsPage };
