/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  memo,
  Profiler,
  ReactNode,
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import {
  Accordion, LoadingOverlay, Textarea, Tooltip,
} from '@mantine/core';
import { CheckIcon, ExclamationTriangleIcon, PlayIcon } from '@heroicons/react/24/outline';

import { UniversalField } from '../../../../common/UniversalField';
import {
  getFieldByPath, getValueByPath, inputTypeToUniversalFieldType,
} from '../../../../../helpers/schema';
import { Button } from '../../../../ui/Button';
import { StandardField } from '../../../../common/StandardField';
import { Order, ProductWithQuantity } from '../../../../../models/Order';
import { useOrderContext } from '../../../../../contexts/useOrderContext';
import { useKeywordContext } from '../../../../../contexts/useKeywordContext';
import { FieldSpec, Schema } from '../../../../../models/Schema';
import { ProductSelect } from '../../../../order/order-draft/OrderDraftPanel/OrderDraftPanelBody/ProductListSection/_ProductListItem/_ProductSelect';
import { usePlayRecordingContext } from '../../../../../contexts/usePlayRecordingIndex';
import { useMessagesContext } from '../../../../../contexts/useMessagesContext';
import { Product } from '../../../../../models/Product';
import { useDragDropContext } from '../../../../../contexts/useDragDropContext';
import RenderIfVisible from '../../../../ui/RenderIfVisible';

interface Props {
  index: number;
  value: string;
  product: ProductWithQuantity;
  customerId: string;
  fieldSpecs: FieldSpec[];
  schema: Schema;
  sortByIdChecked: boolean;
  filterEnabledProducts: boolean;
  setAccordionValue: React.Dispatch<React.SetStateAction<string[]>>;
  updateValueByPath: (value: any, path: string, indices?: number[]) => void;
  updateProductByIndex: (index: number, values: any) => void;
  getProductFieldValue: (index: number, modelPath: string) => any;
  setError?: (key: string, error: string) => void;
}

const FieldItem = memo(({
  fieldSpec,
  schema,
  setError: _setError,
  setProductError,
  product,
  index,
  getProductFieldValue,
  updateValueByPath,
}: {
  fieldSpec: FieldSpec;
  schema: Schema;
  setError: (key: string, error: string) => void;
  setProductError: (key: string, error: string) => void;
  product: ProductWithQuantity;
  index: number;
  getProductFieldValue: (index: number, modelPath: string) => any;
  updateValueByPath: (value: any, path: string, indices?: number[]) => void;
}) => {
  const field = useMemo(() => getFieldByPath(schema?.fields, fieldSpec.path), [schema?.fields, fieldSpec.path]);

  const value = useMemo(() => getProductFieldValue(
    index, fieldSpec.modelPath), [index, fieldSpec.modelPath, getProductFieldValue]);

  const itemStyle = useMemo(() => ({
    minWidth: `${fieldSpec.name?.length}ch`,
    maxWidth: '300px',
    flexGrow: 1,
  }), [fieldSpec.name]);

  const type = useMemo(() => inputTypeToUniversalFieldType(field?.inputType, field?.type), [field?.inputType, field?.type]);

  const onValueChange = useCallback((_value: any) => updateValueByPath?.(
    _value, fieldSpec.modelPath, [index]), [index, updateValueByPath, fieldSpec.modelPath]);

  const onUnitChange = useCallback((ind: number, _value: any) => updateValueByPath?.(
    _value, fieldSpec.modelPath, [index]), [index, updateValueByPath, fieldSpec.modelPath]);

  const props = useMemo(() => ({
    index,
    productWithQuantity: product,
    onUnitChange,

    key: fieldSpec.path,
    type,
    label: fieldSpec.name,
    value,
    size: 'xs' as 'xs',
    shouldScroll: false,
    itemStyle,
    onValueChange,
  }), [fieldSpec.name, fieldSpec.path, index, onUnitChange, onValueChange, product, type, value, itemStyle]);

  const setError = useCallback((error: string) => {
    _setError?.(`${fieldSpec.path}-${product.uiId}`, error);
    setProductError?.(`${fieldSpec.path}-${product.uiId}`, error);
  }, [fieldSpec.path, _setError, setProductError, product.uiId]);

  return (
    <div
      id={`content-${fieldSpec.uiId}`}
      className="flex py-2 px-1 items-end flex-1"
    >
      {field?.isStandard
        ? (
          <StandardField
            fieldSchema={getFieldByPath(schema?.fields, fieldSpec.path)}
            setError={_setError}
            setProductError={setProductError}
            props={props}
          />
        )
        : (
          <UniversalField
            key={fieldSpec.path}
            type={type}
            label={fieldSpec.name}
            value={value}
            validation={fieldSpec.validation}
            size="xs"
            shouldScroll={false}
            itemStyle={itemStyle}
            onValueChange={onValueChange}
            setError={setError}
          />
        )}
    </div>
  );
});

const CommentField = memo(({
  onTextAreaChange,
}: {
  onTextAreaChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}) => (
  <Textarea
    placeholder="Add some comment if needed..."
    label="Comment"
    minRows={2}
    maxRows={4}
    autosize
    autoComplete="off"
    className="pt-4"
    onChange={onTextAreaChange}
  />
));

const Buttons = memo(({
  onRemoveProductButtonClick,
  onMarkAsCheckedButtonClick,
  hideTooltip,
  TooltipLabel,
  isProductConfirmed,
}: {
  onRemoveProductButtonClick: () => void;
  onMarkAsCheckedButtonClick: () => void;
  hideTooltip: boolean;
  TooltipLabel: React.ReactNode;
  isProductConfirmed: boolean;
}) => (
  <div className="pt-4 flex justify-end">
    <div className="flex space-x-2">
      <Button
        title="Remove"
        theme="red"
        onClick={onRemoveProductButtonClick}
      />
      <Tooltip
        label={TooltipLabel}
        hidden={hideTooltip}
      >
        <Button
          title={isProductConfirmed ? 'Mark as unchecked' : 'Mark as checked'}
          onClick={onMarkAsCheckedButtonClick}
          disabled={!hideTooltip}
        />
      </Tooltip>
    </div>
  </div>
));

const IconWithTooltip = memo(({
  hideTooltip,
  isConfirmed,
  TooltipLabel,
}: {
  hideTooltip: boolean,
  isConfirmed: boolean,
  TooltipLabel: ReactNode,
}) => {
  const Icon = useMemo(() => {
    if (!hideTooltip) {
      return <ExclamationTriangleIcon className="w-5 h-5 text-warning-500" />;
    }

    if (isConfirmed) {
      return <CheckIcon className="w-5 h-5 text-success-700" />;
    }

    return null;
  }, [hideTooltip, isConfirmed]);
  return (
    <Tooltip
      label={TooltipLabel}
      hidden={hideTooltip}
    >
      <div>
        {Icon}
      </div>
    </Tooltip>
  );
});

const Control = memo(({
  hideTooltip,
  isProductConfirmed,
  TooltipLabel,
  isRecordingAvailable,
  onPlayButtonClick,
  productName,
}: {
  hideTooltip: boolean;
  isProductConfirmed: boolean;
  productName: string;
  TooltipLabel: React.ReactNode;
  isRecordingAvailable: boolean;
  onPlayButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) => (
  <Accordion.Control
    icon={(
      <IconWithTooltip
        hideTooltip={hideTooltip}
        isConfirmed={isProductConfirmed}
        TooltipLabel={TooltipLabel}
      />
)}
    style={{ backgroundColor: 'white' }}
    className="hover:bg-green-500"
  >
    <div className="flex space-x-2">
      <p className={isProductConfirmed ? 'text-success' : ''}>
        {productName}
      </p>
      {isRecordingAvailable && (
      <Tooltip
        label="Play audio"
        transitionProps={{ transition: 'pop', duration: 200 }}
        bg="white"
        styles={{
          tooltip: {
            color: 'black',
            border: '1.5px solid #E8E8E8',
            boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
          },
        }}
      >
        <button
          type="button"
          className="rounded-full border border-light-gray-100 bg-white p-1"
          onClick={onPlayButtonClick}
        >
          <PlayIcon className="aspect-square w-4" />
        </button>
      </Tooltip>
      )}
    </div>
  </Accordion.Control>
));

const ProductField = memo(({
  index, value, product, fieldSpecs, schema, sortByIdChecked, filterEnabledProducts, customerId,
  setAccordionValue, updateValueByPath, setError, updateProductByIndex,
  getProductFieldValue,
  scrollKeyword,
  setHoverKeyword,
  setGroupOrders,
  selectedIndex,
  selectedMessageId,
  setPlayRecordingKeywordAndMessageId,
  setDraggedItemId,
  setDragging,
  isRecordingAvailable,
}: {
  filterEnabledProducts: boolean;
  scrollKeyword: string;
  setHoverKeyword: React.Dispatch<React.SetStateAction<string>>;
  setGroupOrders: React.Dispatch<React.SetStateAction<Order[]>>;
  selectedIndex: number;
  selectedMessageId: string;
  setPlayRecordingKeywordAndMessageId: (keywordAndMessageId: { keyword: string; messageId: string }) => void;
  setDraggedItemId: React.Dispatch<React.SetStateAction<string>>;
  setDragging: React.Dispatch<React.SetStateAction<boolean>>;
  isRecordingAvailable: boolean;
} & Props) => {
  const dragImageRef = useRef(null);
  const selectRef = useRef<HTMLDivElement>(null);

  const hideTooltip = useMemo(() => !Object.values(product?.errors || {}).some((error) => error), [product?.errors]);

  const isProductConfirmed = useMemo(() => product?.confirmed, [product?.confirmed]);

  const TooltipLabel = useMemo(() => (
    <ul className="list-disc px-3">
      {
        Object.values(product?.errors || {}).reduce(
          (acc, _value) => (_value ? [...acc, _value] : acc), []).map((err) => (
            <li key={err}>{err}</li>
        ))
      }
    </ul>
  ), [product?.errors]);

  const doScrollToKeyword = useMemo(() => {
    if (product?.name && scrollKeyword) {
      return scrollKeyword?.trim() === product?.name?.trim();
    }

    return !product?.name && product?.autoMatched;
  }, [
    product?.name,
    product?.autoMatched,
    scrollKeyword,
  ]);

  const placeholderElement = useMemo(() => (
    <div className="h-[200px] relative">
      <LoadingOverlay
        visible
        loaderProps={{ type: 'dots' }}
        overlayProps={{ blur: 2 }}
      />
    </div>
  ), []);

  useEffect(() => {
    if (doScrollToKeyword && selectRef.current) {
      selectRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [doScrollToKeyword]);

  const onPlayButtonClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setPlayRecordingKeywordAndMessageId({
      keyword: product.name,
      messageId: selectedMessageId,
    });
  }, [product.name, selectedMessageId, setPlayRecordingKeywordAndMessageId]);

  const onProductChange = useCallback((ind: number, p: Product) => updateProductByIndex(index, {
    updatedName: p.name,
    id: p.id,
    score: p.score,
    product: p,
  }), [index, updateProductByIndex]);

  const onRemoveProductButtonClick = useCallback(() => {
    setAccordionValue((prev) => prev.filter((id) => id !== product.uiId));

    setGroupOrders((groupOrders) => {
      const _groupOrders = [...groupOrders];
      _groupOrders[selectedIndex].products.splice(index, 1);
      return _groupOrders;
    });
  }, [index, product.uiId, selectedIndex, setAccordionValue, setGroupOrders]);

  const onMarkAsCheckedButtonClick = useCallback(() => {
    setGroupOrders((groupOrders) => {
      const _groupOrders = [...groupOrders];

      const confirmed = _groupOrders[selectedIndex].products[index].confirmed;

      if (!confirmed) {
        setAccordionValue((prev) => (!prev.includes(
          product.uiId) ? [...prev, product.uiId] : prev.filter((id) => id !== product.uiId)));
      }

      _groupOrders[selectedIndex].products[index].confirmed = !confirmed;
      return _groupOrders.map((prevGroupOrder, i) => (i === selectedIndex
        ? {
          ...prevGroupOrder,
          products: (prevGroupOrder.products || []).map((prevProduct: ProductWithQuantity) => ({ ...prevProduct })),
        } : prevGroupOrder));
    });
  }, [index, product.uiId, selectedIndex, setAccordionValue, setGroupOrders]);

  const setProductError = useCallback((key: string, error: string) => {
    setGroupOrders((groupOrders) => {
      const _groupOrders = [...groupOrders];

      if (!_groupOrders[selectedIndex].products[index]) return _groupOrders;

      _groupOrders[selectedIndex].products[index].errors = {
        ..._groupOrders[selectedIndex].products[index]?.errors,
        [key]: error,
      };
      return _groupOrders.map((prevGroupOrder, i) => (i === selectedIndex
        ? {
          ...prevGroupOrder,
          products: (prevGroupOrder.products || []).map((prevProduct: ProductWithQuantity) => ({ ...prevProduct })),
        } : prevGroupOrder));
    });
  }, [index, selectedIndex, setGroupOrders]);

  const onTextAreaChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => updateProductByIndex?.(index, {
    comment: event.target.value,
  }), [index, updateProductByIndex]);

  const onMouseEnter = useCallback(() => {
    setHoverKeyword(product?.name);
  }, [product?.name, setHoverKeyword]);

  const onMouseLeave = useCallback(() => {
    setHoverKeyword('');
  }, [setHoverKeyword]);

  const createDragImage = useCallback((node: HTMLElement) => {
    const dragImage = node.cloneNode(true) as HTMLElement;
    dragImage.style.position = 'absolute';
    dragImage.style.top = '-9999px';
    dragImage.style.left = '-9999px';
    dragImage.style.transform = 'scale(0.4)';
    document.body.appendChild(dragImage);
    dragImageRef.current = dragImage;
  }, []);

  const onDragStart = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    if (selectRef.current) {
      createDragImage(selectRef.current);
      e.dataTransfer.setDragImage(dragImageRef.current!, 0, 0);
    }
    setDraggedItemId(product.uiId);
    setDragging(true);
  }, [createDragImage, product.uiId, setDraggedItemId, setDragging]);

  const onDragEnd = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  return (
    <Accordion.Item
      value={value}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={selectRef}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      draggable
    >
      <Control
        hideTooltip={hideTooltip}
        TooltipLabel={TooltipLabel}
        isProductConfirmed={isProductConfirmed}
        productName={product?.name}
        isRecordingAvailable={isRecordingAvailable}
        onPlayButtonClick={onPlayButtonClick}
      />
      <Accordion.Panel
        style={{ backgroundColor: 'white' }}
      >
        <RenderIfVisible
          stayRendered
          // defaultHeight={200}
          // visibleOffset={100}
          placeholderElement={placeholderElement}
        >
          <div
            className="flex flex-col gap-sm items-stretch w-full"
          >
            <div key={`preview-${product.uiId}`} className="flex flex-wrap gap-y-sm w-full">
              <ProductSelect
                label="Product"
                businessId={customerId}
                productWithQuantity={product}
                sortById={sortByIdChecked}
                filterEnabledProducts={filterEnabledProducts}
                index={index}
                onProductChange={onProductChange}
              />
              {fieldSpecs.filter((fieldSpec) => fieldSpec.modelPath !== 'products.*.product.name').map((fieldSpec) => (
                <FieldItem
                  key={`preview-${fieldSpec.uiId}`}
                  fieldSpec={fieldSpec}
                  schema={schema}
                  setError={setError}
                  setProductError={setProductError}
                  product={product}
                  index={index}
                  getProductFieldValue={getProductFieldValue}
                  updateValueByPath={updateValueByPath}
                />
              ))}
            </div>
          </div>
          <CommentField
            onTextAreaChange={onTextAreaChange}
          />
          <Buttons
            onRemoveProductButtonClick={onRemoveProductButtonClick}
            onMarkAsCheckedButtonClick={onMarkAsCheckedButtonClick}
            hideTooltip={hideTooltip}
            TooltipLabel={TooltipLabel}
            isProductConfirmed={isProductConfirmed}
          />
        </RenderIfVisible>
      </Accordion.Panel>
    </Accordion.Item>
  );
});

const _ProductField = memo((props: Props) => {
  const { selectedMessageId } = useMessagesContext();
  const { setHoverKeyword, scrollKeyword } = useKeywordContext();
  const { setGroupOrders, selectedIndex } = useOrderContext();
  const { isRecordingAvailable, setPlayRecordingKeywordAndMessageId } = usePlayRecordingContext();
  const {
    setDraggedItemId, setDragging,
  } = useDragDropContext();

  return (
    <ProductField
      scrollKeyword={scrollKeyword}
      setHoverKeyword={setHoverKeyword}
      setGroupOrders={setGroupOrders}
      selectedIndex={selectedIndex}
      selectedMessageId={selectedMessageId}
      setPlayRecordingKeywordAndMessageId={setPlayRecordingKeywordAndMessageId}
      setDraggedItemId={setDraggedItemId}
      setDragging={setDragging}
      isRecordingAvailable={isRecordingAvailable}
      {...props}
    />
  );
});

export default _ProductField;
