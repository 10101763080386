import {
  useCallback,
} from 'react';

import { httpPost } from '../helpers/xhr';
import { genericErrorFeedback } from '../helpers/errors';
import { WorkflowRun, WorkflowRunSideEffectType } from '../models/Workflow';
import { globalAlertData } from '../state/globalAlertData';
import { usePendingLROs } from './usePendingLROs';

interface RetryWorkflowProps {
  onOrderWorkflowRunRetried?: (workflowRunId: string) => void;
}

const useRetryWorkflow = ({ onOrderWorkflowRunRetried }: RetryWorkflowProps) => {
  const { pendingLROs, addPendingLRO } = usePendingLROs('workflow', (lro) => {
    if (lro.error) {
      return genericErrorFeedback(`Error retrying the workflow run: ${lro.error.message}`);
    }

    if (lro.isDone) {
      const refId = lro.result.state.sideEffects.find(
        (sideEffect) => sideEffect.type === WorkflowRunSideEffectType.Order,
      )?.refId;
      if (refId) {
        onOrderWorkflowRunRetried?.(refId);
      }
    }

    return null;
  });

  const retryWorkflow = useCallback(
    async (
      selectedWorkflow: string,
      workflowRun: WorkflowRun,
      pendingId: string,
    ): Promise<void> => {
      if (!workflowRun) return;

      httpPost(`/workflows/runs/${workflowRun.id}/retry`, { workflow: selectedWorkflow })
        .then((response) => {
          globalAlertData.create('Workflow run retry started successfully');
          const lroId: string = response.data.lroId;
          console.log('lroId', lroId, pendingId);
          addPendingLRO(lroId, pendingId);
        })
        .catch(genericErrorFeedback('Error retrying workflow'))
        .finally(() => {});
    },
    [addPendingLRO],
  );

  return {
    retryWorkflow,
    pendingLROs,
  };
};

export { useRetryWorkflow };
