import {
  forwardRef, useCallback, useImperativeHandle, useState,
} from 'react';

import { Message } from '../../../models/Message';
import { ChatMoveMessageModal } from '../../modal/ChatMoveMessageModal';

interface Props {
  children: React.ReactNode;

  message: Message;
  removeMessageFromMessages?: (messageId: string) => void;
}

const MoveMessageModalWrapper = forwardRef(
  ({ children, message, removeMessageFromMessages }: Props, ref) => {
    const [isMoveMessageModalVisible, setIsMoveMessageModalVisible] = useState(false);
    const onMoveMessageButtonClick = useCallback(() => {
      setIsMoveMessageModalVisible(true);
    }, []);

    const onMoveMessageModalClose = useCallback(() => {
      setIsMoveMessageModalVisible(false);
    }, []);

    const onMoveMessageModal = useCallback(
      (byCopy: boolean) => {
        if (!byCopy) {
          removeMessageFromMessages?.(message.id);
        }

        onMoveMessageModalClose();
      },
      [message.id, onMoveMessageModalClose, removeMessageFromMessages],
    );

    useImperativeHandle(ref, () => ({
      onMoveMessageButtonClick,
      setIsMoveMessageModalVisible,
    }));
    return (
      <>
        {children}

        {isMoveMessageModalVisible && (
          <ChatMoveMessageModal
            messageId={message?.id}
            isOpen={isMoveMessageModalVisible}
            onClose={onMoveMessageModalClose}
            onMessageMoved={onMoveMessageModal}
          />
        )}
      </>
    );
  },
);

export default MoveMessageModalWrapper;
