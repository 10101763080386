import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Page } from '../components/layout/Page/Page';
import { KeywordContext } from '../contexts/useKeywordContext';
import {
  DetailPanelContentContext,
  DetailPanelContentType,
} from '../contexts/useDetailPanelContentContext';
import {
  DetailPanelWidthContext,
  DetailPanelWidthType,
} from '../contexts/useDetailPanelWidthContext';
import { OrderProvider } from '../contexts/useOrderContext';
import { PlayRecordingProvider } from '../contexts/usePlayRecordingIndex';
import { TeamMembersProvider } from '../contexts/useTeamMembersContext';
import {
  MessageProviderType,
  MessagesProvider,
} from '../contexts/useMessagesContext';
import { BusinessSettingsProvider } from '../contexts/useBusinessSettingsContext';
import { SchemasProvider } from '../contexts/useSchemasContext';
import { ProcessOrderDrafts } from '../components/order/order-draft/ProcessOrderDrafts';
import { ROUTES } from '../config/routes';

interface Props {
  orderId: string;
}

const ProcessOrderDraftByIdPage = observer(({ orderId }: Props) => {
  const navigate = useNavigate();

  const [hoverKeyword, setHoverKeyword] = useState<string>('');
  const [scrollKeyword, setScrollKeyword] = useState<string>('');

  const [detailPanelWidth, setDetailPanelWidth] = useState<DetailPanelWidthType>(DetailPanelWidthType.SMALL);
  const [detailPanelContent, setDetailPanelContent] = useState<DetailPanelContentType>(
    DetailPanelContentType.ORDER_DRAFT_PROCESSING,
  );

  const keywordContextValue = useMemo(
    () => ({
      hoverKeyword,
      setHoverKeyword,
      scrollKeyword,
      setScrollKeyword,
    }),
    [hoverKeyword, scrollKeyword],
  );

  const detailPanelWidthContextValue = useMemo(
    () => ({
      detailPanelWidth,
      setDetailPanelWidth,
    }),
    [detailPanelWidth],
  );

  const detailPanelContentContextValue = useMemo(
    () => ({
      detailPanelContent,
      setDetailPanelContent,
    }),
    [detailPanelContent],
  );

  const onOrderDraftProcessed = useCallback(() => {
    navigate(ROUTES.ORDER_BY_ID(orderId));
  }, [navigate, orderId]);

  return (
    <Page isLoading={false} contentWithBorder>
      <KeywordContext.Provider value={keywordContextValue}>
        <PlayRecordingProvider>
          <DetailPanelWidthContext.Provider
            value={detailPanelWidthContextValue}
          >
            <DetailPanelContentContext.Provider
              value={detailPanelContentContextValue}
            >
              <MessagesProvider
                providerType={MessageProviderType.BY_REFERENCE_ID}
                referenceId={orderId}
              >
                <BusinessSettingsProvider>
                  <OrderProvider>
                    <SchemasProvider>
                      <TeamMembersProvider>
                        <ProcessOrderDrafts
                          orderId={orderId}
                          onOrderProcessed={onOrderDraftProcessed}
                        />
                      </TeamMembersProvider>
                    </SchemasProvider>
                  </OrderProvider>
                </BusinessSettingsProvider>
              </MessagesProvider>
            </DetailPanelContentContext.Provider>
          </DetailPanelWidthContext.Provider>
        </PlayRecordingProvider>
      </KeywordContext.Provider>
    </Page>
  );
});

export { ProcessOrderDraftByIdPage };
