import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CustomerPage } from '../../pages/CustomerPage';
import { ProtectedScreen } from '../../components/ui/ProtectedScreen';
import { ROUTES } from '../../config/routes';

const CustomerScreen = () => {
  const { businessId } = useParams();
  const navigate = useNavigate();

  const navigateToCustomersList = useCallback(() => {
    navigate(ROUTES.CUSTOMERS);
  }, [navigate]);

  return (
    <ProtectedScreen>
      <CustomerPage
        businessId={businessId}
        navigateToCustomersList={navigateToCustomersList}
      />
    </ProtectedScreen>
  );
};

export { CustomerScreen };
