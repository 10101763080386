import {
  useState, useCallback, useRef, useEffect,
} from 'react';
import { useDebouncedState } from '@mantine/hooks';

import { httpGet } from '../../helpers/xhr';
import { Business } from '../../models/Business';

type LoadCustomersProps = {
  excludeUnconfirmed?: boolean;
  reset?: boolean;
};

interface FetchOrderDraftsProps {
  preventInitialFetch?: boolean;
}

const useFetchCustomers = ({
  preventInitialFetch = false,
}: FetchOrderDraftsProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(!preventInitialFetch);
  const [customers, setCustomers] = useState<Business[]>([]);
  const customersPaginationCursor = useRef<string | null>(null);
  const currentAbortController = useRef<AbortController | null>(null);

  const [searchQuery, setSearchQuery] = useDebouncedState('', 300);

  const endReachedRef = useRef<boolean>(false);
  const isInitialFetch = useRef<boolean>(true);

  const resetParams = () => {
    setCustomers([]);
    customersPaginationCursor.current = null;
    endReachedRef.current = false;
  };

  const loadCustomers = useCallback(
    ({
      excludeUnconfirmed = true,

      reset = false,
    }: LoadCustomersProps) => {
      if (reset) {
        resetParams();
      } else if (endReachedRef.current) return;

      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }

      const controller = new AbortController();
      currentAbortController.current = controller;

      setIsLoading(true);
      httpGet('/businesses/me/customers', {
        params: {
          cursor: customersPaginationCursor.current,
          exclude_unconfirmed: excludeUnconfirmed,
          search_query: searchQuery,
        },
        signal: controller.signal, // Pass the signal to the fetch request
      })
        .then((response) => {
          const fetchedCustomers = response.data.result || [];

          setCustomers((cs) => [...cs, ...fetchedCustomers]);
          customersPaginationCursor.current = response.data.cursor;

          if (!response.data.cursor || fetchedCustomers.length === 0) {
            endReachedRef.current = true;
          }
        })
        .catch((error) => {
          if (error.name !== 'AbortError') {
            console.error('Failed to fetch customers:', error);
          }
        })
        .finally(() => {
          if (!controller.signal.aborted) {
            setIsLoading(false);
          }
          currentAbortController.current = null;
        });
    },
    [searchQuery],
  );

  // Manage initial fetch
  useEffect(() => {
    if (preventInitialFetch) {
      isInitialFetch.current = false;
      return () => {};
    }

    if (!loadCustomers) return () => {};

    if (isInitialFetch.current) {
      isInitialFetch.current = false;
      loadCustomers({ reset: true });
    }

    return () => {
      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }
    };
  }, [loadCustomers, preventInitialFetch]);

  // Manage search query
  useEffect(() => {
    if (isInitialFetch.current) {
      return;
    }

    loadCustomers?.({ reset: true });
  }, [searchQuery, loadCustomers]);

  return {
    customers,
    isLoading,
    loadCustomers,
    setSearchQuery,
  };
};

export { useFetchCustomers };
