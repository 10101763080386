// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OP35f7zi5odhGe6z8vEf {\n    transition: opacity 150ms ease;\n    opacity: 0;\n}\n\n.OP35f7zi5odhGe6z8vEf[data-inactive] {\n        opacity: 0;\n        cursor: default;\n    }\n\n.nObKishHk_YjBTH8XdSb {\n    height: '100%';\n}\n\n.Ly3GdNlCQckj6EDSf9dA {\n    height: 100%;\n}\n\n.X8BMvw5DlboWLz7DfwCo:hover .OP35f7zi5odhGe6z8vEf {\n            opacity: 1;\n        }", "",{"version":3,"sources":["webpack://./src/components/Dashboard/AdamInsights/AdamInsights.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,UAAU;AAMd;;AAJI;QACI,UAAU;QACV,eAAe;IACnB;;AAGJ;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB;;AAIQ;YACI,UAAU;QACd","sourcesContent":[".controls {\n    transition: opacity 150ms ease;\n    opacity: 0;\n\n    &[data-inactive] {\n        opacity: 0;\n        cursor: default;\n    }\n}\n\n.viewport {\n    height: '100%';\n}\n\n.container {\n    height: 100%;\n}\n\n.root {\n    &:hover {\n        .controls {\n            opacity: 1;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controls": "OP35f7zi5odhGe6z8vEf",
	"viewport": "nObKishHk_YjBTH8XdSb",
	"container": "Ly3GdNlCQckj6EDSf9dA",
	"root": "X8BMvw5DlboWLz7DfwCo"
};
export default ___CSS_LOADER_EXPORT___;
