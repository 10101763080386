import {
  useCallback, useEffect, useState, useMemo,
  useRef,
} from 'react';
import {
  Badge,
  Image, Overlay, Tooltip,
} from '@mantine/core';
import { Carousel, Embla } from '@mantine/carousel';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import {
  ArrowsPointingOutIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
} from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';

import { Message, MessageContextUtils } from '../../../../../models/Message';
import { OCRKeyword } from '../../../../../models/Workflow';
import { Button } from '../../../../ui/Button';
import { useKeywordContext } from '../../../../../contexts/useKeywordContext';

interface Props {
  message: Message;
}

const ImageMessage = ({ message }: Props) => {
  const [showOverlay, setShow] = useState(false);
  const [selectedDocIndex, setSelectedDocIndex] = useState(0);
  const [selectedDocPageIndex, setSelectedDocPageIndex] = useState(0);
  const [selectedDocImgIndex, setSelectedDocImgIndex] = useState(0);
  const { setScrollKeyword } = useKeywordContext();

  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  // TODO(ntauth): Proper handling of documents with multiple pages
  const docs = MessageContextUtils.documentAttachments(message.context);
  const docImages = docs?.map((doc, docIndex) => doc.imagifiedPages?.map((page, pageIndex) => ({
    ...page,
    docIndex,
    pageIndex,
  })) || []).reduce(
    (acc, curr) => [...acc, ...curr],
    [],
  );

  const slides = docImages.map((item) => (
    <Carousel.Slide key={item.url}>
      <Image radius="md" src={item.url} fit="contain" w="full" h={400} />
    </Carousel.Slide>
  ));

  const [carouselEmbla, setCarouselEmbla] = useState<Embla>(null);

  const onPreviousButtonClick = useCallback(() => {
    const nextSelectedDocImgIndex = Math.max(selectedDocImgIndex - 1, 0);
    setSelectedDocIndex(docImages[nextSelectedDocImgIndex].docIndex);
    setSelectedDocPageIndex(docImages[nextSelectedDocImgIndex].pageIndex);
    setSelectedDocImgIndex(nextSelectedDocImgIndex);
    carouselEmbla.scrollPrev();
  }, [carouselEmbla, docImages, selectedDocImgIndex]);

  const onNextButtonClick = useCallback(() => {
    const nextSelectedDocImgIndex = Math.min(selectedDocImgIndex + 1, docImages.length - 1);
    setSelectedDocIndex(docImages[nextSelectedDocImgIndex].docIndex);
    setSelectedDocPageIndex(docImages[nextSelectedDocImgIndex].pageIndex);
    setSelectedDocImgIndex(nextSelectedDocImgIndex);
    carouselEmbla.scrollNext();
  }, [carouselEmbla, docImages, selectedDocImgIndex]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        onPreviousButtonClick();
      } else if (event.key === 'ArrowRight') {
        onNextButtonClick();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedDocImgIndex, docImages, onPreviousButtonClick, onNextButtonClick]);

  const keywordPatches = useMemo(() => {
    const patches: any[] = [];
    const keywords = message.context?.workflowOrder?.ocrKeywords || [];

    keywords
      .filter((keyword: OCRKeyword) => keyword.docIndex === selectedDocIndex && keyword.pageIndex === selectedDocPageIndex)
      .forEach((keyword: OCRKeyword, index: number) => {
        const geometry = keyword.geometry;

        if (geometry.length % 2 !== 0) {
          console.error('polygonal geometry length is not even');
          return;
        }

        const points: number[][] = geometry.reduce((acc, x, i, arr) => {
          if (i % 2 === 0) {
            acc.push([x, arr[i + 1]]);
          }
          return acc;
        }, []);

        patches.push({
          id: `${keyword.docIndex}:${index}`,
          keyword: keyword.word,
          points,
        });
      });

    return patches;
  }, [selectedDocIndex, selectedDocPageIndex, message.context?.workflowOrder?.ocrKeywords]);

  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const [naturalImageDimensions, setNaturalImageDimensions] = useState({ width: 0, height: 0 });

  const handleImageLoad = useCallback((event: React.SyntheticEvent<HTMLImageElement>) => {
    const img = event.currentTarget;
    setImageDimensions({
      width: img.width,
      height: img.height,
    });
    setNaturalImageDimensions({
      width: img.naturalWidth,
      height: img.naturalHeight,
    });

    if (containerRef.current && imageRef.current) {
      // if image is wider than container, set width to container width
      if (imageRef.current.clientWidth > containerRef.current.clientWidth) {
        imageRef.current.style.width = `${containerRef.current.offsetWidth}px`;
      }
      // if image is taller than container, set height to container height
      if (imageRef.current.clientHeight > containerRef.current.clientHeight) {
        imageRef.current.style.height = `${containerRef.current.offsetHeight}px`;
      }

      setTimeout(() => {
        setImageDimensions({
          width: imageRef.current.offsetWidth,
          height: imageRef.current.offsetHeight,
        });
      }, 200);
    }
  }, []);

  const rescalePoint = useCallback((point: number[]) => {
    const [x, y] = point;
    const scaleX = imageDimensions.width / naturalImageDimensions.width;
    const scaleY = imageDimensions.height / naturalImageDimensions.height;
    return [
      x * scaleX,
      y * scaleY,
    ];
  }, [imageDimensions, naturalImageDimensions]);

  const rescaledKeywordPatches = useMemo(() => keywordPatches.map((patch) => ({
    ...patch,
    points: patch.points.map(rescalePoint),
  })), [keywordPatches, rescalePoint]);

  return (
    <>
      <div className="relative">
        <Carousel
          getEmblaApi={setCarouselEmbla}
          withIndicators
          withControls={!showOverlay}
          withKeyboardEvents={false} // We handle keyboard events ourselves
          className="mb-sm rounded-lg border border-blue-gray-100 bg-white p-lg"
          align="center"
          styles={{
            indicator: {
              backgroundColor: '#8CBBFA',
            },
          }}
          speed={25}
          onSlideChange={(index) => {
            setSelectedDocIndex(docImages[index].docIndex);
            setSelectedDocPageIndex(docImages[index].pageIndex);
            setSelectedDocImgIndex(index);
          }}
        >
          {slides}
        </Carousel>

        <span className="absolute inset-0 h-fit w-full flex justify-between p-md">
          <Badge variant="outline" color="blue" size="xs">
            {message.context?.attachments?.[selectedDocIndex]?.name}
          </Badge>

          <button
            type="button"
            onClick={() => setShow(true)}
          >
            <ArrowsPointingOutIcon className="aspect-square w-5" />
          </button>
        </span>
      </div>

      {showOverlay && (
        <div className="absolute left-0 top-0 z-[10] h-full w-full">
          <div className="relative h-full w-full">
            {/* Overlay */}
            <Overlay
              bg="#EBEBED"
              opacity={0.4}
              onClick={() => setShow(false)}
              zIndex={10}
            />

            <div className="absolute inset-0 flex h-full w-full items-center justify-center">
              <TransformWrapper>
                {({ zoomIn, zoomOut }) => (
                  <div className="flex items-center w-full h-full py-2 justify-center space-x-2">
                    <button
                      type="button"
                      className={twMerge(
                        'z-[20] flex aspect-square w-8 items-center justify-center rounded-full border border-neutral-50 bg-white hover:bg-slate-50/80',
                        (docImages?.length === 1 || selectedDocImgIndex === 0)
                          && 'invisible',
                      )}
                      onClick={onPreviousButtonClick}
                    >
                      <ChevronLeftIcon className="aspect-square w-5" />
                    </button>
                    <div className="z-[20] max-w-full max-h-full flex bg-green-400 flex-col overflow-hidden rounded-xl border border-neutral-50 bg-white">
                      <div className="flex-1 flex flex-col items-center overflow-hidden justify-center p-2">
                        <TransformComponent
                          contentClass="flex flex-col flex-1 overflow-hidden justify-center items-center"
                          wrapperClass="flex flex-col flex-1 overflow-hidden justify-center items-center"
                        >
                          <div className="relative flex-1 flex flex-col overflow-hidden">
                            <div ref={containerRef} className="flex-1 overflow-hidden">
                              <Image
                                ref={imageRef}
                                alt=""
                                src={docImages[selectedDocImgIndex].url}
                                onLoad={handleImageLoad}
                                className="w-full h-full object-contain"
                                // w={800}
                                // h={700}
                              />
                            </div>
                            <svg
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                              }}
                            >
                              {rescaledKeywordPatches.map((patch: any) => (
                                <Tooltip
                                  key={patch.id}
                                  label={patch.keyword}
                                  transitionProps={{ transition: 'pop', duration: 200 }}
                                  bg="white"
                                  styles={{
                                    tooltip: {
                                      color: 'black',
                                      border: '1.5px solid #E8E8E8',
                                      boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
                                    },
                                  }}
                                >
                                  <polygon
                                    points={patch.points.map((point: number[]) => point.join(',')).join(' ')}
                                    style={{
                                      fill: 'rgba(138, 93, 250, 0.2)',
                                      stroke: 'rgba(138, 93, 250, 1)',
                                      strokeWidth: 1,
                                      cursor: 'pointer',
                                      transition: 'all 0.3s ease',
                                    }}
                                    onMouseEnter={(e) => {
                                      e.currentTarget.style.fill = 'rgba(138, 93, 250, 0.4)';
                                      e.currentTarget.style.strokeWidth = '2';
                                    }}
                                    onMouseLeave={(e) => {
                                      e.currentTarget.style.fill = 'rgba(138, 93, 250, 0.2)';
                                      e.currentTarget.style.strokeWidth = '1';
                                    }}
                                    onClick={() => {
                                      setScrollKeyword(patch.keyword);
                                    }}
                                  />
                                </Tooltip>
                              ))}
                            </svg>
                          </div>
                        </TransformComponent>
                      </div>
                      <div className="my-1 flex items-center justify-center">
                        <Button
                          onClick={() => zoomIn()}
                          theme="transparent"
                          title=""
                          icon={
                            <MagnifyingGlassPlusIcon className="aspect-square w-5" />
                          }
                        />
                        <Button
                          onClick={() => zoomOut()}
                          theme="transparent"
                          title=""
                          icon={
                            <MagnifyingGlassMinusIcon className="aspect-square w-5" />
                          }
                        />
                      </div>
                    </div>
                    <button
                      type="button"
                      className={twMerge(
                        'z-[20] flex aspect-square w-8 items-center justify-center rounded-full border border-neutral-50 bg-white hover:bg-slate-50/80',
                        (docImages?.length === 1
                          || selectedDocImgIndex === docImages.length - 1)
                          && 'invisible',
                      )}
                      onClick={onNextButtonClick}
                    >
                      <ChevronRightIcon className="aspect-square w-5" />
                    </button>
                  </div>
                )}
              </TransformWrapper>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageMessage;
