import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProtectedScreen } from '../../components/ui/ProtectedScreen';
import { OrdersListPage } from '../../pages/OrdersListPage';
import { ROUTES } from '../../config/routes';

const OrdersListScreen = () => {
  const navigate = useNavigate();

  const navigateToOrder = useCallback(
    (orderId: string) => {
      navigate(ROUTES.ORDER_BY_ID(orderId));
    },
    [navigate],
  );

  const navigateToProcessDraftById = useCallback((orderId: string) => {
    navigate(ROUTES.PROCESS_ORDER_DRAFT_BY_ID(orderId));
  }, [navigate]);

  return (
    <ProtectedScreen>
      <OrdersListPage
        navigateToOrder={navigateToOrder}
        navigateToProcessDraftById={navigateToProcessDraftById}
      />
    </ProtectedScreen>
  );
};

export { OrdersListScreen };
