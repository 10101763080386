import { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { createTheme, MantineProvider } from '@mantine/core';

import { Alert } from './components/Alert';
import { AlertData } from './state/classes/AlertData';
import { ErrorBoundary } from './components/ErrorBoundary';

const mantineTheme = createTheme({
  defaultRadius: 'md',
});

interface Props {
  children: ReactNode;
  alertData: AlertData;
}

const AppGlobalProviders = observer(({ children, alertData }: Props) => (
  <MantineProvider theme={mantineTheme}>
    <ErrorBoundary>
      {alertData.title ? (
        <Alert
          title={alertData.title}
          message={alertData.message!}
          iconColor={alertData.iconColor!}
        />
      ) : null}

      {children}
    </ErrorBoundary>
  </MantineProvider>
));

export { AppGlobalProviders };
