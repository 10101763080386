// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ckR4rWgeLN0ttei5Gdzn {\n  width: 10px;\n  height: 10px;\n  transition: width 250ms ease;\n  background-color: blue;\n}\n\n.ckR4rWgeLN0ttei5Gdzn[data-active] {\n    width: 20px;\n  }\n\n.TNK9Pp1jLNdMW_Pgy6R1 {\n  transition: opacity 150ms ease;\n  opacity: 0;\n}\n\n.TNK9Pp1jLNdMW_Pgy6R1[data-inactive] {\n    opacity: 0;\n    cursor: default;\n  }\n\n.FAkRreScT0iKo85LWbQP:hover .TNK9Pp1jLNdMW_Pgy6R1 {\n      opacity: 1;\n    }", "",{"version":3,"sources":["webpack://./src/components/Dashboard/IntentCard/IntentCard.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,4BAA4B;EAC5B,sBAAsB;AAKxB;;AAHE;IACE,WAAW;EACb;;AAGF;EACE,8BAA8B;EAC9B,UAAU;AAMZ;;AAJE;IACE,UAAU;IACV,eAAe;EACjB;;AAKE;MACE,UAAU;IACZ","sourcesContent":[".indicator {\n  width: 10px;\n  height: 10px;\n  transition: width 250ms ease;\n  background-color: blue;\n\n  &[data-active] {\n    width: 20px;\n  }\n}\n\n.controls {\n  transition: opacity 150ms ease;\n  opacity: 0;\n\n  &[data-inactive] {\n    opacity: 0;\n    cursor: default;\n  }\n}\n\n.root {\n  &:hover {\n    .controls {\n      opacity: 1;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"indicator": "ckR4rWgeLN0ttei5Gdzn",
	"controls": "TNK9Pp1jLNdMW_Pgy6R1",
	"root": "FAkRreScT0iKo85LWbQP"
};
export default ___CSS_LOADER_EXPORT___;
