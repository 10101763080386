import { twMerge } from 'tailwind-merge';

import {
  BusinessSettings,
  ErpConnectionType,
} from '../../../../../models/BusinessSettings';

import { Card } from '../../../../common/Card';

import abacus from '../../../../../assets/logo/abacus.svg';
import microsoftDynamics from '../../../../../assets/logo/microsoft_dynamics.svg';
import sapByd from '../../../../../assets/logo/sap_byd.png';
import sftp from '../../../../../assets/sftp.svg';
import SFTP from './SFTP';
import Abacus from './Abacus';
import MicrosoftDynamics from './MicrosoftDynamics/MicrosoftDynamics';
import SAPByD from './SAP/ByD';

interface Props {
  businessSettings: BusinessSettings;
}

// const getERPIcon = (erpConnectionType: ErpConnectionType) => {
//   switch (erpConnectionType) {
//     case ErpConnectionType.Abacus:
//       return abacus;
//     case ErpConnectionType.DynamicsBC:
//       return microsoftDynamics;
//     case ErpConnectionType.SFTP:
//       return sftp;
//     default:
//       return sftp;
//   }
// };

const Overview = ({ businessSettings }: Props) => (
  <Card id="erp_integration_overview">
    <Card.Header>ERP overview</Card.Header>
    <Card.Body>
      {!businessSettings?.erpConnectionSettings?.type && (
        <div className="flex justify-center text-sm text-gray-900/50">
          No ERP connection is set up yet.
        </div>
      )}
      {businessSettings?.erpConnectionSettings?.type && (
        <div className="grid grid-cols-1 gap-5 lg:grid-cols-3 lg:gap-7.5">
          <div
            className={twMerge(
              "align-stretch sso-active flex h-44 cursor-pointer rounded-xl border border-dashed border-gray-300 bg-[url('assets/images/bg-erp.png')] bg-[length:500px] bg-center bg-no-repeat",
              businessSettings?.erpConnectionSettings?.type
                === ErpConnectionType.SFTP
                && "border-primary bg-[url('assets/images/bg-erp-selected.png')]",
            )}
          >
            <div className="flex grow flex-col place-content-center place-items-center space-y-4 rounded-xl">
              <div className="flex items-center">
                <img alt="" className="h-9" src={sftp} />
              </div>
              <span className="text-sm font-semibold text-gray-800">SFTP</span>
            </div>
          </div>

          <div
            className={twMerge(
              "align-stretch sso-active flex h-44 cursor-pointer rounded-xl border border-dashed border-gray-300 bg-[url('assets/images/bg-erp.png')] bg-[length:500px] bg-center bg-no-repeat",
              businessSettings?.erpConnectionSettings?.type
                === ErpConnectionType.DynamicsBC
                && "border-primary bg-[url('assets/images/bg-erp-selected.png')]",
            )}
          >
            <div className="flex grow flex-col place-content-center place-items-center space-y-4 rounded-xl">
              <div className="flex items-center">
                <img alt="" className="h-9" src={microsoftDynamics} />
              </div>
              <span className="text-sm font-semibold text-gray-800">
                Microsoft Dynamics
              </span>
            </div>
          </div>

          <div
            className={twMerge(
              "align-stretch sso-active flex h-44 cursor-pointer rounded-xl border border-dashed border-gray-300 bg-[url('assets/images/bg-erp.png')] bg-[length:500px] bg-center bg-no-repeat",
              businessSettings?.erpConnectionSettings?.type
                === ErpConnectionType.Abacus
                && "border-primary bg-[url('assets/images/bg-erp-selected.png')]",
            )}
          >
            <div className="flex grow flex-col place-content-center place-items-center space-y-4 rounded-xl">
              <div className="flex items-center">
                <img alt="" className="h-9" src={abacus} />
              </div>
              <span className="text-sm font-semibold text-gray-800">
                Abacus
              </span>
            </div>
          </div>

          <div
            className={twMerge(
              "align-stretch sso-active flex h-44 cursor-pointer rounded-xl border border-dashed border-gray-300 bg-[url('assets/images/bg-erp.png')] bg-[length:500px] bg-center bg-no-repeat",
              businessSettings?.erpConnectionSettings?.type
                === ErpConnectionType.SAPByd
                && "border-primary bg-[url('assets/images/bg-erp-selected.png')]",
            )}
          >
            <div className="flex grow flex-col place-content-center place-items-center space-y-4 rounded-xl">
              <div className="flex items-center">
                <img alt="" className="h-9" src={sapByd} />
              </div>
              <span className="text-sm font-semibold text-gray-800">
                SAP Business ByDesign
              </span>
            </div>
          </div>
        </div>
      )}

      {businessSettings?.erpConnectionSettings?.type
        === ErpConnectionType.SFTP && <SFTP businessSettings={businessSettings} />}

      {businessSettings?.erpConnectionSettings?.type
        === ErpConnectionType.Abacus && (
        <Abacus businessSettings={businessSettings} />
      )}

      {businessSettings?.erpConnectionSettings?.type
        === ErpConnectionType.DynamicsBC && (
        <MicrosoftDynamics businessSettings={businessSettings} />
      )}

      {businessSettings?.erpConnectionSettings?.type
        === ErpConnectionType.SAPByd && (
        <SAPByD businessSettings={businessSettings} />
      )}

      {/* <div className="flex flex-col gap-0.5">
        <SubsectionTitle title="Synchronization" subtitle="The type and the interval of the synchronization." />

        <div className="grid gap-5 lg:py-7.5  text-gray-700 text-sm">
          <Select
            label="Type"
            value={businessSettings?.erpConnectionSettings?.syncSettings?.type.toString()}
          />
          <Input
            label="Interval"
            value={businessSettings?.erpConnectionSettings?.syncSettings?.lazy?.interval}
            readOnly
          />
        </div>
      </div> */}
    </Card.Body>
  </Card>
);

export default Overview;
