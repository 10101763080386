import { useCallback, useMemo } from 'react';

import { Button } from './ui/Button';
import { OverflowEllipsisParagraph } from './ui/OverflowEllipsisParagraph';
import { formatPrice } from '../helpers/formatting';

interface Props {
  id: string;
  quantity: number | null;
  unit: string;
  name: string;
  idOrSku?: string;
  price?: number;
  currency?: string;
  theme?: 'LIGHT' | 'DARK';
  isRemoveIconVisible?: boolean;
  onRemove?: (id: string) => void;
}

const styles = {
  leftContent: {
    width: 72,
  },
  mainContent: {
    overflow: 'hidden',
    borderRightWidth: 1,
    borderRightColor: 'lightgrey',
    borderLeftWidth: 1,
    borderLeftColor: 'lightgrey',
  },
  rightContent: {
    width: 70,
  },
  rightContentText: {
    textAlign: 'right' as 'right',
  },
};

const ProductOrderPreview = ({
  id,
  quantity,
  unit,
  name,
  idOrSku,
  price,
  theme,
  currency,
  isRemoveIconVisible,
  onRemove,
}: Props) => {
  const lineWrapperStyle: string = useMemo(() => {
    let style: string = 'gap-sm rounded-sm flex justify-between items-center flex-1 h-[30px] px-[6px]';
    switch (theme) {
      case 'DARK':
        style = `${style} bg-[rgb(248,250,252)] text-blue-gray-200`;
        break;
      default:
        style = `${style} bg-white text-blue-gray-200`;
        break;
    }
    return style;
  }, [theme]);

  const onRemoveIconPress = useCallback(() => {
    if (onRemove) onRemove(id);
  }, [id, onRemove]);

  return (
    <div className="flex w-full flex-1 items-center">
      {isRemoveIconVisible ? (
        <Button title="x" onClick={onRemoveIconPress} />
      ) : null}
      <div className={lineWrapperStyle}>
        <div style={styles.leftContent}>
          <div>{`${quantity || '-'} ${unit}`}</div>
        </div>
        <div style={styles.mainContent} className="flex-1 px-sm">
          <OverflowEllipsisParagraph maxLines={1}>
            {`${name}${idOrSku ? ` (${idOrSku})` : ''}`}
          </OverflowEllipsisParagraph>
        </div>
        <div style={styles.rightContent}>
          <div
            style={styles.rightContentText}
            className="!font-mono text-sm text-blue-gray-200"
          >
            {formatPrice(price || 0, currency)}
          </div>
        </div>
      </div>
    </div>
  );
};

ProductOrderPreview.defaultProps = {
  price: '-',
  currency: 'CHF',
  theme: 'DARK',
  idOrSku: null,
  isRemoveIconVisible: false,
  onRemove: null,
};

export { ProductOrderPreview };
