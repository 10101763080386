import {
  forwardRef, useCallback, useImperativeHandle, useState,
} from 'react';
import { InviteTeammemberSidebar } from '../../Sidebars/InviteTeammember';

interface Props {
  children: React.ReactNode;

  onComplete: () => void;
}

const InviteTeammeberSidebarWrapper = forwardRef(
  ({ children, onComplete }: Props, ref) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const openSidebar = useCallback(() => {
      setIsSidebarOpen(true);
    }, []);

    const onSidebarClose = useCallback(() => {
      setIsSidebarOpen(false);
    }, []);

    useImperativeHandle(ref, () => ({
      openSidebar,
      onSidebarClose,
    }));
    return (
      <>
        {children}

        <InviteTeammemberSidebar
          sidebarOpen={isSidebarOpen}
          setSidebarOpen={setIsSidebarOpen}
          onComplete={onComplete}
        />
      </>
    );
  },
);

export default InviteTeammeberSidebarWrapper;
