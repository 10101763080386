import { useMemo } from 'react';
import {
  DetailPanelContentType,
  useDetailPanelContent,
} from '../../../contexts/useDetailPanelContentContext';
import { UserInfoPanel } from './detail-panels/UserInfoPanel';
import { OrderDraftPanel } from '../../order/order-draft/OrderDraftPanel';
import { OrderDetailPanel } from './detail-panels/OrderDetailPanel';

interface Props {
  chatId?: string;
  onOrderProcessed?: (orderId: string) => void;
}

const DetailPanel = ({ chatId, onOrderProcessed }: Props) => {
  const { detailPanelContent } = useDetailPanelContent();

  const ContentNode = useMemo(() => {
    switch (detailPanelContent) {
      case DetailPanelContentType.USER_INFO:
        return <UserInfoPanel chatId={chatId} />;
      case DetailPanelContentType.ORDER_DRAFT_CHAT:
        return (
          <OrderDraftPanel showOnCancel onOrderProcessed={onOrderProcessed} />
        );
      case DetailPanelContentType.ORDER_DRAFT_PROCESSING:
        return (
          <OrderDraftPanel
            onOrderProcessed={onOrderProcessed}
            isOrderProcessingMode
          />
        );
      case DetailPanelContentType.NEW_ORDER_DRAFT_PROCESSING:
        return (
          <OrderDraftPanel
            onOrderProcessed={onOrderProcessed}
            isOrderProcessingMode
            isNewOrderDraft
          />
        );
      case DetailPanelContentType.ORDER_DETAIL:
        return (
          <OrderDetailPanel />
        );
      default:
        return null;
    }
  }, [chatId, detailPanelContent, onOrderProcessed]);

  return (
    <div className="flex h-full w-full flex-col overflow-hidden bg-white">
      <div className="flex w-full flex-1 flex-col overflow-hidden p-lg">
        {ContentNode}
      </div>
      {/* <div>
        <Button
          title="Detail Panel"
          onClick={() => setDetailPanelWidth(
            (current) => (current === DetailPanelWidthType.SMALL ? DetailPanelWidthType.LARGE : DetailPanelWidthType.SMALL),
          )}
        />
      </div> */}
    </div>
  );
};

export default DetailPanel;
