import { useSearchParams } from 'react-router-dom';
import { ProtectedScreen } from '../../components/ui/ProtectedScreen';
import { MessagesPage } from '../../pages/MessagesPage';
import { MessageIntent } from '../../models/Message';
import { MessageProviderType, MessagesProvider } from '../../contexts/useMessagesContext';

const MessagesScreen = () => {
  const [searchParams] = useSearchParams();

  const unreadOnly = searchParams.get('unread_only') === 'true';
  const intent = searchParams.get('intent') as MessageIntent | null || null;
  // const messageSources = searchParams.get('message_sources')?.split(',') as MessageSource[];

  return (
    <ProtectedScreen>
      <MessagesProvider
        providerType={MessageProviderType.PLAIN}
        defaultFilter={{
          unreadOnly, intent, includeBusinessSentBy: true,
        }}
      >
        <MessagesPage />
      </MessagesProvider>
    </ProtectedScreen>
  );
};

export { MessagesScreen };
