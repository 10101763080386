import { useEffect, useState } from 'react';
import { MantineProvider, MantineTheme, DEFAULT_THEME } from '@mantine/core';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { AppGlobalProviders } from './AppGlobalProviders';
import { LoadingIndicator } from './components/LoadingIndicator';
import { Routing } from './routing/Routing';
import { globalAlertData, initGlobalAlertData } from './state/globalAlertData';
import { initGlobalUser } from './state/globalUser';
import { initDateTimeHelper } from './helpers/dateTime';
import { initGlobalChats } from './state/globalChats';
import { initGlobalEffects } from './state/globalEffects';
import { initGlobalOrderDrafts } from './state/globalOrderDrafts';
import { initGlobalSseSources } from './state/globalSseSources';
import { initGlobalMessageIntents } from './state/globalMessageIntents';

import '@mantine/carousel/styles.css';
import '@mantine/charts/styles.css';

const theme: MantineTheme = {
  ...DEFAULT_THEME,
  primaryColor: 'blue',
  colors: {
    ...DEFAULT_THEME.colors,
    blue: [
      '#EEF5FE',
      '#CAE0FD',
      '#B0D1FB',
      '#8CBBFA',
      '#75AEF9',
      '#539AF7',
      '#4C8CE1',
      '#3B6DAF',
      '#2E5588',
      '#234168',
    ],
  },
};

const App = () => {
  const [appIsReady, setAppIsReady] = useState(false);

  useEffect(() => {
    // Indipendent helpers and logics init
    initDateTimeHelper();

    // Not persisted mobx states and other sync inits
    initGlobalOrderDrafts();
    initGlobalAlertData();
    initGlobalSseSources();
    initGlobalMessageIntents();

    // Persisted mobx states and other async inits
    const asyncInits = [initGlobalUser(), initGlobalChats()];

    Promise.all(asyncInits).then(() => {
      initGlobalEffects();
      setAppIsReady(true);
    });
  }, []);

  if (!appIsReady) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <LoadingIndicator isLoading />
      </div>
    );
  }

  return (
    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
      <AppGlobalProviders alertData={globalAlertData}>
        <MantineProvider theme={theme}>
          <Routing />
        </MantineProvider>
      </AppGlobalProviders>
    </GoogleOAuthProvider>
  );
};

export { App };
