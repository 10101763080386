import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { LoadingOverlay, Textarea } from '@mantine/core';

import { Select, SelectOption } from '../../../ui/Select';
import { Order } from '../../../../models/Order';
import { Button } from '../../../ui/Button';
import { useTeamMemnersContext } from '../../../../contexts/useTeamMembersContext';
import { globalUser } from '../../../../state/globalUser';

interface Props {
  order: Order;
  assignOrder: (
    teamMemberId: string,
    teamMemberName: string,
    assignedComment: string,
  ) => void;

  // Optional
  onCancel?: () => void;
  preventFetch?: boolean;
}
const Assignee = ({
  order,
  assignOrder,

  onCancel,
  preventFetch = false,
}: Props) => {
  const {
    teamMembers, isLoading, loadTeamMembers,
  } = useTeamMemnersContext();
  const [assignedTeamMember, setAssignedTeamMember] = useState<SelectOption>(null);
  const [assignedComment, setAssignedComment] = useState<string>('');

  const teamMembersOptions = useMemo(
    () => {
      const options = teamMembers.map((u) => ({
        label: `${u.firstName} ${u.lastName}`,
        value: u.id,
      }));

      if (!options.find((u) => u.value === globalUser.id)) {
        options.push({
          label: `${globalUser.firstName} ${globalUser.lastName}`,
          value: globalUser.id,
        });
      }
      return options;
    },
    [teamMembers],
  );

  useEffect(() => {
    if (teamMembers.length > 0) {
      setAssignedTeamMember(
        teamMembersOptions.find((u) => u.value === globalUser.id) || null,
      );
    }
  }, [teamMembers.length, teamMembersOptions]);

  const onTeamMemberSelectChange = useCallback(
    (selected: { value: string; label: string }) => {
      setAssignedTeamMember(selected);
    },
    [],
  );

  const onAssignButtonClick = useCallback(() => {
    if (assignedTeamMember?.value) {
      assignOrder(
        assignedTeamMember.value,
        assignedTeamMember.label,
        assignedComment,
      );
    }
  }, [
    assignedTeamMember?.value,
    assignedTeamMember?.label,
    assignOrder,
    assignedComment,
  ]);

  const onScrolledEnd = useCallback(() => {
    loadTeamMembers();
  }, [loadTeamMembers]);

  // Set comment if present
  useEffect(() => {
    if (preventFetch) return;

    if (order?.draft?.comment) {
      setAssignedComment(order.draft.comment);
    } else {
      setAssignedComment('');
    }
  }, [order, setAssignedComment, preventFetch]);

  if (isLoading) {
    return (
      <div className="relative flex h-full flex-1 items-center justify-center">
        <LoadingOverlay
          visible
          loaderProps={{ type: 'dots' }}
          overlayProps={{ blur: 2 }}
        />
      </div>
    );
  }
  return (
    <div>
      {/* title */}
      <div className="pb-xl">
        <h1 className="pb-sm text-title-sm font-semibold">
          Order Assignment Required
        </h1>
        <div className="text-label-caption-md text-description">
          This order has not yet been assigned. Please assign it to yourself or
          another team member before proceeding. You may also add a comment if
          necessary.
        </div>
      </div>
      {/* main */}
      <div className="space-y-4">
        <Select
          label="Assignee"
          placeholder="Assign to..."
          required
          options={teamMembersOptions}
          selectedOption={assignedTeamMember}
          onSelectionChange={onTeamMemberSelectChange}
          onScrolledEnd={onScrolledEnd}
          isExternalSearchEnabled
        />

        <Textarea
          placeholder="Add some comment if needed..."
          label="Comment"
          autosize
          minRows={2}
          maxRows={4}
          value={assignedComment}
          onChange={(event) => setAssignedComment(event.currentTarget.value)}
        />

        <div
          className={`flex w-full ${onCancel ? 'justify-between' : 'justify-end'}`}
        >
          {onCancel && (
            <Button title="Cancel" theme="secondary" onClick={onCancel} />
          )}
          <Button
            title="Assign"
            theme="primary"
            disabled={assignedTeamMember === null}
            onClick={onAssignButtonClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Assignee;
