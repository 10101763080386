import { useParams } from 'react-router-dom';

import { ProtectedScreen } from '../../components/ui/ProtectedScreen';
import { OrderPage } from '../../pages/OrderPage';

const OrderScreen = () => {
  const { orderId } = useParams();

  return (
    <ProtectedScreen>
      <OrderPage orderId={orderId} />
    </ProtectedScreen>
  );
};

export { OrderScreen };
