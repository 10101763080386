import {
  forwardRef, useCallback, useImperativeHandle, useState,
} from 'react';

import { Business } from '../../../models/Business';
import { ManageCustomerSidebar } from '../../Sidebars/ManageCustomer/ManageCustomerSidebar';

interface Props {
  children: React.ReactNode;

  customer: Business;
  onComplete: (customer: Business) => void;
}

const ManageCustomerSidebarWrapper = forwardRef(
  ({ children, customer, onComplete }: Props, ref) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const onManageCustomerButtonClick = useCallback(() => {
      setIsSidebarOpen(true);
    }, []);

    const onSidebarClose = useCallback(() => {
      setIsSidebarOpen(false);
    }, []);

    useImperativeHandle(ref, () => ({
      onManageCustomerButtonClick,
      onSidebarClose,
    }));
    return (
      <>
        {children}

        <ManageCustomerSidebar
          customer={customer}
          sidebarOpen={isSidebarOpen}
          setSidebarOpen={setIsSidebarOpen}
          onComplete={onComplete}
        />
      </>
    );
  },
);

export default ManageCustomerSidebarWrapper;
