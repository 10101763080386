import { ProtectedScreen } from '../../components/ui/ProtectedScreen';
import { MockedAssistanceRequstPage } from '../../pages/MockedAssistanceRequestPage';

const MockedAssistanceRequstScreen = () => (
  <ProtectedScreen>
    <MockedAssistanceRequstPage />
  </ProtectedScreen>
);

export { MockedAssistanceRequstScreen };
