import { Page } from '../components/layout/Page/Page';
import { MessageListTable } from '../components/messages';

const MessagesPage = () => (
  <Page contentWithPadding>
    <div className="flex-1 bg-white flex flex-col overflow-hidden gap-3">
      <MessageListTable />
    </div>
  </Page>
);

export { MessagesPage };
