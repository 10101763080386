import { useState } from 'react';
import { Page } from '../components/layout/Page/Page';

import { Business } from '../models/Business';

interface Props {
  businessId: string;
  navigateToCustomersList: () => void;
}

const CustomerPage = ({ businessId, navigateToCustomersList }: Props) => {
  const [customer, setCustomer] = useState<Business>();
  const [isLoading, setIsLoading] = useState(false);

  console.log(
    businessId,
    navigateToCustomersList,
    customer,
    setCustomer,
    setIsLoading,
  );

  return (
    <Page isLoading={isLoading}>
      Customer page content
    </Page>
  );
};

export { CustomerPage };
