import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InvitedSignupPage } from '../../pages/InvitedSignupPage';
import { ROUTES } from '../../config/routes';

const InvitedSignupScreen = () => {
  const { appLink } = useParams();
  const navigate = useNavigate();

  const navigateToHome = useCallback(() => {
    navigate(ROUTES.HOME);
  }, [navigate]);

  return (
    <InvitedSignupPage inviteAppLink={appLink} navigateToHome={navigateToHome} />
  );
};

export { InvitedSignupScreen };
