import Dayjs from 'dayjs';
import {
  useMemo,
} from 'react';
import { OrderDeliveryStatusTrail } from '../../../../../models/Order';
import { formatPrice } from '../../../../../helpers/formatting';
import { formatOrderStatus, getOrderStatusColor } from '../../../../../helpers/enums';
import { OverflowEllipsisParagraph } from '../../../../ui/OverflowEllipsisParagraph';
import { FieldDisplay } from '../../../../FieldDisplay';
import { ProductOrderPreview } from '../../../../ProductOrderPreview';
import { ChatMessage } from '../../../../ChatMessage';
import { useOrderContext } from '../../../../../contexts/useOrderContext';

const styles = {
  statusDot: {
    width: 16,
    height: 16,
  },
};

const OrderDetailPanel = () => {
  const { groupOrders, selectedIndex } = useOrderContext();

  const order = useMemo(() => groupOrders[selectedIndex], [groupOrders, selectedIndex]);

  const totalPricePreview = useMemo(
    () => formatPrice(order?.totalPrice?.amount || 0, order?.totalPrice?.currency),
    [order?.totalPrice],
  );

  const numberOfProductsPreview = useMemo(
    () => (order && order.products.length
      ? `${order.products.length} products`
      : null),
    [order],
  );

  const statusStyle = useMemo(
    () => ({
      ...styles.statusDot,
      backgroundColor: getOrderStatusColor(order?.status),
    }),
    [order?.status],
  );

  if (!order) {
    return null;
  }

  return (
    <div className="flex-1 gap-lg overflow-auto p-lg">
      <div className="flex w-full max-w-[600px] flex-col gap-lg">
        <div className="flex gap-sm">
          <div className="rounded-4xl border border-blue-gray-100 bg-white px-lg py-md">
            <div className="flex items-center gap-md">
              <OverflowEllipsisParagraph>
                {`Order ${formatOrderStatus(order?.status)}`}
              </OverflowEllipsisParagraph>
              <div style={statusStyle} className="rounded-4xl" />
            </div>
          </div>
          {order.isDraft ? (
            <div className="rounded-4xl border border-blue-gray-100 bg-white px-lg py-md">
              <div className="flex items-center gap-md">Draft</div>
            </div>
          ) : null}
        </div>

        <div className="flex flex-col gap-lg">
          <FieldDisplay label="Customer">
            <div>{order.customer.name}</div>
          </FieldDisplay>

          <FieldDisplay label="Created at">
            <div>{Dayjs(order.createdAt).format('LL')}</div>
          </FieldDisplay>

          <FieldDisplay label="Requested delivery">
            <div>{Dayjs(order.requestedDeliveryTime).format('llll')}</div>
          </FieldDisplay>

          <FieldDisplay label="Comment">
            <div>{order.draft.comment || '-'}</div>
          </FieldDisplay>
        </div>

        <FieldDisplay label="Products">
          <div className="flex flex-col gap-sm pb-sm pt-lg">
            {order.products.map((p) => (
              <ProductOrderPreview
                key={p.product?.id}
                id={p.product?.id}
                name={p.product?.name}
                idOrSku={p.product?.idOrSku}
                unit={p.unit?.symbol}
                price={
                      p.product?.price?.amount
                        ? p.product.price.amount * p.quantity
                        : null
                    }
                currency={p.product?.price.currency}
                quantity={p.quantity}
              />
            ))}
          </div>
          {numberOfProductsPreview || totalPricePreview ? (
            <div className="mt-sm flex flex-col items-end border-t border-t-light-gray-100 p-sm">
              <div className="self-start">{numberOfProductsPreview}</div>
              <div className="self-end">{totalPricePreview}</div>
            </div>
          ) : null}
        </FieldDisplay>

        <div className="flex flex-col gap-lg">
          <FieldDisplay label="Events">
            {order.statusTrails.map((s: OrderDeliveryStatusTrail) => (
              <div
                key={s.status}
                className="mt-lg flex flex-col gap-lg rounded-sm bg-[rgb(248,250,252)] p-lg text-blue-gray-200"
              >
                <div className="flex-row justify-between">
                  <div>{`Order ${formatOrderStatus(s.status)}`}</div>
                  <div>{Dayjs(s.createdAt).format('lll')}</div>
                </div>
                {s.message ? (
                  <ChatMessage
                        // @ts-ignore
                    message={{ message: s.message }}
                    direction="RECEIVED"
                    text={s.message}
                    theme="light"
                  />
                ) : null}
              </div>
            ))}
          </FieldDisplay>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailPanel;
