import { memo } from 'react';
import { Order } from '../../../models/Order';
import { Business } from '../../../models/Business';
import { TextWithLabel } from '../../ui/TextWithLabel';

interface Props {
  order: Order;
}

const MemoizedContent = memo(({ customer }: {
  customer: Business
}) => (
  <div className="grid grid-cols-2 gap-sm space-y-1">
    <TextWithLabel
      label="Name"
      value={customer?.name}
      className="col-span-2"
    />

    {
      customer?.externalId && (
        <TextWithLabel
          label="Customer ID"
          value={customer?.externalId}
          className="col-span-1"
        />
      )
    }

    {
      customer?.shortName && (
        <TextWithLabel
          label="Short Name"
          value={customer?.shortName}
          className="col-span-1"
        />
      )
    }

    {
      customer?.address && (
        <TextWithLabel
          label="Address"
          value={customer?.address}
          className="col-span-2"
        />
      )
    }

    {
      customer?.emails && (
        <TextWithLabel
          label="Emails"
          value={(customer?.emails || []).join(', ')}
          className="col-span-2"
        />
      )
    }

    {
      customer?.phones && (
        <TextWithLabel
          label="Phones"
          value={(customer?.phones || []).map((phone) => phone.number).join(', ')}
          className="col-span-2"
        />
      )
    }
  </div>
));

const Customer = ({ order }: Props) => (
  <MemoizedContent customer={order?.customer} />
);

export default Customer;
