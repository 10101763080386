import { ProtectedScreen } from '../../components/ui/ProtectedScreen';
import { NewAdamChatPage } from '../../pages/NewAdamChatPage';

const NewAdamChatScreen = () => (
  <ProtectedScreen>
    <NewAdamChatPage />
  </ProtectedScreen>
);

export { NewAdamChatScreen };
