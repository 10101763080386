import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProtectedScreen } from '../../components/ui/ProtectedScreen';
import { ROUTES } from '../../config/routes';
import { ProcessOrderDraftsPage } from '../../pages/ProcessOrderDraftsPage';

const ProcessOrderDraftsScreen = () => {
  const navigate = useNavigate();

  const navigateToHome = useCallback(() => {
    navigate(ROUTES.HOME);
  }, [navigate]);

  return (
    <ProtectedScreen>
      <ProcessOrderDraftsPage navigateToHome={navigateToHome} />
    </ProtectedScreen>
  );
};

export { ProcessOrderDraftsScreen };
