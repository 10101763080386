import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProductsListPage } from '../../pages/ProductsListPage';
import { ProtectedScreen } from '../../components/ui/ProtectedScreen';
import { ROUTES } from '../../config/routes';

const ProductsListScreen = () => {
  const navigate = useNavigate();

  const navigateToProductsImportList = useCallback(() => {
    navigate(ROUTES.PRODUCTS_IMPORT);
  }, [navigate]);

  return (
    <ProtectedScreen>
      <ProductsListPage
        navigateToProductsImportList={navigateToProductsImportList}
      />
    </ProtectedScreen>
  );
};

export { ProductsListScreen };
