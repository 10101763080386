import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ProtectedScreen } from '../../components/ui/ProtectedScreen';
import MessageByIdPage from '../../pages/MessageByIdPage';
import { MessageProviderType, MessagesProvider } from '../../contexts/useMessagesContext';
import { PlayRecordingProvider } from '../../contexts/usePlayRecordingIndex';
import { KeywordContext } from '../../contexts/useKeywordContext';

const MessageByIdScreen = () => {
  const { messageId } = useParams();

  const [hoverKeyword, setHoverKeyword] = useState<string>('');
  const [scrollKeyword, setScrollKeyword] = useState<string>('');

  const keywordContextValue = useMemo(
    () => ({
      hoverKeyword,
      setHoverKeyword,
      scrollKeyword,
      setScrollKeyword,
    }),
    [hoverKeyword, scrollKeyword],
  );

  return (
    <ProtectedScreen>
      <MessagesProvider
        providerType={MessageProviderType.BY_MESSAGE_ID}
        messageId={messageId}
      >
        <PlayRecordingProvider>
          <KeywordContext.Provider value={keywordContextValue}>
            <MessageByIdPage />
          </KeywordContext.Provider>
        </PlayRecordingProvider>
      </MessagesProvider>
    </ProtectedScreen>
  );
};

export { MessageByIdScreen };
