import { memo } from 'react';
import { Message, MessageRefType } from '../../../../../models/Message';
import { Order } from '../../../../../models/Order';
import { OrderTags } from './OrderTags';

interface Props {
  message: Message;
  order: Order;
  isIntersecting: boolean;
  isOrderLoading: boolean;
}

const TagBase = memo(
  ({
    message, order, isIntersecting, isOrderLoading,
  }: Props) => {
    if (
      message.refType === MessageRefType.ORDER
      && message.ref
      && isIntersecting
    ) {
      return <OrderTags order={order} isOrderLoading={isOrderLoading} />;
    }

    return null;
  },
);

export default TagBase;
