import { FieldSpec, Schema } from '../models/Schema';
import { getFieldByPath } from './schema';

export const groupFields = (fields: FieldSpec[], schema: Schema): Record<string, FieldSpec[]> => {
  if (!fields || !schema) return {};

  return fields?.reduce(
    (acc: Record<string, FieldSpec[]>, field) => {
      const parentPath = field.lastCardableParentPath;

      let parentName = '';
      if (parentPath?.includes('.')) {
        parentName = getFieldByPath(schema?.fields, parentPath)?.name || parentPath;
      } else {
        parentName = 'Standard fields';
      }

      if (!acc[parentName]) {
        acc[parentName] = [];
      }
      acc[parentName].push(field);
      return acc;
    },
    {},
  );
};
