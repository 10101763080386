import {
  memo, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import { twMerge } from 'tailwind-merge';
import {
  Message, MessageRefType, MessageContextUtils,
} from '../../../../models/Message';
import { MessageDirection } from '../../../../types/message';
import { EllipsisButton } from './EllipsisButton';
import { RecordingMessage } from './RecordingMessage';
import { MoveMessageModalWrapper } from '../../../wrapper/MoveMessageModalWrapper';
import { MessageDatetimeWrapper } from '../../../wrapper/MessageDatetimeWrapper';
import { useFetchOrder } from '../../../../hooks/fetch/useFetchOrder';
import { useIntersectionObserver } from '../../../../hooks/utils/useIntersectionObserver';
import { PreviewHTMLModalWrapper } from '../../../wrapper/PreviewHTMLModalWrapper';
import { MessageBodyBase } from './MessageBody';
import { Subject } from './Subject';
import { TagBase } from './Tag';
import { useOrderContext } from '../../../../contexts/useOrderContext';
import {
  DetailPanelContentType,
  useDetailPanelContent,
} from '../../../../contexts/useDetailPanelContentContext';
import { ROUTES } from '../../../../config/routes';
import { useMessagesContext } from '../../../../contexts/useMessagesContext';
import { ImageMessage } from './ImageMessage';
import { useFetchOrderDrafts } from '../../../../hooks/fetch/useFetchOrderDrafts';
import { isZeroId } from '../../../../helpers/objectId';
import { Business } from '../../../../models/Business';
import { Actions } from './Actions';
import { AttachmentList } from './AttachmentList/AttachmentList';
import { Order } from '../../../../models/Order';
import { useMarkMessageAsRead } from '../../../../hooks/useMarkMessageAsRead';
import { isZeroTime } from '../../../../helpers/dateTime';

const ChatMessageBaseWrapper = memo(({
  children,
  order,
  message,
  business,
  direction,
  removeMessageFromMessages,
  isOrderLoading,
  isOrderDraftsLoading,
  isMessageExpanded,
  handleIntersect,
  onOrderDetailsTitle,
  onOrderDetailsButtonClick,
  onCreateNewOrderButtonClick,
}: {
  children: React.ReactNode;
  message: Message;
  order?: Order;
  business?: Business;
  direction: MessageDirection;
  onOrderDetailsTitle: string;
  isMessageExpanded: boolean;
  handleIntersect: () => void;
  onOrderDetailsButtonClick: () => void;
  onCreateNewOrderButtonClick: () => void;
  removeMessageFromMessages?: (messageId: string) => void;
  isOrderLoading: boolean;
  isOrderDraftsLoading: boolean;
}) => {
  const { ref: intersectionRef, isIntersecting } = useIntersectionObserver(handleIntersect);

  const isAdded = useMemo(() => message.isAdded, [message.isAdded]);

  const { markMessagesAsRead } = useMarkMessageAsRead();
  useEffect(() => {
    if (isIntersecting && isMessageExpanded && isZeroTime(message.readAt)) {
      markMessagesAsRead([message.id]);
    }
  }, [
    isIntersecting,
    isMessageExpanded,
    message.readAt,
    markMessagesAsRead,
    message.id,
  ]);

  // Modal wrapper
  const moveMessageModalWrapperRef = useRef(null);
  const onMoveMessageClick = () => {
    if (moveMessageModalWrapperRef.current) {
      moveMessageModalWrapperRef.current.onMoveMessageButtonClick();
    }
  };

  const previewHTMLModalWrapperRef = useRef(null);
  const onPreviewHTMLClick = () => {
    if (previewHTMLModalWrapperRef.current) {
      previewHTMLModalWrapperRef.current.onShowHtmlPreviewButtonClick();
    }
  };

  return (
    <motion.div
      ref={intersectionRef}
      className={twMerge(
        'max-w-[min(70%,600px)] group',
        direction === MessageDirection.Sent && 'self-end',
      )}
      id={message.id}
      initial={isAdded ? { opacity: 0, y: 10 } : null}
      animate={isAdded ? { opacity: 1, y: 0 } : null}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <MessageDatetimeWrapper message={message} direction={direction}>
        <MoveMessageModalWrapper
          ref={moveMessageModalWrapperRef}
          message={message}
          removeMessageFromMessages={removeMessageFromMessages}
        >
          <PreviewHTMLModalWrapper
            ref={previewHTMLModalWrapperRef}
            message={message}
          >
            <div className={twMerge(
              'w-full',
              direction === MessageDirection.Sent && 'flex justify-end',
            )}
            >
              <div className="flex space-x-2 w-fit">
                <div className="group relative flex-1">
                  {children}

                  {
                    direction === MessageDirection.Received && !message.isAdamThinking && (
                      <>
                        <EllipsisButton
                          onMoveMessageButtonClick={onMoveMessageClick}
                          onOrderDetailsTitle={order ? onOrderDetailsTitle : null}
                          onOrderDetailsButtonClick={
                            order ? onOrderDetailsButtonClick : null
                          }
                          onCreateNewOrderButtonClick={
                              !order ? onCreateNewOrderButtonClick : null
                          }
                          onShowHtmlPreviewButtonClick={
                            message.context?.html ? onPreviewHTMLClick : null
                          }
                        />

                        <TagBase
                          message={message}
                          order={order}
                          isIntersecting={isIntersecting}
                          isOrderLoading={isOrderLoading || isOrderDraftsLoading}
                        />
                      </>
                    )
                  }
                </div>

                {
                  direction === MessageDirection.Received && (
                  <Actions
                    message={message}
                    business={business}
                  />
                  )
                }
              </div>
            </div>
          </PreviewHTMLModalWrapper>
        </MoveMessageModalWrapper>
      </MessageDatetimeWrapper>
    </motion.div>
  );
});

interface Props {
  message: Message;
  business?: Business;
  direction: MessageDirection;

  isExpandable?: boolean; // In case user wants to force non-expandable messages
  isOrderProcessingMode?: boolean;
  removeMessageFromMessages?: (messageId: string) => void;
}

const ChatMessageBase = memo(
  ({
    message,
    business,
    direction,

    isExpandable = false,
    isOrderProcessingMode = false,
    removeMessageFromMessages,
  }: Props) => {
    const navigate = useNavigate();

    const { setDetailPanelContent } = useDetailPanelContent();
    const { setGroupOrders, setSelectedIndex } = useOrderContext();
    // TODO: get rid of requesting order
    const {
      order,
      isLoading: isOrderLoading,
      loadOrder,
    } = useFetchOrder({ orderId: message.ref });
    const { isLoading: isOrderDraftsLoading, loadOrderDrafts } = useFetchOrderDrafts({ preventInitialFetch: true });
    const { setSelectedMessageId } = useMessagesContext();
    const [isMessageExpanded, setIsMessageExpanded] = useState(false);

    const onOrderDetailsTitle = useMemo(() => {
      if (order?.isDraft) return 'Process order draft';

      return 'See order detail';
    }, [order]);

    // Load (if it was an order) the order on interaction
    const handleIntersect = useCallback(() => {
      // This is more for the tag and applies only for chat interface
      if (isOrderProcessingMode) return;

      // If it is not an order message or no orderId found
      if (message.refType !== MessageRefType.ORDER || !message.ref) return;

      // If the order is already set
      if (order?.id === message.ref) return;

      loadOrder();
    }, [
      isOrderProcessingMode,
      loadOrder,
      message.ref,
      message.refType,
      order?.id,
    ]);

    const isExpandableMessageType = useMemo(() => {
      // If it's an order
      if (message?.refType === MessageRefType.ORDER) return true;

      // If it contains audio
      if (MessageContextUtils.audioAttachments(message.context)?.length > 0) return true;

      // If it contains documents
      if (MessageContextUtils.documentAttachments(message.context)?.length > 0) return true;

      return false;
    }, [
      message?.refType,
      message?.context,
    ]);

    // Used for positioning correctly the blur
    const isLongMessage = useMemo(
      () => message.message?.length > 300,
      [message.message?.length],
    );

    const onCreateNewOrderClick = useCallback(() => {
      navigate(
        `${ROUTES.PROCESS_NEW_ORDER_DRAFTS}?customer_id=${message.businessSentBy}&message_id=${message.id}`,
      );
    }, [message?.businessSentBy, message?.id, navigate]);

    const onOrderDetailsButtonClick = useCallback(() => {
      if (order?.isDraft) {
        if (order.groupId && !isZeroId(order.groupId)) {
          loadOrderDrafts(false, true, order.groupId).then((orders) => {
            setGroupOrders([order, ...orders]);
            setSelectedIndex(0);
          });
        } else {
          setGroupOrders([order]);
        }
        setSelectedIndex(0);
        setSelectedMessageId(message.id);
        setDetailPanelContent(DetailPanelContentType.ORDER_DRAFT_CHAT);
      } else {
        navigate(`${ROUTES.ORDERS}/${order?.id}`);
      }
    }, [
      loadOrderDrafts,
      message.id,
      navigate,
      order,
      setDetailPanelContent,
      setGroupOrders,
      setSelectedIndex,
      setSelectedMessageId,
    ]);

    if (isExpandable && !isMessageExpanded && isExpandableMessageType) {
      return (
        <ChatMessageBaseWrapper
          message={message}
          order={order}
          business={business}
          direction={direction}
          removeMessageFromMessages={removeMessageFromMessages}
          handleIntersect={handleIntersect}
          isMessageExpanded={isMessageExpanded}
          onOrderDetailsTitle={onOrderDetailsTitle}
          onOrderDetailsButtonClick={onOrderDetailsButtonClick}
          onCreateNewOrderButtonClick={onCreateNewOrderClick}
          isOrderLoading={isOrderLoading}
          isOrderDraftsLoading={isOrderDraftsLoading}
        >
          <div className="mb-sm rounded-lg border border-blue-gray-100 bg-white p-lg text-content-md">
            <Subject message={message} />
            <div className="relative cursor-pointer">
              <div
                className={twMerge(
                  'relative overflow-hidden h-fit',
                  isLongMessage ? 'max-h-[90px]' : 'max-h-[30px]',
                  direction === MessageDirection.Sent && 'bg-primary-500 text-white',
                )}
              >
                <MessageBodyBase message={message} direction={direction} />
                <div
                  className={`absolute bottom-0 left-0 ${isLongMessage ? 'h-[80%]' : 'h-[40px]'} z-50 w-full bg-gradient-to-b from-transparent to-white`}
                />
              </div>

              {/* Expand icon */}
              <div className="absolute -bottom-[5px] left-[50%] z-[100] hidden group-hover:block">
                <button
                  type="button"
                  onClick={() => setIsMessageExpanded(true)}
                  className="w-full rounded-full border bg-white p-1"
                >
                  <ChevronDownIcon className="aspect-square w-5" />
                </button>
              </div>
            </div>
          </div>
        </ChatMessageBaseWrapper>
      );
    }

    return (
      <ChatMessageBaseWrapper
        message={message}
        order={order}
        business={business}
        direction={direction}
        removeMessageFromMessages={removeMessageFromMessages}
        handleIntersect={handleIntersect}
        isMessageExpanded={isMessageExpanded}
        onOrderDetailsTitle={onOrderDetailsTitle}
        onOrderDetailsButtonClick={onOrderDetailsButtonClick}
        onCreateNewOrderButtonClick={onCreateNewOrderClick}
        isOrderLoading={isOrderLoading}
        isOrderDraftsLoading={isOrderDraftsLoading}
      >
        <div className={twMerge(
          'mb-sm rounded-lg border border-blue-gray-100 bg-white p-lg text-content-md',
          direction === MessageDirection.Sent && 'bg-primary-500 text-white',
        )}
        >
          <Subject message={message} />
          <MessageBodyBase message={message} direction={direction} />
        </div>
        <div className="hidden">{direction}</div>

        {MessageContextUtils.audioAttachments(message.context)?.length > 0 && (
          <RecordingMessage
            message={message}
            disableShortcut={!isOrderProcessingMode}
          />
        )}

        {MessageContextUtils.documentAttachments(message.context)?.length > 0 && (
          <ImageMessage message={message} />
        )}

        <AttachmentList attachments={message.context?.attachments || []} />
      </ChatMessageBaseWrapper>
    );
  },
);

export default ChatMessageBase;
