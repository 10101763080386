import { useCallback } from 'react';

import { useMessagesContext } from '../../../../contexts/useMessagesContext';
import { ChatMessagesBase } from './ChatMessagesBase';
import { Business } from '../../../../models/Business';

interface Props {
  business?: Business;
  isAdamThinking?: boolean;
}

const ChatMessages = ({ business, isAdamThinking }: Props) => {
  const {
    messages, setMessages, isLoading, loadMessages,
  } = useMessagesContext();

  const removeMessageFromMessages = useCallback(
    (messageId: string) => {
      setMessages((_messages) => _messages.filter((_message) => _message.id !== messageId),
      );
    },
    [setMessages],
  );

  return (
    <ChatMessagesBase
      messages={messages}
      business={business}
      isLoading={isLoading}
      isAdamThinking={isAdamThinking}
      loadMessages={loadMessages}
      removeMessageFromMessages={removeMessageFromMessages}
      isExpandable
    />
  );
};

export default ChatMessages;
