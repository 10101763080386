import { ChatList as ChatListComponent } from '../components/chat/chat-list';
import { Page } from '../components/layout/Page/Page';

interface Props {
  navigateToChat: (chatId: string) => void;
}

const ChatListPage = ({ navigateToChat }: Props) => (
  <Page contentWithBorder>
    <div className="flex h-full w-full">
      <div className="h-full min-w-[30%] max-w-[30%] md:min-w-[25%] md:max-w-[25%] xl:min-w-[20%] xl:max-w-[20%] 2xl:min-w-[15%] 2xl:max-w-[15%]">
        <ChatListComponent navigateToChat={navigateToChat} />
      </div>
      <div className="relative w-full" />
    </div>
  </Page>
);

export { ChatListPage };
