/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  memo,
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import Dayjs from 'dayjs';
import { LoadingOverlay } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Page } from '../components/layout/Page/Page';
import { Messages } from '../components/order/order-draft/Messages';
import { DetailPanel } from '../components/common/DetailPanel';
import { DetailPanelContentContext, DetailPanelContentType } from '../contexts/useDetailPanelContentContext';
import { useFetchOrder } from '../hooks/fetch/useFetchOrder';
import { OrderProvider, useOrderContext } from '../contexts/useOrderContext';
import { useFetchOrderDrafts } from '../hooks/fetch/useFetchOrderDrafts';
import { isZeroId } from '../helpers/objectId';
import { MessageProviderType, MessagesProvider, useMessagesContext } from '../contexts/useMessagesContext';
import { PlayRecordingProvider } from '../contexts/usePlayRecordingIndex';
import { KeywordContext } from '../contexts/useKeywordContext';
import { Card } from '../components/common/Card';
import { ProductWithQuantity } from '../models/Order';
import { OrderStatusBadge } from '../components/OrderStatusBadge';
import {
  Customer, Details, Events, Products,
} from '../components/order/order-detail';
import { ROUTES } from '../config/routes';

interface Props {
  orderId: string;
}

const PageContent = memo(({ orderId }: Props) => {
  const navigate = useNavigate();

  const { order, isLoading: isOrderLoading } = useFetchOrder({
    orderId,
    preventFetch: false,
  });
  const { isLoading: isGroupLoading, loadOrderDrafts } = useFetchOrderDrafts({
    preventInitialFetch: true,
  });

  const {
    groupOrders, setGroupOrders, setSelectedIndex, selectedIndex,
  } = useOrderContext();
  const { messages, isLoading: isMessageLoading } = useMessagesContext();

  const selectedOrder = useMemo(() => groupOrders[selectedIndex], [groupOrders, selectedIndex]);

  const isLoading = useMemo(
    () => isOrderLoading || isGroupLoading,
    [isOrderLoading, isGroupLoading],
  );

  const onBackButtonClick = useCallback(() => {
    navigate(ROUTES.ORDERS);
  }, [navigate]);

  useEffect(() => {
    if (order && setGroupOrders && setSelectedIndex && loadOrderDrafts) {
      if (order.groupId && !isZeroId(order.groupId)) {
        // Get order groups
        loadOrderDrafts(false, true, order.groupId).then((orders) => {
          setGroupOrders([order, ...(orders || [])]);
        });
      } else {
        setGroupOrders([{
          ...order,
          products: (order.products || []).map((product) => ({ ...product })),
        }]);
      }

      setSelectedIndex(0);
    }
  }, [loadOrderDrafts, order, setGroupOrders, setSelectedIndex]);

  return (
    <div className="h-full flex-1 p-lg overflow-hidden flex flex-col gap-lg">
      <div className="flex items-center space-x-4">
        <div>
          <button type="button" onClick={onBackButtonClick}>
            <ArrowLeftIcon className="w-5 h-5" />
          </button>
        </div>
        <div className="space-y-2">
          <div className="flex gap-lg">
            <h1 className="text-2xl font-semibold leading-none text-gray-900">
              Order details
            </h1>

            {/* Tags */}
            <div className="h-4">
              <OrderStatusBadge status={selectedOrder?.status} />
            </div>
          </div>
          <div className="text-sm">
            {Dayjs(selectedOrder?.createdAt).format('MMM D, YYYY')}
            {' '}
            from
            {' '}
            {selectedOrder?.customer?.name}
          </div>
        </div>
      </div>
      <div className="flex-1 grid grid-cols-6 w-full gap-lg overflow-hidden relative">
        <LoadingOverlay
          visible={isMessageLoading}
          loaderProps={{ type: 'dots' }}
          overlayProps={{ blur: 2 }}
        />
        <div className="col-span-3 overflow-y-auto h-full hidden-scrollbar space-y-3">
          <Card openAccordion accordion className="col-span-3">
            <Card.Header>Details</Card.Header>
            <Card.Body className="block">
              <Details order={selectedOrder} />
            </Card.Body>
          </Card>

          <Card accordion className="col-span-3">
            <Card.Header>Customer</Card.Header>
            <Card.Body className="block">
              <Customer order={selectedOrder} />
            </Card.Body>
          </Card>

          <Card accordion className="col-span-3">
            <Card.Header>Messages</Card.Header>
            <Card.Body className="block">
              <div className="h-[40vh] flex flex-col w-full">
                <Messages
                  messages={messages}
                  customer={groupOrders[0]?.customer}
                />
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-span-3 overflow-y-auto hidden-scrollbar h-full space-y-3">
          <Card openAccordion accordion className="col-span-2">
            <Card.Header>Products</Card.Header>
            <Card.Body className="block">
              <Products order={selectedOrder} />
            </Card.Body>
          </Card>

          <Card openAccordion accordion className="col-span-2">
            <Card.Header>Events</Card.Header>
            <Card.Body className="block">
              <Events order={selectedOrder} />
            </Card.Body>
          </Card>
        </div>
      </div>
      {/* <div className="max-[50%] relative h-full min-w-[50%] overflow-hidden border-r border-blue-gray-50">
        <LoadingOverlay
          visible={isMessageLoading}
          loaderProps={{ type: 'dots' }}
          overlayProps={{ blur: 2 }}
        />
        <Messages
          messages={messages}
          customer={groupOrders[0]?.customer}
          // onManageCustomerButtonClick={onManageCustomerButtonClick}
        />
      </div>
      <div className="max-[50%] relative h-full min-w-[50%]">
        <LoadingOverlay
          visible={isLoading}
          loaderProps={{ type: 'dots' }}
          overlayProps={{ blur: 2 }}
        />
        <DetailPanel />
      </div> */}
    </div>
  );
});

const OrderPage = ({ orderId }: Props) => {
  const [detailPanelContent, setDetailPanelContent] = useState<DetailPanelContentType>(
    DetailPanelContentType.ORDER_DETAIL,
  );
  const [hoverKeyword, setHoverKeyword] = useState<string>('');
  const [scrollKeyword, setScrollKeyword] = useState<string>('');

  const keywordContextValue = useMemo(
    () => ({
      hoverKeyword,
      setHoverKeyword,
      scrollKeyword,
      setScrollKeyword,
    }),
    [hoverKeyword, scrollKeyword],
  );

  const detailPanelContentContextValue = useMemo(
    () => ({
      detailPanelContent,
      setDetailPanelContent,
    }),
    [detailPanelContent],
  );

  return (
    <Page contentWithPadding>
      <MessagesProvider
        providerType={MessageProviderType.BY_REFERENCE_ID}
        referenceId={orderId}
      >
        <PlayRecordingProvider>
          <KeywordContext.Provider value={keywordContextValue}>
            <DetailPanelContentContext.Provider
              value={detailPanelContentContextValue}
            >
              <OrderProvider>
                <PageContent orderId={orderId} />
              </OrderProvider>
            </DetailPanelContentContext.Provider>
          </KeywordContext.Provider>
        </PlayRecordingProvider>
      </MessagesProvider>
    </Page>
  );
};

export { OrderPage };
