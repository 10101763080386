import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { Card, Text } from '@mantine/core';
import { Page } from '../components/layout/Page/Page';
import { BluePinkyBackground } from '../components/background/BluePinkyBackground';
import { globalUser } from '../state/globalUser';

const ActivateUserPage = observer(() => {
  useEffect(() => {
    globalUser.signOut();
  }, []);

  return (
    <Page hideMenu hideHeader>
      <div className="flex flex-1 items-start justify-center p-lg">
        <div className="relative flex h-full w-full items-center justify-center space-y-5">
          <BluePinkyBackground />

          <Card
            shadow="md"
            padding="lg"
            radius="md"
            className="w-[80%] sm:w-[30%]"
          >
            <Card.Section py="md" withBorder inheritPadding>
              <Text fw={600}>User verification required</Text>
            </Card.Section>

            <Card.Section py="md" withBorder inheritPadding>
              <Text>
                In order to use the application you need to verify your user.
                Please check your email and follow the instructions. Press retry
                if you just activated it.
              </Text>
            </Card.Section>
          </Card>
        </div>
      </div>
    </Page>
  );
});

export { ActivateUserPage };
