import { useState, useCallback } from 'react';

import { httpPost } from '../helpers/xhr';
import { genericErrorFeedback } from '../helpers/errors';
import { globalAlertData } from '../state/globalAlertData';

const useConfirmOrderDraft = () => {
  const [isLoading, setIsLoading] = useState(false);

  const confirmOrderDraft = useCallback((id: string): Promise<void> => {
    setIsLoading(true);
    return httpPost(`/orders/drafts/${id}/confirm`, { id })
      .then(() => {
        globalAlertData.create('Order confirmed successfully');
      })
      .catch((error) => {
        genericErrorFeedback('Error confirming order draft')(error);
        return Promise.reject(error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return { isLoading, confirmOrderDraft };
};

export { useConfirmOrderDraft };
