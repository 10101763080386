import { useState, useCallback } from 'react';

import { httpGet } from '../../helpers/xhr';
import { genericErrorFeedback } from '../../helpers/errors';
import { Business } from '../../models/Business';

const useFetchCustomerById = () => {
  const [customer, setCustomer] = useState<Business>(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadCustomer = useCallback(
    (
      customerId: string,
      giveErrorFeedback: boolean = true,
    ): Promise<Business> => {
      if (!customerId) return Promise.reject();
      setIsLoading(true);
      return httpGet(`/businesses/me/customers/${customerId}`)
        .then((response) => {
          setCustomer(response.data);
          return response.data;
        })
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('Error occured during loading messages.')(
              error,
            );
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return {
    customer,
    loadCustomer,
    isLoading,
  };
};

export { useFetchCustomerById };
