import { memo } from 'react';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/24/outline';
import {
  format,
  differenceInSeconds,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from 'date-fns';

import { Event } from '../../../models/Event';
import { Operation } from '../../../models/Lro';
import { ScrollAreaWrapper } from '../../wrapper/ScrollAreaWrapper';
import { useFetchEvents } from '../../../hooks/fetch/useFetchEvents';

const getTitle = (event: Event) => {
  switch (event.type) {
    case 'new_chat':
      return 'New chat created';
    case 'new_order':
      return 'New order placed';
    case 'lro_done':
      if (event.data) {
        const lro: Operation = JSON.parse(event.data);
        if (lro?.group === 'workflow') {
          return 'Workflow run completed';
        }
      }
      break;
    default:
      break;
  }

  return '';
};

const getEventTime = (event: Event) => {
  const eventTime = new Date(event.createdAt);
  const now = new Date();
  const diffInSeconds = differenceInSeconds(now, eventTime);

  if (diffInSeconds < 10) {
    return 'Just now';
  }

  const diffInDays = differenceInDays(now, eventTime);
  const diffInMonths = differenceInMonths(now, eventTime);
  const diffInYears = differenceInYears(now, eventTime);

  if (diffInDays === 0) {
    return format(eventTime, 'HH:mm');
  }
  if (diffInDays === 1) {
    return 'Yesterday';
  }
  if (diffInDays <= 30) {
    return `${diffInDays} days ago`;
  }
  if (diffInMonths <= 12) {
    return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''} ago`;
  }
  return `${diffInYears} year${diffInYears > 1 ? 's' : ''} ago`;
};

interface Props {
  showBadge?: boolean;
}

const NotificationBell = memo(({ showBadge = false }: Props) => {
  const { events, loadEvents } = useFetchEvents({});

  return (
    <Popover className="relative">
      <PopoverButton className="group flex cursor-pointer items-center gap-smd rounded-md p-smd text-sm leading-6 text-gray-600 outline-none ring-0">
        <div className="relative">
          <BellIcon
            width={24}
            height={24}
            className="text-[rgba(107,114,128,1)] group-hover:text-primary"
          />
          {/* badge */}
          <span
            className={`absolute right-0.5 top-0 aspect-square w-2 rounded-full bg-danger-500 ${!showBadge && 'hidden'}`}
          />
        </div>
      </PopoverButton>

      <PopoverPanel className="absolute left-1/2 z-10 mt-1 flex w-screen max-w-max -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in">
        <div className="w-[300px] flex-auto overflow-hidden rounded-sm border border-neutral-50 bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
          <ScrollAreaWrapper
            maxVh={0.5}
            className="w-full py-2"
            onScrolledEnd={() => loadEvents({})}
            offsetScrollbar
          >
            <div className="space-y-1 px-2">
              {events.map((event) => (
                <div
                  key={event.id}
                  className="group relative flex max-w-md cursor-pointer gap-x-2 rounded-sm px-1 py-2 hover:bg-gray-50"
                >
                  <div className="flex items-start">
                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-neutral-100">
                      <p className="text-lg font-bold text-white">
                        {event.topic[0].toUpperCase()}
                      </p>
                    </div>
                  </div>
                  <div className="flex-1 overflow-hidden">
                    {/* <p className='text-neutral-900 font-semibold'>{getTitle(event)}</p> */}
                    <p className="line-clamp-2 w-full max-w-full overflow-hidden text-ellipsis leading-tight text-neutral-800">
                      {getTitle(event)}
                    </p>
                    <p className="text-xs text-neutral-300">
                      {getEventTime(event)}
                    </p>
                  </div>

                  <span className="absolute right-0 top-0 flex h-full items-center p-1">
                    <span className="aspect-square w-2 rounded-full bg-primary-500" />
                  </span>
                </div>
              ))}
            </div>

            <div className="hidden bg-gray-50 p-2">
              <div className="flex justify-end">
                <p className="text-primary-600">Mark as read</p>
              </div>
            </div>
          </ScrollAreaWrapper>
        </div>
      </PopoverPanel>
    </Popover>
  );
});

export { NotificationBell };
