export enum Workflow {
  Other = '',
  Order = 'order',
}

export type WorkflowRun = {
  id: string;

  businessId: string;

  messageId: string;

  bridgeType: string;

  state: WorkflowRunState;

  stateHistory: WorkflowRunState[];

  createdAt: string;

  updatedAt: string;
};

export enum WorkflowRunSideEffectType {
  Order = 'order',
}

export type WorkflowRunSideEffect = {
  refId: string;

  type: WorkflowRunSideEffectType;
};

export enum WorkflowRunFSMState {
  Ready = 'ready',
  Done = 'done',
  Failed = 'failed',
  Retry = 'retry',
}

export type WorkflowRunState = {
  fsmState: WorkflowRunFSMState;

  workflow: string;

  sideEffects: WorkflowRunSideEffect[];
};

export class OrderParserMessageContext {
  audioKeywords?: AudioKeyword[]; // Keywords extracted from audio attachments

  audioLanguage?: string; // Language of the audio

  audioTranscript?: string; // Transcript of the audio

  keywords?: TextKeyword[]; // Keywords extracted from the email body

  ocrKeywords: OCRKeyword[]; // Keywords extracted from documents
}

export class AudioKeyword {
  word: string;

  originalWord: string;

  start: number;

  end: number;

  offset: number;

  probability: number;
}

export class TextKeyword {
  originalWord: string;

  word: string;

  offset: number;
}

export class OCRKeyword {
  word: string;

  geometry: number[];

  docIndex: number;

  pageIndex: number;

  pageDimensions: number[];
}
