import {
  ChatBubbleBottomCenterIcon,
  ExclamationTriangleIcon,
  InboxStackIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';
import { ReactNode, useMemo } from 'react';
import { Blinker } from './Blinker';
import { LoadingIndicator } from './LoadingIndicator';

interface CardDataStatsProps {
  title: string;
  number: number;
  widgetType:
  | 'ORDER_DRAFTS'
  | 'UNREAD_CHATS'
  | 'COMPLAINTS'
  | 'ASSISTANCE_REQUESTS';
  blinkerType?: 'STANDARD' | 'READY' | 'WARNING' | 'ALERT';
  className?: string;
  children?: ReactNode;
  isLoading?: boolean;
}

const CardDataStats = ({
  title,
  number,
  widgetType,
  className,
  children,
  blinkerType,
  isLoading,
}: CardDataStatsProps) => {
  const iconComponent = useMemo(() => {
    switch (widgetType) {
      case 'ORDER_DRAFTS':
        return (
          <InboxStackIcon
            height={25}
            width={25}
            style={{ stroke: 'rgb(84, 110, 122)' }}
          />
        );
      case 'UNREAD_CHATS':
        return (
          <ChatBubbleBottomCenterIcon
            height={25}
            width={25}
            style={{ stroke: 'rgb(84, 110, 122)' }}
          />
        );
      case 'ASSISTANCE_REQUESTS':
        return (
          <ChatBubbleBottomCenterIcon
            height={25}
            width={25}
            style={{ stroke: 'rgb(84, 110, 122)' }}
          />
        );
      case 'COMPLAINTS':
        return (
          <ExclamationTriangleIcon
            height={25}
            width={25}
            style={{ stroke: 'rgb(84, 110, 122)' }}
          />
        );
      default:
        return <SparklesIcon height={25} width={25} />;
    }
  }, [widgetType]);

  return (
    <div
      className={`${className} row-span-1 rounded-xl border border-gray-200 bg-white p-3 shadow-sm`}
    >
      <div className="flex space-x-4">
        <div className="relative">
          <div className="rounded-full bg-slate-200 bg-opacity-55 p-lg">
            {iconComponent}
          </div>
          <div className="absolute right-1 top-0">
            <Blinker isVisible={number > 0} type={blinkerType} />
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex items-baseline">
            <div className="relative mr-1">
              {isLoading ? (
                <LoadingIndicator isLoading />
              ) : (
                <div className="flex !font-mono text-xl">{number}</div>
              )}
            </div>
            <span className="text-sm text-gray-500">{title}</span>
          </div>
        </div>
      </div>
      <div className="mt-4 flex items-end gap-smd">{children}</div>
    </div>
  );
};

export { CardDataStats };
