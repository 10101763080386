import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Page } from '../components/layout/Page/Page';
import {
  DetailPanelContentContext,
  DetailPanelContentType,
} from '../contexts/useDetailPanelContentContext';
import {
  DetailPanelWidthContext,
  DetailPanelWidthType,
} from '../contexts/useDetailPanelWidthContext';
import { OrderProvider } from '../contexts/useOrderContext';
import { TeamMembersProvider } from '../contexts/useTeamMembersContext';
import { KeywordContext } from '../contexts/useKeywordContext';
import {
  MessageProviderType,
  MessagesProvider,
} from '../contexts/useMessagesContext';
import { PlayRecordingProvider } from '../contexts/usePlayRecordingIndex';
import { BusinessSettingsProvider } from '../contexts/useBusinessSettingsContext';
import { ProcessNewOrderDraft } from '../components/order/order-draft/ProcessNewOrderDraft';
import { SchemasProvider } from '../contexts/useSchemasContext';

interface Props {
  navigateToHome: () => void;
}

const ProcessNewOrderDraftPage = ({ navigateToHome }: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const messageId = searchParams.get('message_id');

  const [hoverKeyword, setHoverKeyword] = useState<string>('');
  const [scrollKeyword, setScrollKeyword] = useState<string>('');

  const [detailPanelWidth, setDetailPanelWidth] = useState<DetailPanelWidthType>(DetailPanelWidthType.SMALL);
  const [detailPanelContent, setDetailPanelContent] = useState<DetailPanelContentType>(
    DetailPanelContentType.NEW_ORDER_DRAFT_PROCESSING,
  );

  const keywordContextValue = useMemo(
    () => ({
      hoverKeyword,
      setHoverKeyword,
      scrollKeyword,
      setScrollKeyword,
    }),
    [hoverKeyword, scrollKeyword],
  );

  const detailPanelWidthContextValue = useMemo(
    () => ({
      detailPanelWidth,
      setDetailPanelWidth,
    }),
    [detailPanelWidth],
  );

  const detailPanelContentContextValue = useMemo(
    () => ({
      detailPanelContent,
      setDetailPanelContent,
    }),
    [detailPanelContent],
  );

  return (
    <Page isLoading={false} contentWithBorder>
      <KeywordContext.Provider value={keywordContextValue}>
        <MessagesProvider
          providerType={MessageProviderType.BY_MESSAGE_ID}
          messageId={messageId}
        >
          <DetailPanelWidthContext.Provider
            value={detailPanelWidthContextValue}
          >
            <DetailPanelContentContext.Provider
              value={detailPanelContentContextValue}
            >
              <PlayRecordingProvider>
                <BusinessSettingsProvider>
                  <OrderProvider>
                    <SchemasProvider>
                      <TeamMembersProvider>
                        <ProcessNewOrderDraft navigateToHome={navigateToHome} />
                      </TeamMembersProvider>
                    </SchemasProvider>
                  </OrderProvider>
                </BusinessSettingsProvider>
              </PlayRecordingProvider>
            </DetailPanelContentContext.Provider>
          </DetailPanelWidthContext.Provider>
        </MessagesProvider>
      </KeywordContext.Provider>
    </Page>
  );
};

export { ProcessNewOrderDraftPage };
