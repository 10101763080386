import { useMemo } from 'react';
import { Tooltip } from '@mantine/core';
import { ClockIcon } from '@heroicons/react/24/outline';

import Dayjs from 'dayjs';

import { twMerge } from 'tailwind-merge';
import { Message, MessageContextUtils, MessageStatus } from '../../../models/Message';
import { formatMessageSource } from '../../../helpers/enums';
import { MessageSourceIcon } from '../../MessageSourceIcon';
import { MessageDirection } from '../../../types/message';

interface Props {
  children: React.ReactNode;

  message: Message;
  direction: MessageDirection;
}

const MessageDatetimeWrapper = ({ children, message, direction }: Props) => {
  const messageDatetime = useMemo(() => {
    if (message.originalCreatedAt) {
      const dt = Dayjs(message.originalCreatedAt);
      const now = Dayjs();
      if (dt.isSame(now, 'day')) {
        return dt.format('HH:mm A');
      }
      return dt.format('ddd, MMM D, YYYY HH:mm A');
    }
    return null;
  }, [message.originalCreatedAt]);

  const isRecordingAttached = useMemo(
    () => MessageContextUtils.audioAttachments(message.context).length > 0,
    [message],
  );

  const title = useMemo(() => {
    if (isRecordingAttached) return 'Source: Phone';

    return `Source: ${formatMessageSource(message.source)}`;
  }, [isRecordingAttached, message]);

  return (
    <>
      {children}

      <div className={twMerge(
        'flex items-center gap-md pt-sm',
        direction === MessageDirection.Sent && 'justify-end',
      )}
      >
        {
          messageDatetime && (
            <div className="pl-xs text-label-caption text-blue-gray-200">{`${messageDatetime}`}</div>
          )
        }
        <Tooltip
          label={title}
          transitionProps={{ transition: 'pop' }}
          bg="white"
          styles={{
            tooltip: {
              color: 'black',
              border: '1.5px solid #E8E8E8',
              boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
            },
          }}
        >
          <MessageSourceIcon
            source={message.source}
            isRecordingAttached={isRecordingAttached}
          />
        </Tooltip>

        {
          message.messageStatus === MessageStatus.SENDING && (
            <div className="flex items-end h-full">
              <ClockIcon className="w-4 h-4 text-gray-500" />
            </div>
          )
        }
      </div>
    </>
  );
};

export default MessageDatetimeWrapper;
