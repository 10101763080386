import {
  useState, useCallback, useRef, useEffect,
} from 'react';

import { httpGet } from '../../helpers/xhr';
import { Product } from '../../models/Product';

export enum QuerySearchType {
  Standard = 'standard',
  Embedding = 'embedding',
}

type LoadProductsProps = {
  searchQuery?: string;
  supplierId?: string;
  category?: string;
  customerId?: string;
  querySearchType?: QuerySearchType;
  sortById?: boolean;
  filterEnabledProducts?: boolean;

  reset?: boolean;
};

type FetchProductsParams = {
  supplier_id?: string;
  category?: string;
  query?: string;
  include_order_count?: string;
  customer_id?: string;

  query_search_type?: QuerySearchType;
  only_enabled?: boolean;
  num_candidates?: number;

  sorting_columns?: string[];
  cursor?: string | null;
  limit?: number;
};

interface FetchOrderDraftsProps {
  preventInitialFetch?: boolean;
}

const useFetchProducts = ({
  preventInitialFetch = false,
}: FetchOrderDraftsProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(!preventInitialFetch);
  const [products, setProducts] = useState<Product[]>([]);

  const endReachedRef = useRef<boolean>(false);
  const productsPaginationCursor = useRef<string | null>(null);
  const currentAbortController = useRef<AbortController | null>(null);

  const resetParams = () => {
    setProducts([]);
    productsPaginationCursor.current = null;
  };

  const loadProducts = useCallback(
    ({
      searchQuery,
      supplierId,
      category,
      customerId,
      querySearchType,
      sortById,
      filterEnabledProducts,
      reset,
    }: LoadProductsProps) => {
      if (reset) {
        productsPaginationCursor.current = null;
        endReachedRef.current = false;
      } else if (endReachedRef.current) {
        return;
      }

      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }

      const controller = new AbortController();
      currentAbortController.current = controller;

      setIsLoading(true);

      const params: FetchProductsParams = {
        supplier_id: supplierId,
        category,
        query: searchQuery,
        customer_id: customerId,
        query_search_type: querySearchType,
        only_enabled: filterEnabledProducts,
        cursor: productsPaginationCursor.current,
      };

      if (querySearchType === QuerySearchType.Embedding) {
        params.num_candidates = 20;
        params.sorting_columns = ['score'];
      } else {
        params.limit = 20;

        if (sortById) params.sorting_columns = ['id_or_sku'];
      }

      httpGet('/products', { params })
        .then((response) => {
          const fetchedProducts = (response.data.result || []) as Product[];
          if (reset) {
            setProducts(fetchedProducts);
          } else {
            setProducts((ps) => [...ps, ...fetchedProducts]);
          }

          productsPaginationCursor.current = response.data.cursor;

          if (!response.data.cursor || fetchedProducts.length === 0) {
            endReachedRef.current = true;
          }
        })
        .catch((error) => {
          if (error.name !== 'AbortError') {
            console.error('Failed to fetch products:', error);
          }
        })
        .finally(() => {
          if (!controller.signal.aborted) {
            setIsLoading(false);
          }
          currentAbortController.current = null;
        });
    },
    [],
  );

  useEffect(() => {
    if (preventInitialFetch || endReachedRef.current) return () => {};

    resetParams();
    loadProducts({});

    return () => {
      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }
    };
  }, [preventInitialFetch, loadProducts]);

  return {
    products,
    isLoading,
    loadProducts,
    endReachedRef,
  };
};

export { useFetchProducts };
