import { useNavigate } from 'react-router-dom';
import { LoadingOverlay } from '@mantine/core';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Page } from '../components/layout/Page/Page';
import { useFetchPrompts } from '../hooks/fetch/useFetchPrompts';
import { Card } from '../components/common/Card';

import { OverflowEllipsisParagraph } from '../components/ui/OverflowEllipsisParagraph';
import { Button } from '../components/ui/Button';

import cartLight from '../assets/utils/cart-light.svg';
import { ROUTES } from '../config/routes';
import { useDeletePrompt } from '../hooks/useDeletePrompt';

const InstructionsPage = () => {
  const { prompts, setPrompts, isLoading } = useFetchPrompts({});
  const navigate = useNavigate();

  const { deletePrompt } = useDeletePrompt();

  const onCardClick = (promptId: string) => {
    navigate(ROUTES.EDIT_INSTRUCTION(promptId));
  };

  const onDeleteClick = (promptId: string) => {
    deletePrompt(promptId)
      .then(() => {
        setPrompts((_prompts) => _prompts.filter((prompt) => prompt.id !== promptId));
      });
  };

  const onCreateInstructionButtonPress = () => {
    navigate(ROUTES.CREATE_INSTRUCTION);
  };

  return (
    <Page contentWithPadding>
      <div className="space-y-4 p-lg">
        <div className="flex justify-between">
          <div className="flex flex-col justify-center gap-2">
            <h1 className="text-xl font-semibold leading-none text-gray-900">
              Instructions
            </h1>
            <div className="flex items-center gap-2 text-sm font-medium text-gray-600">
              Central Hub for instructions
            </div>
          </div>
          <Button
            title="Create new instruction"
            onClick={onCreateInstructionButtonPress}
          />
        </div>
        <div className="relative min-h-20">
          <LoadingOverlay
            visible={isLoading}
            loaderProps={{ type: 'dots' }}
            overlayProps={{ blur: 2 }}
          />
          <div className="grid grid-cols-5 gap-4 2xl:grid-cols-6">
            {prompts.map((prompt) => (
              <Card
                key={prompt.id}
                className="cursor-pointer transition-all ease-linear duration-200 hover:shadow-md"
                onClick={() => {
                  onCardClick(prompt.id);
                }}
              >
                <Card.Body className="px-4 group">
                  <div className="flex justify-between">
                    <OverflowEllipsisParagraph
                      maxLines={1}
                      isTooltipEnabled
                      className="cursor-pointer text-sm font-semibold"
                    >
                      {prompt.name}
                    </OverflowEllipsisParagraph>
                    <button className="group-hover:visible invisible" type="button" onClick={(e) => { onDeleteClick(prompt.id); e.stopPropagation(); }}>
                      <TrashIcon className="h-4 w-4 text-red-500" />
                    </button>
                  </div>
                  <img
                    src={cartLight}
                    alt="cart"
                    className="aspect-square h-[100px]"
                  />
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default InstructionsPage;
