import { useState, useCallback } from 'react';

import { httpPost } from '../helpers/xhr';
import { genericErrorFeedback } from '../helpers/errors';
import { globalAlertData } from '../state/globalAlertData';
import { Order, ProductWithQuantity } from '../models/Order';
import { useGroupOrderDrafts } from './useGroupOrderDrafts';

type CreateOrderDraftParams = {
  customerId: string;
  requestedDeliveryTime?: string;
  products?: ProductWithQuantity[];
  comment?: string;
  assigneeId?: string;
  promptId?: string;
};

const useCreateOrderDrafts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { groupOrders } = useGroupOrderDrafts();

  const _createOrderDraft = useCallback(
    async (
      orderDraft: Order,
      createAlertData: boolean = true,
      giveErrorFeedback: boolean = true,
    ): Promise<Order> => {
      if (!orderDraft.customer?.id) {
        if (giveErrorFeedback) {
          genericErrorFeedback('Create order failed')(
            'Specify a customer for which to create the order draft',
          );
        }
        return Promise.reject();
      }

      const params: CreateOrderDraftParams = {
        customerId: orderDraft.customer.id,
      };

      if (orderDraft.requestedDeliveryTime) params.requestedDeliveryTime = orderDraft.requestedDeliveryTime;
      if (orderDraft.products?.length > 0) params.products = orderDraft.products;
      if (orderDraft.draft?.comment) params.comment = orderDraft.draft?.comment;
      if (orderDraft.assigneeId) params.assigneeId = orderDraft.assigneeId;
      if (orderDraft.promptId) params.promptId = orderDraft.promptId;

      return httpPost('/orders/drafts', params)
        .then((response) => {
          if (createAlertData) {
            globalAlertData.create('Order created successfully');
          }
          return response.data;
        })
        .catch((error) => {
          genericErrorFeedback('Create order failed')(error);
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  const createAndGroupOrderDrafts = useCallback(
    async (
      orderDrafts: Order[],
      _ids: string[] = [],
      createAlertData: boolean = true,
      giveErrorFeedback: boolean = true,
    ): Promise<Order[]> => {
      if (isLoading) {
        return Promise.reject(
          new Error('Orders creation is already in progress'),
        );
      }

      setIsLoading(true);

      let responseBaseOrderDraft: Order;
      try {
        // create base product
        responseBaseOrderDraft = await _createOrderDraft(
          orderDrafts[0],
          createAlertData,
          giveErrorFeedback,
        );
      } catch (error) {
        setIsLoading(false);
        return Promise.reject(error);
      }

      // if there was only one drafts then return the base draft
      if (orderDrafts.length === 1 && !_ids.length) {
        setIsLoading(false);
        return Promise.resolve([responseBaseOrderDraft]);
      }

      if (!responseBaseOrderDraft) {
        setIsLoading(false);
        return Promise.reject(new Error('Failed to create base order draft'));
      }

      const ids = [..._ids, responseBaseOrderDraft.id];
      const orders = [responseBaseOrderDraft];

      const promises = await Promise.all(
        orderDrafts.slice(1).map(async (orderDraft): Promise<any> => {
          try {
            const responseOrderDraft = await _createOrderDraft(
              orderDraft,
              false,
              false,
            );
            if (responseOrderDraft) {
              ids.push(responseOrderDraft.id);
              orders.push(responseOrderDraft);
              return responseOrderDraft;
            }
          } catch (error) {
            return Promise.reject(error);
          }
          return null;
        }),
      );

      if (promises.some((promise) => promise instanceof Error)) {
        if (giveErrorFeedback) {
          genericErrorFeedback('Create order failed')(
            'Failed to create order drafts',
          );
        }
        return Promise.reject(new Error('Failed to create order drafts'));
      }

      // group orders
      return groupOrders({ orderIds: ids, giveErrorFeedback: false })
        .then((groupId) => {
          setIsLoading(false);
          if (createAlertData) {
            globalAlertData.create('Order created successfully');
          }
          return orders.map((_order) => ({ ..._order, groupId }));
        })
        .catch(() => {
          setIsLoading(false);
          if (giveErrorFeedback) {
            genericErrorFeedback('Create order failed')(
              'Failed to group order drafts',
            );
          }
          return Promise.reject(new Error('Failed to group order drafts'));
        });
    },
    [_createOrderDraft, groupOrders, isLoading],
  );

  return { isLoading, createAndGroupOrderDrafts };
};

export { useCreateOrderDrafts };
