import { ProtectedScreen } from '../../components/ui/ProtectedScreen';
import { MockedComplaintsPage } from '../../pages/MockedComplaintsPage';

const MockedComplaintsScreen = () => (
  <ProtectedScreen>
    <MockedComplaintsPage />
  </ProtectedScreen>
);

export { MockedComplaintsScreen };
