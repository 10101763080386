import { DocumentArrowUpIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useCallback, useState, useRef } from 'react';
import { ImportPreviewTable } from '../ImportPreviewTable';
import { readCsvFirstTenLines } from '../../helpers/files';
import { genericErrorFeedback } from '../../helpers/errors';
import { Wrapper } from './Wrapper';

interface Props {
  file: File;
  setFile: React.Dispatch<React.SetStateAction<File>>;
  previewHeaders: string[];
  setPreviewHeaders: React.Dispatch<React.SetStateAction<string[]>>;
  previewItems: string[][];
  setPreviewItems: React.Dispatch<React.SetStateAction<string[][]>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onStepClicked: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    name: string,
  ) => Promise<void>;
  resetSteps: () => void;
}

const UploadMatcherFile = ({
  file,
  setFile,
  previewHeaders,
  previewItems,
  setPreviewItems,
  setPreviewHeaders,
  setIsLoading,
  onStepClicked,
  resetSteps,
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [dragging, setDragging] = useState(false);

  const processFile = (tempFile: File) => {
    // set max width
    const tableParent = document.querySelector('#table_parent') as HTMLElement;
    if (tableParent) {
      const currentWidth = window.getComputedStyle(tableParent).width;
      tableParent.style.maxWidth = currentWidth;
    }

    setIsLoading(true);
    readCsvFirstTenLines(tempFile)
      .then((response) => {
        if (response && response.length > 0) {
          setPreviewHeaders(response[0]);
          // Give unique ids to each row
          const rows: string[][] = response.slice(1);
          for (let i = 0; i < rows.length; i += 1) {
            rows[i].unshift(i.toString());
          }
          setPreviewItems(rows);
          console.group(rows);
        }
      })
      .catch((err) => {
        genericErrorFeedback(`Error loading file: ${err}`);
      })
      .finally(() => {
        setFile(tempFile);
        setIsLoading(false);
      });
  };

  const handleDragOver = useCallback((event: React.DragEvent<HTMLElement>) => {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();
    setDragging(true);
  }, []);

  const handleDragLeave = useCallback((event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    setDragging(false);
  }, []);

  const handleDrop = async (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    setDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      if (fileInputRef.current) {
        fileInputRef.current.files = event.dataTransfer.files;
        processFile(event.dataTransfer.files[0]);
      }
    }
  };

  const handleDivClick = useCallback(() => {
    fileInputRef.current.click();
  }, []);

  const handleDeleteFileClick = useCallback(() => {
    resetSteps();
  }, [resetSteps]);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.preventDefault();

    const tempFile = event.target.files?.[0];

    if (tempFile) {
      processFile(tempFile);
    }
  };
  return (
    <Wrapper
      stepCriteria={previewItems.length > 0}
      onStepClicked={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (previewItems.length > 0) onStepClicked(e, 'Fields Matching');
      }}
    >
      <div className="flex h-full flex-col">
        <div className="flex w-full justify-between pt-2">
          {!file ? (
            <div className="w-full">
              <div className="flex items-center">
                <p className="text-label-caption-md text-description">
                  Click or drag and drop to upload your file.
                </p>
              </div>
              <div
                className={`mt-2 flex justify-center rounded-lg border ${dragging ? 'border-solid bg-gray-50' : 'border-dashed'} border-gray-900/25 px-xl py-10`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={handleDivClick}
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
              >
                <div className="text-center">
                  <DocumentArrowUpIcon
                    className="mx-auto h-12 w-12 text-gray-300"
                    aria-hidden="true"
                  />
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <p className="pl-1">Upload a file or drag and drop</p>
                    <input
                      type="file"
                      className="sr-only"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                  </div>
                  <p className="text-xs leading-5 text-gray-600">
                    CSV or EXCEL
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex w-full justify-between rounded-md border border-gray-300 px-smd py-sm">
              <div className="flex space-x-2">
                <DocumentArrowUpIcon className="aspect-square w-8" />
                <div className="text-label-caption-md text-gray-600">
                  <div>
                    {file.name}
                    <p className="text-label-caption text-gray-400">
                      {(file.size / 1048576).toFixed(3)}
                      {' '}
                      MB
                    </p>
                    {/* <p className="text-gray-400">{file.size} MB</p> */}
                  </div>
                </div>
              </div>
              <button type="button" onClick={handleDeleteFileClick}>
                <XMarkIcon className="aspect-square w-8" />
              </button>
            </div>
          )}
        </div>

        <div className="flex-grow py-smd">
          {previewItems.length > 0 && (
            <ImportPreviewTable
              headers={previewHeaders}
              contents={previewItems}
            />
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export { UploadMatcherFile };
