import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { rem, TextInput } from '@mantine/core';
import { useCallback, useMemo } from 'react';
import { OverflowEllipsisParagraph } from '../../../../../../../ui/OverflowEllipsisParagraph';
import { ProductWithQuantity } from '../../../../../../../../models/Order';

interface Props {
  label: string;
  productWithQuantity: ProductWithQuantity;
  onValueChange?: (value: string) => void;
}

const IdOrSkuInput = ({
  label, productWithQuantity, onValueChange,
}: Props) => {
  const showErrorIcon = useMemo(() => (
    productWithQuantity?.autoMatchedIdOrSku
    && (productWithQuantity?.autoMatchedIdOrSku !== productWithQuantity?.product?.idOrSku)
  ), [productWithQuantity?.autoMatchedIdOrSku, productWithQuantity?.product?.idOrSku]);

  const onTextInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange?.(e.target.value);
  }, [onValueChange]);
  return (
    <TextInput
      label={label}
      value={productWithQuantity?.autoMatchedIdOrSku}
      size="sm"
      onChange={onTextInputChange}
      rightSection={showErrorIcon && (
        <OverflowEllipsisParagraph
          isTooltipEnabled
          customTooltipContent={(
            <div>
              Adam has extracted this
              {' '}
              {label}
              , which is different from the one of the matched product.
              <br />
              Please check the product and update the value if needed.
            </div>
)}
          theme="warning"
        >
          <ExclamationCircleIcon
            style={{ width: rem(20), height: rem(20) }}
            color="var(--mantine-color-error)"
          />
        </OverflowEllipsisParagraph>
      )}
    />
  );
};

export default IdOrSkuInput;
