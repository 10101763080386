import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ChatListPage } from '../../pages/ChatListPage';
import { ProtectedScreen } from '../../components/ui/ProtectedScreen';
import { ChatsProvider } from '../../contexts/useChatsContext';
import { ROUTES } from '../../config/routes';

const ChatsListScreen = () => {
  const navigate = useNavigate();

  const navigateToChat = useCallback(
    (chatId: string) => {
      navigate(ROUTES.CHAT_BY_ID(chatId));
    },
    [navigate],
  );

  return (
    <ProtectedScreen>
      <ChatsProvider>
        <ChatListPage navigateToChat={navigateToChat} />
      </ChatsProvider>
    </ProtectedScreen>
  );
};

export { ChatsListScreen };
