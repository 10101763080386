import { ChangeEvent } from 'react';

import { Button } from './ui/Button';

type Props = {
  disabled?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  value?: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onSendButtonClick: () => void;
  rows?: number;
  bottom?: number;
  rounded?: boolean;
};

const ChatActions = ({
  disabled = false,
  onChange,
  onSendButtonClick,
  placeholder = '',
  readOnly = false,
  value = undefined,
  rows = 1,
  bottom = 13,
  rounded = true,
}: Props) => (
  <div
    style={{ bottom }}
    className={`relative z-10 h-full border border-solid p-3 ${rounded && 'rounded-lg'} flex flex-col bg-white`}
  >
    <div className="flex items-center gap-smd">
      <textarea
        // Disable autocomplete in most browsers
        autoComplete="new-password"
        className="h-[95%] w-full flex-1 resize-none rounded-lg border-0 pb-0 pl-1 pr-1 pt-0 outline-none disabled:bg-slate-50"
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
        value={value}
        rows={rows}
      />
      <div className="flex items-center justify-end gap-sm">
        <Button
          onClick={onSendButtonClick}
          title="Send"
          disabled={!value.length}
        />
      </div>
    </div>
  </div>
);

export { ChatActions };
