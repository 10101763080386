import { Business } from './Business';
import { Workflow, OrderParserMessageContext as OrderWorkflowMessageContext } from './Workflow';

class BaseMessage {
  message: string;
}

class BaseFirstMessage {
  message: string;

  businessSentTo: string;
}

class Attachment {
  type: AttachmentType;

  name: string;

  url: string;

  size: number;

  contentType?: string;

  transcription?: string;

  isInline: boolean;

  intents: Record<string, boolean>;

  imagifiedPages?: Attachment[]; // For documents, these are the imagified pages
}

enum AttachmentType {
  AUDIO = 0,
  IMAGE = 1,
  PDF = 2,
  EXCEL = 3,
  HTML = 4,
  OTHER = 5,
  // Needed for messages migrated from v0 to v1 since we did not know the original attachment type
  DOCUMENT_UNKNOWN = 6,
}

namespace AttachmentType {
  export function isAudio(attachmentType: AttachmentType): boolean {
    return (
      attachmentType === AttachmentType.AUDIO
    );
  }

  export function isDocument(attachmentType: AttachmentType): boolean {
    return (
      attachmentType === AttachmentType.IMAGE
      || attachmentType === AttachmentType.PDF
      || attachmentType === AttachmentType.EXCEL
      || attachmentType === AttachmentType.HTML
      || attachmentType === AttachmentType.DOCUMENT_UNKNOWN
    );
  }
}

enum MessageType {
  TEXT = 'text',
  EMAIL = 'email',
  VOICE = 'voice',
}

enum MessageSource {
  INTERNAL = 'internal',
  MAIL_BRIDGE = 'mail_bridge',
  VOICE_BRIDGE = 'voice_bridge',
}

enum MessageRefType {
  ORDER = 'ORDER',
}

enum MessageIntent {
  ORDER = 'order',
  OTHER = 'other',
}

enum MessageStatus {
  SENDING = 'sending',
  SENT = 'sent',
  FAILED = 'failed',
}

class Message {
  businessSentBy: string;

  businessSentByInfo?: Business;

  businessSentTo: string;

  chatId: string;

  chatGroupId: string;

  createdAt: string;

  originalCreatedAt: string;

  id: string;

  message: string;

  messageType: MessageType;

  source: MessageSource;

  ref: string;

  refType: MessageRefType;

  readAt: string;

  userSentBy: string;

  userSentTo: string;

  context: MessageContext;

  workflowRuns?: Record<Workflow, string>;

  intents: MessageIntent[];

  __version?: string;

  messageStatus?: MessageStatus;

  isAdded?: boolean;

  // temporary field for a simple thinking message
  isAdamThinking?: boolean;

  constructor(
    id: string,
    chatId: string,
    chatGroupId: string,

    businessSentBy: string,
    businessSentTo: string,
    userSentBy: string,
    userSentTo: string,

    message: string,
    messageType: MessageType,
    source: MessageSource,

    ref: string,
    refType: MessageRefType,
    context: MessageContext,

    readAt: string,
    createdAt: string,
    originalCreatedAt: string,
    workflowRuns?: Record<Workflow, string>,
    __version?: string,
    messageStatus?: MessageStatus,
    isAdamThinking?: boolean,
    isAdded?: boolean,
  ) {
    this.businessSentBy = businessSentBy;
    this.businessSentTo = businessSentTo;
    this.chatId = chatId;
    this.chatGroupId = chatGroupId;
    this.createdAt = createdAt;
    this.originalCreatedAt = originalCreatedAt;
    this.id = id;
    this.message = message;
    this.messageType = messageType;
    this.source = source;
    this.ref = ref;
    this.refType = refType;
    this.readAt = readAt;
    this.userSentBy = userSentBy;
    this.userSentTo = userSentTo;
    this.context = context;
    this.workflowRuns = workflowRuns;
    this.__version = __version;
    this.messageStatus = messageStatus || MessageStatus.SENT;
    this.isAdamThinking = isAdamThinking;
    this.isAdded = isAdded;
  }
}

class MessageContext {
  html?: string;

  subject?: string;

  attachments?: Attachment[];

  workflowOrder?: OrderWorkflowMessageContext;
}

// TODO(ntauth): Need to do this instead of simple getters because we are not instantiating MessageContext pdddd.
export const MessageContextUtils = {
  audioAttachments(messageContext?: MessageContext): Attachment[] {
    return messageContext?.attachments?.filter((attachment) => attachment.type === AttachmentType.AUDIO) || [];
  },

  documentAttachments(messageContext?: MessageContext): Attachment[] {
    return messageContext?.attachments?.filter((attachment) => AttachmentType.isDocument(attachment.type)) || [];
  },
};

export {
  BaseMessage,
  BaseFirstMessage,
  Message,
  MessageType,
  MessageRefType,
  MessageSource,
  MessageIntent,
  MessageStatus,
  Attachment,
  AttachmentType,
};
