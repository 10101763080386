import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { CustomersImportPage } from '../../pages/CustomersImportPage';
import { ProtectedScreen } from '../../components/ui/ProtectedScreen';
import { ROUTES } from '../../config/routes';

const CustomersImportScreen = () => {
  const navigate = useNavigate();

  const navigateToCustomersList = useCallback(() => {
    navigate(ROUTES.CUSTOMERS);
  }, [navigate]);

  return (
    <ProtectedScreen>
      <CustomersImportPage navigateToCustomersList={navigateToCustomersList} />
    </ProtectedScreen>
  );
};

export { CustomersImportScreen };
