import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { LoadingOverlay } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { Page } from '../components/layout/Page/Page';
import { useMessagesContext } from '../contexts/useMessagesContext';
import { Messages } from '../components/order/order-draft/Messages';
import { useFetchCustomerById } from '../hooks/fetch/useFetchCustomerById';
import { isZeroTime } from '../helpers/dateTime';
import { useMarkMessageAsRead } from '../hooks/useMarkMessageAsRead';
import { Message } from '../models/Message';
import { Business } from '../models/Business';
import { WorkflowRetrySidebarWrapper } from '../components/wrapper/WorkflowRetrySidebarWrapper';
import { Workflow, WorkflowRun } from '../models/Workflow';
import { useRetryWorkflow } from '../hooks/useRetryWorkflow';
import { ROUTES } from '../config/routes';

const Content = ({
  messages,
  customer,
  isMessagesLoading,
  isCustomerLoading,
  isMessageRetrying,
  onOpenRetryWorkflowSidebarButtonClick,
}: {
  messages: Message[];
  customer: Business | null;
  isMessagesLoading: boolean;
  isCustomerLoading: boolean;
  isMessageRetrying: boolean;
  onOpenRetryWorkflowSidebarButtonClick?: () => void;
}) => (
  <div className="relative flex h-full flex-1 overflow-hidden">
    <LoadingOverlay
      visible={isMessagesLoading || isCustomerLoading || isMessageRetrying}
      loaderProps={{ type: 'dots' }}
      overlayProps={{ blur: 2 }}
    />
    <div className="max-[50%] flex h-full min-w-[50%] flex-col overflow-hidden border-r border-blue-gray-50">
      {onOpenRetryWorkflowSidebarButtonClick && (
      <div className="relative flex w-full border-b bg-white px-lg py-1 text-sm">
        <p className="px-lg text-blue-gray-200">
          Is this message not processed properly?
          <button
            type="button"
            className="pl-sm text-primary-500"
            onClick={onOpenRetryWorkflowSidebarButtonClick}
          >
            Retry
          </button>
        </p>
      </div>
      )}
      <Messages messages={messages} customer={customer} />
    </div>
    <div className="max-[50%] relative flex h-full min-w-[50%] items-center justify-center text-center">
      <div className="text-lg text-gray-500">
        Adam will soon assist you in processing the message.
        <br />
        Stay tuned for updates!
      </div>
    </div>
  </div>
);

const MemoizedMessageByIdPage = observer(({
  messages,
  customer,
  isMessagesLoading,
  isCustomerLoading,
}: {
  messages: Message[];
  customer: Business | null;
  isMessagesLoading: boolean;
  isCustomerLoading: boolean;
}) => {
  const navigate = useNavigate();

  // Assuming its an order
  const onOrderWorkflowRunRetried = useCallback((workflowRunId: string) => {
    navigate(ROUTES.PROCESS_ORDER_DRAFT_BY_ID(workflowRunId));
  }, [navigate]);

  const { retryWorkflow, pendingLROs } = useRetryWorkflow({ onOrderWorkflowRunRetried });
  const workflowRetrySidebarWrapperRef = useRef<any>(null);

  const pendingId = messages[0]?.id;

  const pendingMessageRetries = useMemo(() => new Set(
    Array.from(pendingLROs.values()).map((lro) => lro.data as string),
  ), [pendingLROs]);
  const isMessageRetrying = useMemo(() => (
    pendingMessageRetries.has(pendingId as string)
  ), [pendingMessageRetries, pendingId]);

  const onOpenRetryWorkflowSidebarButtonClick = useCallback(() => {
    if (workflowRetrySidebarWrapperRef.current) {
      workflowRetrySidebarWrapperRef.current.onRetryWorkflowButtonClick();
    }
  }, []);

  const onRetryButtonClick = (selectedWorkflow: string, workflowRun: WorkflowRun) => {
    retryWorkflow(selectedWorkflow, workflowRun, pendingId);
  };

  const workflowRunId = useMemo(() => {
    if (!messages[0] || !messages[0].workflowRuns) return null;

    return messages[0].workflowRuns[Workflow.Other];
  }, [messages]);

  if (!workflowRunId) {
    return (
      <Content
        messages={messages}
        customer={customer}
        isMessagesLoading={isMessagesLoading}
        isCustomerLoading={isCustomerLoading}
        isMessageRetrying={isMessageRetrying}
      />
    );
  }

  return (
    <WorkflowRetrySidebarWrapper
      ref={workflowRetrySidebarWrapperRef}
      workflowRunId={workflowRunId}
      onRetryWorkflow={onRetryButtonClick}
    >
      <Content
        messages={messages}
        customer={customer}
        isMessagesLoading={isMessagesLoading}
        isCustomerLoading={isCustomerLoading}
        isMessageRetrying={isMessageRetrying}
        onOpenRetryWorkflowSidebarButtonClick={
          onOpenRetryWorkflowSidebarButtonClick
        }
      />
    </WorkflowRetrySidebarWrapper>
  );
});

const MessageByIdPage = () => {
  const { messages, isLoading: isMessagesLoading } = useMessagesContext();
  const {
    customer,
    loadCustomer,
    isLoading: isCustomerLoading,
  } = useFetchCustomerById();
  const { markMessagesAsRead } = useMarkMessageAsRead();

  useEffect(() => {
    if (messages.length > 0) {
      loadCustomer(messages[0].businessSentBy);

      if (isZeroTime(messages[0].readAt)) {
        markMessagesAsRead([messages[0].id]);
      }
    }
  }, [messages, loadCustomer, markMessagesAsRead]);

  return (
    <Page contentWithBorder>
      <MemoizedMessageByIdPage
        messages={messages}
        customer={customer}
        isMessagesLoading={isMessagesLoading}
        isCustomerLoading={isCustomerLoading}
      />
    </Page>
  );
};

export default MessageByIdPage;
