import {
  useCallback, useEffect, useState,
} from 'react';

import { User } from '../models/User';
import { formatUserRole, userRoleToTagVariant } from '../helpers/enums';
import { genericErrorFeedback } from '../helpers/errors';
import { httpGet } from '../helpers/xhr';
import { Page } from '../components/layout/Page/Page';
import { Card } from '../components/common/Card';
import { TeamMemberIcon } from '../components/team-member';
import { isUserActivated } from '../helpers/user';
import { Tag } from '../components/common/Tag';
import { TextWithLabel } from '../components/ui/TextWithLabel';
import { TagVariant } from '../types/tag';

interface Props {
  userId: string;
}

const TeamMemberPage = ({ userId }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<User | null>();

  const loadUser = useCallback(() => {
    setIsLoading(true);
    httpGet(`/businesses/me/users/${userId}`)
      .then((response) => {
        setUser(response.data);
      })
      .catch(genericErrorFeedback('Error loading team member'))
      .finally(() => {
        setIsLoading(false);
      });
  }, [userId]);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  return (
    <Page isLoading={isLoading} contentWithPadding>
      <div className="flex flex-1 flex-col px-lg py-lg items-center">
        <div className="flex flex-1 w-[70%] flex-col space-y-4">
          <div className="flex flex-col justify-center gap-2">
            <h1 className="text-xl font-semibold leading-none text-gray-900">
              Team member details
            </h1>
            <div className="flex items-center gap-2 text-sm font-medium text-gray-600">
              View team member details
            </div>
          </div>
          <div className="w-full flex-1 flex space-x-6">
            <div className="w-[30%]">
              <Card>
                <Card.Body>
                  <div className="flex flex-col items-center">
                    <div className="mb-3.5">
                      <TeamMemberIcon user={user} />
                    </div>
                    <div className="mb-2 flex items-center justify-center gap-1.5">
                      <div className="hover:text-primary-active text-base font-semibold leading-5 text-gray-900">
                        {!isUserActivated(user)
                          ? 'Not activated'
                          : `${user?.firstName} ${user?.lastName}`}
                      </div>
                    </div>
                    <div className="flex gap-2">
                      {user?.roles?.map((role) => (
                        <Tag
                          key={role}
                          className="static"
                          isTagLoading={!user}
                          tagTitle={formatUserRole(role)}
                          tagVariant={userRoleToTagVariant(role)}
                          hideCircle
                        />
                      ))}
                    </div>
                  </div>
                  <div className="space-y-1">
                    {
                    user?.email && (
                      <TextWithLabel
                        label="Email"
                        value={user?.email}
                      />
                    )
                  }
                    {
                    user?.phone && (
                      <TextWithLabel
                        label="Phone"
                        value={user?.phone}
                      />
                    )
                  }
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="flex-1 space-y-4">
              <Card>
                <Card.Header>
                  <div className="flex gap-2">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      User&apos;s Schedule
                    </h3>
                    <Tag
                      tagTitle="Soon"
                      tagVariant={TagVariant.BLUE}
                      isTagLoading={false}
                      hideCircle
                      className="static"
                    />
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="flex justify-center items-center h-[20vh]">
                    Here you will see the schedule of the user
                  </div>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header>
                  <div className="flex gap-2">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      User&apos;s Tasks
                    </h3>
                    <Tag
                      tagTitle="Soon"
                      tagVariant={TagVariant.BLUE}
                      isTagLoading={false}
                      hideCircle
                      className="static"
                    />
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="flex justify-center items-center h-[50vh]">
                    Here you will see the tasks assigned to the user
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export { TeamMemberPage };
