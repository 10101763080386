import {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import { twMerge } from 'tailwind-merge';

import { useNavigate } from 'react-router-dom';
import { Page } from '../components/layout/Page/Page';
import { ChatList } from '../components/chat/chat-list';
import { Chat as ChatComponent } from '../components/chat/Chat/Chat';
import { KeywordContext } from '../contexts/useKeywordContext';
import {
  DetailPanelWidthContext,
  DetailPanelWidthType,
} from '../contexts/useDetailPanelWidthContext';
import { ChatsProvider, useChatsContext } from '../contexts/useChatsContext';
import {
  DetailPanelContentContext,
  DetailPanelContentType,
} from '../contexts/useDetailPanelContentContext';
import { OrderProvider } from '../contexts/useOrderContext';
import { PlayRecordingProvider } from '../contexts/usePlayRecordingIndex';
import { TeamMembersProvider } from '../contexts/useTeamMembersContext';
import {
  MessageProviderType,
  MessagesProvider,
  useMessagesContext,
} from '../contexts/useMessagesContext';
import { BusinessSettingsProvider } from '../contexts/useBusinessSettingsContext';
import { ChatMessagesProvider } from '../contexts/useChatMessagesContext';
import { Chat } from '../models/Chat';
import { ROUTES } from '../config/routes';
import { Business } from '../models/Business';
import { globalChats } from '../state/globalChats';
import { User, UserRole } from '../models/User';

const NewAdamChatPageContent = memo(({
  chatId,
  navigateToChat,
}: {
  chatId: string;
  navigateToChat: (chatIdParam: string) => void;
}) => {
  const { chats } = useChatsContext();
  const { setMessages, messages } = useMessagesContext();

  const chat: Chat | undefined = useMemo(
    () => chats.find((c) => c.id === chatId),
    [chats, chatId],
  );

  useEffect(() => {
    const newChatId = messages?.[0]?.chatId;
    if (newChatId && newChatId !== 'new-adam-chat') {
      navigateToChat(newChatId);
    }
  }, [messages, navigateToChat, chatId]);

  useEffect(() => {
    console.log(messages.map((m) => m.message));
  }, [messages]);

  return (
    <ChatMessagesProvider setMessages={setMessages} chat={chat} isNewAdamChat>
      <div
        className={twMerge(
          'w-full',
          'border-r border-blue-gray-50',
        )}
      >
        <ChatComponent chatId={chatId} />
      </div>
    </ChatMessagesProvider>
  );
});

function NewAdamChatPage() {
  const navigate = useNavigate();

  const chatId = 'new-adam-chat';

  const [hoverKeyword, setHoverKeyword] = useState<string>('');
  const [scrollKeyword, setScrollKeyword] = useState<string>('');

  const [detailPanelWidth, setDetailPanelWidth] = useState<DetailPanelWidthType>(DetailPanelWidthType.SMALL);
  const [detailPanelContent, setDetailPanelContent] = useState<DetailPanelContentType>(DetailPanelContentType.USER_INFO);

  const keywordContextValue = useMemo(
    () => ({
      hoverKeyword,
      setHoverKeyword,
      scrollKeyword,
      setScrollKeyword,
    }),
    [hoverKeyword, scrollKeyword],
  );

  const detailPanelWidthContextValue = useMemo(
    () => ({
      detailPanelWidth,
      setDetailPanelWidth,
    }),
    [detailPanelWidth],
  );

  const detailPanelContentContextValue = useMemo(
    () => ({
      detailPanelContent,
      setDetailPanelContent,
    }),
    [detailPanelContent],
  );

  const navigateToChat = useCallback(
    (chatIdParam: string) => {
      navigate(ROUTES.CHAT_BY_ID(chatIdParam));
    },
    [navigate],
  );

  useEffect(() => {
    globalChats.chats.push(new Chat({
      id: 'new-adam-chat',
      business: new Business({ name: 'Adam' }),
      user: new User({ roles: [UserRole.AiAssistant] }),
    }));
  }, []);

  return (
    <Page isLoading={false} contentWithBorder>
      <ChatsProvider>
        <div className="flex h-full w-full">
          <div className="h-full min-w-[30%] max-w-[30%] md:min-w-[25%] md:max-w-[25%] xl:min-w-[20%] xl:max-w-[20%] 2xl:min-w-[15%] 2xl:max-w-[15%]">
            <ChatList selectedChatId={null} navigateToChat={navigateToChat} />
          </div>

          <div className="flex h-full flex-1">
            <KeywordContext.Provider value={keywordContextValue}>
              <PlayRecordingProvider>
                <DetailPanelWidthContext.Provider
                  value={detailPanelWidthContextValue}
                >
                  <DetailPanelContentContext.Provider
                    value={detailPanelContentContextValue}
                  >
                    <MessagesProvider
                      providerType={MessageProviderType.BY_CHAT_ID}
                      chatId={chatId}
                    >
                      <OrderProvider>
                        <BusinessSettingsProvider>
                          <TeamMembersProvider>
                            <NewAdamChatPageContent
                              chatId={chatId}
                              navigateToChat={navigateToChat}
                            />
                          </TeamMembersProvider>
                        </BusinessSettingsProvider>
                      </OrderProvider>
                    </MessagesProvider>
                  </DetailPanelContentContext.Provider>
                </DetailPanelWidthContext.Provider>
              </PlayRecordingProvider>
            </KeywordContext.Provider>
          </div>
        </div>
      </ChatsProvider>
    </Page>
  );
}

export { NewAdamChatPage };
