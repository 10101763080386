import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { globalOrderDrafts } from '../state/globalOrderDrafts';
import { Order } from '../models/Order';
import { ROUTES } from '../config/routes';

interface OrderDraftNavigationProps {
  orderDrafts: Order[];
  setOrderDrafts: React.Dispatch<React.SetStateAction<Order[]>>;
  loadOrderDrafts: () => Promise<Order[]>;
}

const useOrderDraftNavigation = ({
  orderDrafts,
  setOrderDrafts,
  loadOrderDrafts,
}: OrderDraftNavigationProps) => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(-1);

  const selectNextOrderDraft = useCallback(
    (increment: boolean = true) => {
      if (orderDrafts.length > 0) {
        if (currentIndex !== null) {
          let nextIndex = (currentIndex || 0) + 1;
          if (!increment) nextIndex -= 1; // in case the order was confirmed

          if (globalOrderDrafts.count === 0) {
            navigate(ROUTES.HOME);
          } else if (nextIndex < orderDrafts.length) {
            setCurrentIndex(nextIndex);
          } else if (nextIndex < globalOrderDrafts.count) {
            loadOrderDrafts()
              .then((results) => {
                if (results.length === 0) {
                  setCurrentIndex(-1);
                  navigate(ROUTES.HOME);
                } else {
                  setCurrentIndex(nextIndex);
                }
              })
              .catch(() => {
                setCurrentIndex(-1);
              });
          }
        } else {
          setCurrentIndex(0);
        }
      } else {
        setCurrentIndex(-1);
      }
    },
    [orderDrafts.length, currentIndex, loadOrderDrafts, navigate],
  );

  const selectPrevOrderDraft = useCallback(() => {
    if (orderDrafts.length > 0) {
      if (currentIndex !== null) {
        const nextIndex = currentIndex - 1;
        if (nextIndex >= 0) {
          setCurrentIndex(nextIndex);
        }
      } else {
        setCurrentIndex(0);
      }
    } else {
      setCurrentIndex(-1);
    }
  }, [orderDrafts.length, currentIndex]);

  const onOrderDraftProcessed = useCallback(
    (orderId: string) => {
      setOrderDrafts((_orderDrafts) => _orderDrafts.filter((_orderDraft) => _orderDraft.id !== orderId),
      );
      globalOrderDrafts.processOrder();
      selectNextOrderDraft(false);
    },
    [selectNextOrderDraft, setOrderDrafts],
  );

  useEffect(() => {
    if (orderDrafts.length > 0 && currentIndex === -1) {
      setCurrentIndex(0);
    }
  }, [orderDrafts, currentIndex]);

  return {
    currentIndex,
    setCurrentIndex,
    selectNextOrderDraft,
    selectPrevOrderDraft,
    onOrderDraftProcessed,
  };
};

export { useOrderDraftNavigation };
