import { useCallback, useState } from 'react';

import {
  CustomerConfig,
  CustomerHeaderMatcher,
} from '../models/matcher/CustomerHeaderMatcher';
import { MatchStatus } from '../models/matcher/BaseHeaderMatcher';
import { ImportMain } from '../components/matcher/ImportMain';

interface Props {
  navigateToCustomersList: () => void;
}

const CustomersImportPage = ({ navigateToCustomersList }: Props) => {
  const [confirmedHeaders, setConfirmHeaders] = useState<string[]>([]);
  const [confirmedCustomers, setConfirmCustomers] = useState<string[][]>([]);

  const [matchers, setMatchers] = useState<CustomerHeaderMatcher[]>([]);
  const [selectedMatcher, setSelectedMatcher] = useState<CustomerHeaderMatcher | null>(null);

  const getMatchingStates = useCallback(
    (matcher: CustomerHeaderMatcher, previewHeaders: string[]) => {
      const temp: MatchStatus[] = [];

      temp.unshift({
        title: 'Name',
        key: matcher.name,
        matched: matcher.name === '' || previewHeaders.includes(matcher.name),
      });

      temp.unshift({
        title: 'Emails',
        key: !matcher.emails ? '' : matcher.emails.join(','),
        matched:
          !matcher.emails
          || matcher.emails.length === 0
          || previewHeaders.some((val) => matcher.emails.includes(val)),
      });

      temp.unshift({
        title: 'Phones',
        key: !matcher.phones ? '' : matcher.phones.join(','),
        matched:
          !matcher.phones
          || matcher.phones.length === 0
          || previewHeaders.some((val) => matcher.phones.includes(val)),
      });

      temp.unshift({
        title: 'Addresses',
        key: !matcher.addressComponents
          ? ''
          : matcher.addressComponents.join(','),
        matched:
          !matcher.addressComponents
          || matcher.addressComponents.length === 0
          || previewHeaders.some((val) => matcher.addressComponents.includes(val)),
      });

      temp.unshift({
        title: 'External ID',
        key: matcher.externalId,
        matched:
          matcher.externalId === ''
          || previewHeaders.includes(matcher.externalId),
      });

      return temp;
    },
    [],
  );

  const setConfirmContent = useCallback(
    (previewHeaders: string[], previewCustomers: string[][]) => {
      const customers: string[][] = [];

      const externalIDIndex = previewHeaders.indexOf(
        selectedMatcher.externalId,
      );
      const nameIndex = previewHeaders.indexOf(selectedMatcher.name);
      const phonesIndices = selectedMatcher.phones.map((p) => previewHeaders.indexOf(p),
      );
      const emailsIndices = selectedMatcher.emails.map((e) => previewHeaders.indexOf(e),
      );
      const addressesIndices = selectedMatcher.addressComponents.map((a) => previewHeaders.indexOf(a),
      );

      for (let i = 0; i < previewCustomers.length; i += 1) {
        const temp: string[] = [];
        temp.push(i.toString());

        temp.push(
          externalIDIndex !== -1
            ? previewCustomers[i][externalIDIndex + 1].toString()
            : '',
        );
        temp.push(
          nameIndex !== -1 ? previewCustomers[i][nameIndex + 1].toString() : '',
        );

        const tempPhones = phonesIndices
          .filter((uI) => uI !== -1)
          .map((uI) => previewCustomers[i][uI + 1])
          .join(',');

        temp.push(tempPhones);

        const tempEmails = emailsIndices
          .filter((uI) => uI !== -1)
          .map((uI) => previewCustomers[i][uI + 1])
          .join(',');

        temp.push(tempEmails);

        const tempAddresses = addressesIndices
          .filter((uI) => uI !== -1)
          .map((uI) => previewCustomers[i][uI + 1])
          .join(',');

        temp.push(tempAddresses);

        customers.push(temp);
      }

      setConfirmHeaders([
        ...CustomerConfig.required.map((i) => i.title),
        ...CustomerConfig.optional.map((i) => i.title),
      ]);
      setConfirmCustomers(customers);
    },
    [selectedMatcher],
  );

  return (
    <ImportMain
      matchers={matchers}
      setMatchers={setMatchers}
      selectedMatcher={selectedMatcher}
      setSelectedMatcher={setSelectedMatcher}
      getMatchingStates={getMatchingStates}
      model="customer"
      modelConfig={CustomerConfig}
      confirmedHeaders={confirmedHeaders}
      confirmedItems={confirmedCustomers}
      setConfirmContent={setConfirmContent}
      navigateToListPage={navigateToCustomersList}
    />
  );
};

export { CustomersImportPage };
