import {
  useCallback, useEffect, useMemo,
} from 'react';
import { LoadingOverlay } from '@mantine/core';

import {
  DetailPanelContentType,
  useDetailPanelContent,
} from '../../../../contexts/useDetailPanelContentContext';
import { useOrderContext } from '../../../../contexts/useOrderContext';
import {
  DetailPanelWidthType,
  useDetailPanelWidth,
} from '../../../../contexts/useDetailPanelWidthContext';
import { Assignee } from '../Assignee';
import { useAssignDraft } from '../../../../hooks/useAssignDraft';
import { AssignedStatus } from '../../../../types/order';
import { getAssignedStatus } from '../../../../helpers/order';
import { Button } from '../../../ui/Button';
import { globalUser } from '../../../../state/globalUser';
import { OrderDraftPanelBody } from './OrderDraftPanelBody';
import { DragDropProvider } from '../../../../contexts/useDragDropContext';
import { useFetchPromptByCustomerId } from '../../../../hooks/fetch/useFetchPromptByCustomerId';
import { Pipeline } from '../../../../types/instruction';
import { isZeroId } from '../../../../helpers/objectId';
import { useFetchOrderDrafts } from '../../../../hooks/fetch/useFetchOrderDrafts';

interface Props {
  showOnCancel?: boolean;
  isOrderProcessingMode?: boolean;
  isNewOrderDraft?: boolean;
  onOrderProcessed?: (orderId: string) => void; // in case, some action is required
}

const OrderDraftPanel = ({
  showOnCancel = false,
  isOrderProcessingMode = false,
  isNewOrderDraft = false,
  onOrderProcessed,
}: Props) => {
  const { setDetailPanelWidth } = useDetailPanelWidth();
  const { setDetailPanelContent } = useDetailPanelContent();
  const { groupOrders, setGroupOrders, selectedIndex } = useOrderContext();
  const { isLoading: isAssignLoading, assignTeamMember } = useAssignDraft();
  const { isLoading: isPromptLoading, loadPrompt } = useFetchPromptByCustomerId();
  const { isLoading: isGroupLoading, loadOrderDrafts } = useFetchOrderDrafts({
    preventInitialFetch: true,
  });

  const order = useMemo(
    () => {
      const _order = groupOrders[selectedIndex];
      return {
        ..._order,
        products: (_order?.products || []).map((product) => ({ ...product })),
      };
    },
    [groupOrders, selectedIndex],
  );

  const closeOrderDraftPanel = useCallback(
    (orderId: string = order?.id) => {
      setGroupOrders([]);
      if (!isOrderProcessingMode) {
        setDetailPanelContent(DetailPanelContentType.USER_INFO);
      }
      onOrderProcessed?.(orderId);
    },
    [
      setGroupOrders,
      isOrderProcessingMode,
      onOrderProcessed,
      setDetailPanelContent,
      order?.id,
    ],
  );

  const assignedStatus: AssignedStatus = useMemo(
    () => getAssignedStatus(order?.assigneeId),
    [order?.assigneeId],
  );

  const onAssignButtonClick = useCallback(
    async (
      teamMemberId: string,
      teamMemberName: string,
      assignedComment: string,
    ) => {
      try {
        const result = await assignTeamMember({
          order,
          teamMemberId,
          teamMemberName,
          assignedComment,
        });

        setGroupOrders((_groupOrders) => _groupOrders.map((o) => (o.id === result?.id ? result : o)),
        );

        if (result && teamMemberId !== globalUser.id && isOrderProcessingMode) {
          onOrderProcessed(order?.id);
        }

        if (
          result
          && teamMemberId !== globalUser.id
          && !isOrderProcessingMode
        ) {
          closeOrderDraftPanel();
        }

        if (result.groupId && !isZeroId(result.groupId)) {
          // Get order groups
          loadOrderDrafts(false, true, result.groupId).then((orders) => {
            setGroupOrders([result, ...(orders || [])]);
          });
        }
      } catch (error) {
        /* empty */
      }
    },
    [assignTeamMember,
      closeOrderDraftPanel,
      isOrderProcessingMode,
      loadOrderDrafts,
      onOrderProcessed,
      order,
      setGroupOrders,
    ],
  );

  // Set detail panel size to large
  useEffect(() => {
    setDetailPanelWidth(DetailPanelWidthType.LARGE);
  }, [setDetailPanelWidth]);

  // When customer is changed fetch the corresponding prompt
  useEffect(() => {
    const customerId = order?.customer?.id;

    if (customerId) {
      loadPrompt(customerId, Pipeline.ORDER)
        .then((prompt) => setGroupOrders((_groupOrders) => _groupOrders.map(
          (_order, idx) => (idx === selectedIndex ? { ..._order, typeSpecs: prompt.boundTypeSpecs, promptId: prompt.id }
            : _order),
        )));
    }
  }, [loadPrompt, order?.customer?.id, selectedIndex, setGroupOrders]);

  // TODO: add loading logic for draft processing mode
  if (isAssignLoading || isGroupLoading || !order) {
    return (
      <div className="relative flex h-full flex-1 items-center justify-center">
        <LoadingOverlay
          visible
          loaderProps={{ type: 'dots' }}
          overlayProps={{ blur: 2 }}
        />
      </div>
    );
  }

  if (assignedStatus === AssignedStatus.OTHER) {
    return (
      <div className="relative flex h-full flex-1 items-center justify-center">
        <div className="flex flex-col space-y-2">
          <h1 className="text-center text-title-md">Oops!</h1>
          <p>This order is already assigned to other team member.</p>
          <div className="flex justify-center">
            <Button
              title="Close"
              theme="secondary"
              variant="small"
              onClick={showOnCancel ? closeOrderDraftPanel : null}
            />
          </div>
        </div>
      </div>
    );
  }

  if (assignedStatus === AssignedStatus.UNASSIGNED) {
    return (
      <Assignee
        order={order}
        assignOrder={onAssignButtonClick}
        onCancel={showOnCancel ? closeOrderDraftPanel : null}
      />
    );
  }

  return (
    <DragDropProvider>
      <OrderDraftPanelBody
        orderDraftsProcessingMode={!showOnCancel}
        isNewOrderDraft={isNewOrderDraft}
        isPromptLoading={isPromptLoading}
        onCancel={closeOrderDraftPanel}
        onOrderDraftProcessed={closeOrderDraftPanel}
      />
    </DragDropProvider>
  );
  // return (
  //   <OrderDraft
  //     isLoading={false}
  //     order={order}
  //     orderDraftsProcessingMode={!showOnCancel}
  //     onCancel={closeOrderDraftPanel}
  //     onOrderDraftProcessed={closeOrderDraftPanel} // TODO: bring to the order detail page
  //     isEditable
  //   />
  // );
};

export default OrderDraftPanel;
