import { Navigate } from 'react-router-dom';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { Card, Title } from '@mantine/core';
import { AccessDataStep, InvitedUserDetailsStep, TotpStep } from '../components/invited-signup';
import { WizardContent } from '../components/ui/WizardContent';
import { useWizard } from '../helpers/wizard';
import { Page } from '../components/layout/Page/Page';
import { BluePinkyBackground } from '../components/background/BluePinkyBackground';
import { AccessData, InvitedUserDetails } from '../types/invitation-sign-up';

import logo from '../assets/logo/hoshii.svg';
import { globalUser } from '../state/globalUser';

interface Props {
  inviteAppLink: string;
  navigateToHome: () => void;
}

const InvitedSignupPage = ({ inviteAppLink, navigateToHome }: Props) => {
  useEffect(() => {
    globalUser.signOut();
  }, []);

  const { currentStep, goToNextStep, goToStep } = useWizard(3);
  const [accessData, setAccessData] = useState<AccessData | null>();
  const [invitedUserDetails, setInvitedUserDetails] = useState<InvitedUserDetails | null>();
  const [isLoading, setIsLoading] = useState(false);

  const resetSteps = useCallback(() => {
    setAccessData(null);
    goToStep(0);
  }, [goToStep]);

  const onAccessDataStepCompletion = useCallback(
    (p: AccessData) => {
      setAccessData(p);
      goToNextStep();
    },
    [goToNextStep],
  );

  const onInvitedUserDetailsStepCompletion = useCallback(
    (details: InvitedUserDetails) => {
      setInvitedUserDetails(details);
      goToNextStep();
    },
    [goToNextStep],
  );

  const onTotpStepCompletion = useCallback(() => {
    navigateToHome();
  }, [navigateToHome]);

  const steps = useMemo(
    () => [
      () => (
        <AccessDataStep
          isLoading={isLoading}
          onCompletion={onAccessDataStepCompletion}
        />
      ),
      () => (
        <InvitedUserDetailsStep
          isLoading={isLoading}
          onCompletion={onInvitedUserDetailsStepCompletion}
        />
      ),
      () => (
        <TotpStep
          isLoading={isLoading}
          onCompletion={onTotpStepCompletion}
          accessData={accessData}
          inviteAppLink={inviteAppLink}
          invitedUserDetails={invitedUserDetails}
          setIsLoading={setIsLoading}
          resetSteps={resetSteps}
        />
      ),
    ],
    [
      accessData,
      inviteAppLink,
      invitedUserDetails,
      isLoading,
      onAccessDataStepCompletion,
      onInvitedUserDetailsStepCompletion,
      onTotpStepCompletion,
      resetSteps,
    ],
  );

  if (globalUser.isSignedIn) {
    return <Navigate to="/" />;
  }

  return (
    <Page isLoading={isLoading} hideMenu hideHeader>
      <div className="flex flex-1 items-start justify-center p-lg">
        <div className="relative flex h-full w-full items-center justify-center space-y-5">
          <BluePinkyBackground />

          <Card
            shadow="md"
            padding="lg"
            radius="md"
            className="w-[80%] border border-light-gray-100 sm:w-[30%] 3xl:max-w-[30%] 4xl:max-w-[25%]"
          >
            <Card.Section py="md" inheritPadding>
              <div className="flex justify-start font-mono text-[2rem] sm:text-[2.5rem]">
                <div className="flex items-center justify-center">
                  <img
                    className="relative mr-2 h-[1.5rem] w-auto"
                    src={logo}
                    alt=""
                    style={{
                      filter:
                        'brightness(0) saturate(100%) invert(12%) sepia(18%) saturate(5372%) hue-rotate(193deg) brightness(97%) contrast(96%)',
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-center">
                <Title order={1}>Sign up</Title>
              </div>
            </Card.Section>
            <WizardContent currentStep={currentStep} steps={steps} />
          </Card>
        </div>
      </div>
    </Page>
  );
};

export { InvitedSignupPage };
