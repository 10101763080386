import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ProductPage } from '../../pages/ProductPage';
import { ProtectedScreen } from '../../components/ui/ProtectedScreen';
import { ROUTES } from '../../config/routes';

const ProductsScreen = () => {
  const { productId } = useParams();
  const navigate = useNavigate();

  const navigateToProductsList = useCallback(() => {
    navigate(ROUTES.PRODUCTS);
  }, [navigate]);

  return (
    <ProtectedScreen>
      <ProductPage
        productId={productId}
        navigateToProductsList={navigateToProductsList}
      />
    </ProtectedScreen>
  );
};

export { ProductsScreen };
