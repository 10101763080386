import {
  memo,
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Accordion, Switch, Popover,
} from '@mantine/core';
import { PlusIcon } from '@heroicons/react/24/outline';
import { v4 as uuidv4 } from 'uuid';

import { FieldSpec, Schema } from '../../../../../models/Schema';
import { getValueByPath } from '../../../../../helpers/schema';
import { ProductWithQuantity } from '../../../../../models/Order';
import { Button } from '../../../../ui/Button';
import ProductField from './ProductField';
import { useKeywordContext } from '../../../../../contexts/useKeywordContext';

interface Props {
  fieldSpecs: FieldSpec[];
  obj: any;
  schema: Schema;
  updateValueByPath: (value: any, path: string, indices?: number[]) => void;
  updateProductByIndex?: (index: number, values: any) => void;
  addNewProduct?: (product: ProductWithQuantity) => void;
  setError?: (key: string, error: string) => void;
}

const Products = memo(({
  accordionValue,
  products: _products,
  fieldSpecs,
  obj,
  schema,
  sortByIdChecked,
  filterEnabledProducts,
  setAccordionValue,
  getProductFieldValue,
  updateValueByPath,
  updateProductByIndex,
  setError,
}: {
  accordionValue: string[];
  products: ProductWithQuantity[];
  fieldSpecs: FieldSpec[];
  obj: any;
  schema: Schema;
  sortByIdChecked: boolean;
  filterEnabledProducts: boolean;
  setAccordionValue: (value: string[]) => void;
  getProductFieldValue: (index: number, modelPath: string) => any;
  updateValueByPath: (value: any, path: string, indices?: number[]) => void;
  updateProductByIndex?: (index: number, values: any) => void;
  setError?: (key: string, error: string) => void;
}) => {
  const products = (_products || []).filter((product) => product.uiId);

  if (products.length === 0) return <p className="text-sm text-gray-500">No products found</p>;

  return (
    <Accordion
      multiple
      transitionDuration={300}
      value={accordionValue}
      variant="contained"
      onChange={setAccordionValue}
    >
      {products.map((product: ProductWithQuantity, i: number) => (
        <ProductField
          key={product.uiId}
          value={product.uiId}
          index={i}
          product={product}
          fieldSpecs={fieldSpecs}
          schema={schema}
          customerId={obj.customer?.id}
          sortByIdChecked={sortByIdChecked}
          filterEnabledProducts={filterEnabledProducts}
          setAccordionValue={setAccordionValue}
          updateValueByPath={updateValueByPath}
          updateProductByIndex={updateProductByIndex}
          getProductFieldValue={getProductFieldValue}
          setError={setError}
        />
      ))}
    </Accordion>
  );
});

const HeaderSection = memo(({
  sortByIdChecked,
  filterEnabledProducts,
  onSortByIdSwitchChange,
  onFilterEnabledProductsSwitchChange,
  products,
  addNewProduct,
}: {
  sortByIdChecked: boolean;
  filterEnabledProducts: boolean;
  onSortByIdSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterEnabledProductsSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  products: ProductWithQuantity[];
  addNewProduct: (product: ProductWithQuantity) => void;
}) => {
  const { setScrollKeyword } = useKeywordContext();
  return (
    <div className="flex items-center justify-between py-sm sticky top-0 bg-white z-10">
      <Popover position="bottom" withArrow shadow="md">
        <Popover.Target>
          <Button title="Filter products" variant="small" onClick={() => {}} />
        </Popover.Target>
        <Popover.Dropdown className="space-y-2">
          <Switch
            checked={sortByIdChecked}
            onChange={onSortByIdSwitchChange}
            labelPosition="left"
            label="Sort products by ID"
            classNames={{
              body: 'w-full flex justify-between',
            }}
          />
          <Switch
            checked={filterEnabledProducts}
            onChange={onFilterEnabledProductsSwitchChange}
            labelPosition="left"
            label="Show only enabled products"
          />
        </Popover.Dropdown>
      </Popover>
      <Button
        title="Add product"
        theme="secondary"
        variant="small"
        icon={<PlusIcon className="aspect-square w-4" />}
        onClick={() => {
          const newProductname = `New product ${(products || []).length + 1}`;
          setScrollKeyword(newProductname);
          addNewProduct?.({
            id: '',
            uiId: uuidv4(),
            name: newProductname,
            quantity: null,
            product: null,
            unit: null,
            price: null,
            comment: '',
            score: 0,
            autoMatched: false,
            defaultUnitConversionFactor: null,
          });
        }}
      />
    </div>
  );
});

const ProductFields = ({
  fieldSpecs,
  obj,
  schema,
  updateValueByPath,
  updateProductByIndex,
  addNewProduct: _addNewProduct,
  setError,
}: Props) => {
  const [sortByIdChecked, setSortByIdChecked] = useState<boolean>(false);
  const [filterEnabledProducts, setFilterEnabledProducts] = useState<boolean>(false);
  const [accordionValue, setAccordionValue] = useState<string[]>([]);

  const firstRender = useRef(true);

  const productsPath = useMemo(() => fieldSpecs?.[0]?.modelPath?.split('.')?.[0], [fieldSpecs]);
  const products: ProductWithQuantity[] = useMemo(() => getValueByPath(obj, productsPath), [obj, productsPath]);

  const onSortByIdSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setSortByIdChecked(event.currentTarget.checked), []);

  const onFilterEnabledProductsSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setFilterEnabledProducts(event.currentTarget.checked), []);

  const getProductFieldValue = useCallback((index: number, modelPath: string) => {
    const path = modelPath.split(`${productsPath}.*.`)[1];

    if (!path) return null;

    return getValueByPath(products[index], path);
  }, [products, productsPath]);

  const addNewProduct = useCallback((product: ProductWithQuantity) => {
    _addNewProduct?.(product);
    setAccordionValue((prev) => [...prev, product.uiId]);
  }, [_addNewProduct]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      setAccordionValue(products?.filter(
        (product: ProductWithQuantity) => !product.confirmed).map((product: ProductWithQuantity) => product.uiId));
    }
  }, [products]);

  useEffect(() => {
    const storedSortByIdChecked = localStorage.getItem('sortByIdChecked');
    if (storedSortByIdChecked !== null) {
      setSortByIdChecked(JSON.parse(storedSortByIdChecked));
    }

    const storedFilterEnabledProducts = localStorage.getItem('filterEnabledProducts');
    if (storedFilterEnabledProducts !== null) {
      setFilterEnabledProducts(JSON.parse(storedFilterEnabledProducts));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('sortByIdChecked', JSON.stringify(sortByIdChecked));
  }, [sortByIdChecked]);

  useEffect(() => {
    localStorage.setItem('filterEnabledProducts', JSON.stringify(filterEnabledProducts));
  }, [filterEnabledProducts]);

  return (
    <div className="space-y-2 relative">
      <HeaderSection
        filterEnabledProducts={filterEnabledProducts}
        onFilterEnabledProductsSwitchChange={onFilterEnabledProductsSwitchChange}
        sortByIdChecked={sortByIdChecked}
        onSortByIdSwitchChange={onSortByIdSwitchChange}
        products={products}
        addNewProduct={addNewProduct}
      />

      <Products
        accordionValue={accordionValue}
        fieldSpecs={fieldSpecs}
        obj={obj}
        products={products}
        schema={schema}
        setError={setError}
        getProductFieldValue={getProductFieldValue}
        sortByIdChecked={sortByIdChecked}
        filterEnabledProducts={filterEnabledProducts}
        setAccordionValue={setAccordionValue}
        updateProductByIndex={updateProductByIndex}
        updateValueByPath={updateValueByPath}
      />
    </div>
  );
};

export default ProductFields;
