import { useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { httpGet } from '../../helpers/xhr';
import { genericErrorFeedback } from '../../helpers/errors';
import { TypeRef } from '../../types/type-ref';
import { Schema } from '../../models/Schema';

const useFetchSchemaByTypeRef = () => {
  const [schema, setSchema] = useState<Schema | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadSchema = useCallback(
    (
      typeRef: TypeRef,
      giveErrorFeedback: boolean = true,
    ): Promise<Schema> => {
      if (!typeRef) return Promise.reject();
      setIsLoading(true);
      return httpGet('/schemas', {
        params: {
          type_ref: typeRef,
        },
      })
        .then((response) => {
          const _schema = response.data as Schema;
          const _schemaWithUiId: Schema = {
            ..._schema,
            fields: _schema.fields.map((field) => ({
              ...field,
              uiId: uuidv4(),
            })),
          };
          setSchema(_schemaWithUiId);
          return _schemaWithUiId;
        })
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('Error occured during loading messages.')(
              error,
            );
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return {
    schema,
    loadSchema,
    isLoading,
  };
};

export { useFetchSchemaByTypeRef };
