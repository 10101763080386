import { ProtectedScreen } from '../../components/ui/ProtectedScreen';
import { SettingsPage } from '../../pages/SettingsPage';

const SettingsScreen = () => (
  <ProtectedScreen>
    <SettingsPage />
  </ProtectedScreen>
);

export { SettingsScreen };
