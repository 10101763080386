import { useCallback, useMemo, useRef } from 'react';
import { LoadingOverlay } from '@mantine/core';

import { useDeclineOrderDraft } from '../../../../../hooks/useDeclineOrderDraft';
import { useUpdateOrderDrafts } from '../../../../../hooks/useUpdateOrderDrafts';
import { useConfirmOrderDraft } from '../../../../../hooks/useConfirmOrderDraft';

// import { HeaderSection } from './HeaderSection';
// import { ProductListSection } from './ProductListSection';
import { ButtonSection } from './ButtonSection';
import { useOrderContext } from '../../../../../contexts/useOrderContext';

import { Tabs } from './ProductListSection/_Tabs';
import { ConfirmOrderModalWrapper } from '../../../../wrapper/ConfirmOrderModalWrapper';
import { useConfirmOrderDraftsByGroupId } from '../../../../../hooks/useConfirmOrderDraftsByGroupId';
import { isZeroId } from '../../../../../helpers/objectId';
import { OrderDraftRender } from '../../../../prompt/render';

interface Props {
  orderDraftsProcessingMode: boolean;
  onCancel: () => void;
  onOrderDraftProcessed: (orderId: string) => void;

  isNewOrderDraft?: boolean;
  isPromptLoading?: boolean;
}

const OrderDraftPanelBody = ({
  orderDraftsProcessingMode,
  onCancel,
  onOrderDraftProcessed,

  isNewOrderDraft,
  isPromptLoading,
}: Props) => {
  const { isLoading: isDeclineOrderDraftLoading, declineOrderDraft } = useDeclineOrderDraft();
  const { isLoading: isSaveAndCreateOrderDraftsLoading, updateOrderDrafts } = useUpdateOrderDrafts();
  const { isLoading: isConfirmOrderDraftLoading, confirmOrderDraft } = useConfirmOrderDraft();
  const {
    isLoading: isConfirmOrderDraftsByGroupIdLoading,
    confirmOrderDraftsByGroupId,
  } = useConfirmOrderDraftsByGroupId();
  const { groupOrders, selectedIndex: tabIndex } = useOrderContext();

  const confirmOrderModalWrapperRef = useRef(null);

  const order = useMemo(() => groupOrders[tabIndex], [tabIndex, groupOrders]);

  const isInnerLoading = useMemo(
    () => !order
      || isDeclineOrderDraftLoading
      || isSaveAndCreateOrderDraftsLoading
      || isConfirmOrderDraftLoading
      || isConfirmOrderDraftsByGroupIdLoading
      || isPromptLoading,
    [
      order,
      isDeclineOrderDraftLoading,
      isSaveAndCreateOrderDraftsLoading,
      isConfirmOrderDraftLoading,
      isConfirmOrderDraftsByGroupIdLoading,
      isPromptLoading,
    ],
  );

  const _onConfirm = useCallback(() => {
    if (order.groupId && !isZeroId(order.groupId)) {
      confirmOrderDraftsByGroupId(order.groupId).then(() => {
        if (orderDraftsProcessingMode) onOrderDraftProcessed(groupOrders.find((o) => !o.isGroupSibling)?.id);
        else onCancel?.();
      });
    } else {
      confirmOrderDraft(order.id).then(() => {
        if (orderDraftsProcessingMode) onOrderDraftProcessed(order.id);
        else onCancel?.();
      });
    }
  }, [
    confirmOrderDraft,
    confirmOrderDraftsByGroupId,
    groupOrders,
    onCancel,
    onOrderDraftProcessed,
    order.groupId,
    order.id,
    orderDraftsProcessingMode,
  ]);

  const onConfirmButtonClick = useCallback(() => {
    if (
      !order
      || isConfirmOrderDraftLoading
      || isConfirmOrderDraftsByGroupIdLoading
    ) return;

    if (order.groupId && !isZeroId(order.groupId)) {
      confirmOrderModalWrapperRef.current?.openModal();
    } else {
      _onConfirm();
    }
  }, [
    _onConfirm,
    isConfirmOrderDraftLoading,
    isConfirmOrderDraftsByGroupIdLoading,
    order,
  ]);

  return (
    <ConfirmOrderModalWrapper
      ref={confirmOrderModalWrapperRef}
      onConfirm={_onConfirm}
    >
      <div className="flex flex-1 flex-col overflow-hidden">
        <Tabs />

        <div className="relative mt-2 flex flex-1 flex-col overflow-hidden">
          <LoadingOverlay
            visible={isInnerLoading}
            loaderProps={{ type: 'dots' }}
            overlayProps={{ blur: 2 }}
          />

          <div className="hidden-scrollbar flex-1 overflow-y-scroll">
            <div className="flex flex-col gap-lg">
              <OrderDraftRender isInnerLoading={isInnerLoading} />

              {/* <div className="hidden">
                <HeaderSection isInnerLoading={isInnerLoading} />

                <ProductListSection
                  onConfirmButtonClick={onConfirmButtonClick}
                  isInnerLoading={isInnerLoading}
                />
              </div> */}
            </div>
          </div>
        </div>

        <div className="flex h-fit justify-between border-t border-t-blue-gray-50 bg-white pt-lg">
          <ButtonSection
            orderDraftsProcessingMode={orderDraftsProcessingMode}
            onCancel={onCancel}
            onOrderDraftProcessed={onOrderDraftProcessed}
            isNewOrderDraft={isNewOrderDraft}
            isInnerLoading={isInnerLoading}
            onConfirmButtonClick={onConfirmButtonClick}
            updateOrderDrafts={updateOrderDrafts}
            declineOrderDraft={declineOrderDraft}
          />
        </div>
      </div>
    </ConfirmOrderModalWrapper>
  );
};

export default OrderDraftPanelBody;
