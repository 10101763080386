import {
  useCallback, useEffect, useRef, useMemo,
} from 'react';
import { LoadingOverlay } from '@mantine/core';
import { observer } from 'mobx-react-lite';

import { DetailPanel } from '../../common/DetailPanel';
import { useOrderContext } from '../../../contexts/useOrderContext';
import { Messages } from './Messages';
import { useFetchOrder } from '../../../hooks/fetch/useFetchOrder';
import { useMessagesContext } from '../../../contexts/useMessagesContext';
import { ManageCustomerSidebarWrapper } from '../../wrapper/ManageCustomerSidebarWrapper';
import { Business } from '../../../models/Business';
import { useFetchOrderDrafts } from '../../../hooks/fetch/useFetchOrderDrafts';
import { isZeroId } from '../../../helpers/objectId';
import { useFetchBusinessSettings } from '../../../hooks/fetch/useFetchBusinessSettings';
import { useBusinessSettingsContext } from '../../../contexts/useBusinessSettingsContext';
import { useFetchSchemaByTypeRef } from '../../../hooks/fetch/useFetchSchemaByTypeRef';
import { useSchemasContext } from '../../../contexts/useSchemasContext';
import { getAssignedStatus } from '../../../helpers/order';
import { AssignedStatus } from '../../../types/order';
import { WorkflowRun } from '../../../models/Workflow';
import { WorkflowRetrySidebarWrapper } from '../../wrapper/WorkflowRetrySidebarWrapper';
import { useMarkMessageAsRead } from '../../../hooks/useMarkMessageAsRead';
import { isZeroTime } from '../../../helpers/dateTime';
import { useRetryWorkflow } from '../../../hooks/useRetryWorkflow';

interface Props {
  orderId: string;

  onOrderProcessed?: (orderId: string) => void;
  onOrderRetried?: (orderId: string) => Promise<any>;
}

const ProcessOrderDrafts = observer(({
  orderId,

  onOrderProcessed,
  onOrderRetried,
}: Props) => {
  const { retryWorkflow, pendingLROs } = useRetryWorkflow({ onOrderWorkflowRunRetried: onOrderRetried });
  const {
    groupOrders, setGroupOrders, selectedIndex, setSelectedIndex,
  } = useOrderContext();

  const { schemas, setSchemas } = useSchemasContext();

  const { setBusinessSettings } = useBusinessSettingsContext();

  const { markMessagesAsRead } = useMarkMessageAsRead();

  const { order, isLoading: isOrderLoading } = useFetchOrder({
    orderId,
    preventFetch: false,
  });

  const { loadSchema: loadOrderSchema, isLoading: isOrderSchemaLoading } = useFetchSchemaByTypeRef();

  const pendingOrderRetries = useMemo(() => new Set(
    Array.from(pendingLROs.values()).map((lro) => lro.data as string),
  ), [pendingLROs]);
  const isOrderRetrying = useMemo(() => pendingOrderRetries.has(orderId), [pendingOrderRetries, orderId]);

  const onRetryButtonClick = (selectedWorkflow: string, workflowRun: WorkflowRun) => {
    retryWorkflow(selectedWorkflow, workflowRun, orderId);
  };

  const { isLoading: isGroupLoading, loadOrderDrafts } = useFetchOrderDrafts({
    preventInitialFetch: true,
  });

  const { businessSettings, isLoading: isBusinessSettingsLoading } = useFetchBusinessSettings({});

  const { messages, isLoading: isMessageLoading } = useMessagesContext();

  const manageCustomerModalWrapperRef = useRef(null);
  const workflowRetrySidebarWrapperRef = useRef(null);

  const onManageCustomerButtonClick = useCallback(() => {
    if (manageCustomerModalWrapperRef.current) {
      manageCustomerModalWrapperRef.current.onManageCustomerButtonClick();
    }
  }, []);

  const onOpenRetryWorkflowSidebarButtonClick = useCallback(() => {
    if (workflowRetrySidebarWrapperRef.current) {
      workflowRetrySidebarWrapperRef.current.onRetryWorkflowButtonClick();
    }
  }, []);

  const onCustomerReconciliationDone = useCallback(
    (customer: Business) => {
      setGroupOrders((orders) => orders.map((_order, index) => {
        if (index === selectedIndex) {
          return {
            ..._order,
            customer,
          };
        }
        return _order;
      }),
      );
    },
    [selectedIndex, setGroupOrders],
  );

  useEffect(() => {
    if (order && setGroupOrders && setSelectedIndex && loadOrderDrafts) {
      const assignedStatus = getAssignedStatus(order.assigneeId);
      if (assignedStatus === AssignedStatus.ME && order.groupId && !isZeroId(order.groupId)) {
        // Get order groups
        loadOrderDrafts(false, true, order.groupId).then((orders) => {
          setGroupOrders([order, ...(orders || [])]);
        });
      } else {
        setGroupOrders([{
          ...order,
          products: (order.products || []).map((product) => ({ ...product })),
        }]);
      }

      setSelectedIndex(0);
    }
  }, [loadOrderDrafts, order, setGroupOrders, setSelectedIndex]);

  useEffect(() => {
    setBusinessSettings(businessSettings);
  }, [businessSettings, setBusinessSettings]);

  useEffect(() => {
    if (order) {
      order.typeSpecs?.forEach((typeSpec) => {
        if (schemas[typeSpec.typeRef]) {
          return;
        }

        loadOrderSchema(typeSpec.typeRef)
          .then((schema) => {
            setSchemas((_schemas) => ({ ..._schemas, [typeSpec.typeRef]: schema }));
          });
      });
    }
  }, [order, loadOrderSchema, setSchemas, schemas]);

  useEffect(() => {
    if (messages.length > 0) {
      const toMark = messages.filter((m) => isZeroTime(m.readAt)).map((m) => m.id);
      if (toMark.length > 0) {
        markMessagesAsRead(toMark);
      }
    }
  }, [messages, markMessagesAsRead]);

  return (
    <div className="relative h-full overflow-hidden">
      <LoadingOverlay
        visible={isOrderRetrying}
        overlayProps={{ blur: 2 }}
      />
      <ManageCustomerSidebarWrapper
        ref={manageCustomerModalWrapperRef}
        customer={order?.customer}
        onComplete={onCustomerReconciliationDone}
      >
        <WorkflowRetrySidebarWrapper
          ref={workflowRetrySidebarWrapperRef}
          workflowRunId={order?.createdByWorkflowRunId}
          onRetryWorkflow={onRetryButtonClick}
        >
          <div className="flex h-full flex-1 overflow-hidden">
            <div className="max-[50%] relative h-full min-w-[50%] flex flex-col overflow-hidden border-r border-blue-gray-50">
              {!isZeroId(order?.createdByWorkflowRunId) && (
              <div className="relative w-full bg-white border-b flex py-1 px-lg text-sm">
                <p className="text-blue-gray-200 px-lg">
                  Was the order not processed properly or it is missing some information?
                  <button
                    type="button"
                    className="text-primary-500 pl-sm"
                    onClick={onOpenRetryWorkflowSidebarButtonClick}
                  >
                    Retry
                  </button>
                </p>
              </div>
              )}

              <LoadingOverlay
                visible={isMessageLoading}
                loaderProps={{ type: 'dots' }}
                overlayProps={{ blur: 2 }}
              />
              <Messages
                messages={messages}
                customer={groupOrders[0]?.customer}
                onManageCustomerButtonClick={onManageCustomerButtonClick}
              />
            </div>
            <div className="max-[50%] relative h-full min-w-[50%]">
              <LoadingOverlay
                visible={isOrderLoading || isGroupLoading || isBusinessSettingsLoading || isOrderSchemaLoading}
                loaderProps={{ type: 'dots' }}
                overlayProps={{ blur: 2 }}
              />
              <DetailPanel onOrderProcessed={onOrderProcessed} />
            </div>
          </div>
        </WorkflowRetrySidebarWrapper>
      </ManageCustomerSidebarWrapper>
    </div>
  );
});

export { ProcessOrderDrafts };
