import { Routes, Route, BrowserRouter } from 'react-router-dom';

import { ActivateUserScreen } from './screens/ActivateUserScreen';
import { ChatsListScreen } from './screens/ChatsListScreen';
import { CustomerScreen } from './screens/CustomerScreen';
import { CustomersImportScreen } from './screens/CustomersImportScreen';
import { CustomersListScreen } from './screens/CustomersListScreen';
import { HomeScreen } from './screens/HomeScreen';
import { InvitedSignupScreen } from './screens/InvitedSignupScreen';
import { MockedAssistanceRequstScreen } from './screens/MockedAssistanceRequestScreen';
import { MockedComplaintsScreen } from './screens/MockedComplaintsScreen';
import { OrderScreen } from './screens/OrderScreen';
import { OrdersListScreen } from './screens/OrdersListScreen';
import { ProductsImportScreen } from './screens/ProductsImportScreen';
import { ProductsListScreen } from './screens/ProductsListScreen';
import { ProductsScreen } from './screens/ProductScreen';
import { RoutingLog } from './RoutingLog';
import { SettingsScreen } from './screens/SettingsScreen';
import { SigninScreen } from './screens/SigninScreen';
import { TeamMemberInviteScreen } from './screens/TeamMemberInviteScreen';
import { TeamMemberListScreen } from './screens/TeamMembersListScreen';
import { TeamMemberScreen } from './screens/TeamMemberScreen';
import { ROUTES } from '../config/routes';
import { ChatScreen } from './screens/ChatScreen';
import { ProcessOrderDraftsScreen } from './screens/ProcessOrderDraftsScreen';
import { ProcessNewOrderDraftScreen } from './screens/ProcessNewOrderDraftScreen';
import { InstructionsScreen } from './screens/InstructionScreen';
import CreateInstructionScreen from './screens/CreateInstructionScreen';
import { EditInstructionScreen } from './screens/EditInstructionScreen';
import { ProcessOrderDraftByIdScreen } from './screens/ProcessOrderDraftByIdScreen';
import { MessagesScreen } from './screens/MessagesScreen';
import { MessageByIdScreen } from './screens/MessageByIdScreen';
import { NewAdamChatScreen } from './screens/NewAdamChatScreen';

const Routing = () => (
  <BrowserRouter>
    <RoutingLog enabled={false} />

    <Routes>
      <Route path={ROUTES.HOME} element={<HomeScreen />} />

      <Route path={ROUTES.SIGNIN(':username')} element={<SigninScreen />} />
      <Route
        path={ROUTES.INVITED_SIGNUP(':appLink')}
        element={<InvitedSignupScreen />}
      />
      <Route path={ROUTES.ACTIVATE_USER} element={<ActivateUserScreen />} />

      <Route path={ROUTES.PRODUCTS} element={<ProductsListScreen />} />
      <Route
        path={ROUTES.PRODUCT_BY_ID(':productId')}
        element={<ProductsScreen />}
      />
      <Route path={ROUTES.PRODUCTS_IMPORT} element={<ProductsImportScreen />} />

      <Route path={ROUTES.CUSTOMERS} element={<CustomersListScreen />} />
      <Route
        path={ROUTES.CUSTOMERS_IMPORT}
        element={<CustomersImportScreen />}
      />
      <Route
        path={ROUTES.CUSTOMER_BY_ID(':businessId')}
        element={<CustomerScreen />}
      />

      <Route path={ROUTES.CHATS} element={<ChatsListScreen />} />
      <Route path={ROUTES.NEW_ADAM_CHAT} element={<NewAdamChatScreen />} />
      <Route path={ROUTES.CHAT_BY_ID(':chatId')} element={<ChatScreen />} />
      <Route path={ROUTES.MESSAGES({})} element={<MessagesScreen />} />
      <Route path={ROUTES.MESSAGE_BY_ID(':messageId')} element={<MessageByIdScreen />} />

      <Route path={ROUTES.ORDERS} element={<OrdersListScreen />} />
      <Route path={ROUTES.ORDER_BY_ID(':orderId')} element={<OrderScreen />} />
      <Route
        path={ROUTES.PROCESS_ORDER_DRAFTS}
        element={<ProcessOrderDraftsScreen />}
      />
      <Route
        path={ROUTES.PROCESS_ORDER_DRAFT_BY_ID(':orderId')}
        element={<ProcessOrderDraftByIdScreen />}
      />
      <Route
        path={ROUTES.PROCESS_NEW_ORDER_DRAFTS}
        element={<ProcessNewOrderDraftScreen />}
      />

      <Route path={ROUTES.TEAM_MEMBERS} element={<TeamMemberListScreen />} />
      <Route
        path={ROUTES.TEAM_MEMBER_ADD}
        element={<TeamMemberInviteScreen />}
      />
      <Route
        path={ROUTES.TEAM_MEMBER_BY_ID(':userId')}
        element={<TeamMemberScreen />}
      />

      <Route path={ROUTES.INSTRUCTIONS} element={<InstructionsScreen />} />
      <Route path={ROUTES.CREATE_INSTRUCTION} element={<CreateInstructionScreen />} />
      <Route path={ROUTES.EDIT_INSTRUCTION(':instructionId')} element={<EditInstructionScreen />} />

      <Route path={ROUTES.SETTINGS} element={<SettingsScreen />} />

      {SHOW_BETA_COMPONENTS === 'true' ? (
        <>
          <Route path="complaints" element={<MockedComplaintsScreen />} />
          <Route
            path="assistance_request"
            element={<MockedAssistanceRequstScreen />}
          />
        </>
      ) : null}
    </Routes>
  </BrowserRouter>
);

export { Routing };
