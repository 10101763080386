import {
  memo, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { UserIcon } from '@heroicons/react/20/solid';

import { FlashCard } from '../../../../FlashCard';
import { Button } from '../../../../ui/Button';
import { ItemsExpanded } from '../../../../../types/common';
import { useChatsContext } from '../../../../../contexts/useChatsContext';
import { Chat } from '../../../../../models/Chat';
import { ProfileSection } from './Section/ProfileSection';
import {
  DetailPanelWidthType,
  useDetailPanelWidth,
} from '../../../../../contexts/useDetailPanelWidthContext';
import { ManageCustomerSidebarWrapper } from '../../../../wrapper/ManageCustomerSidebarWrapper';
import { Business } from '../../../../../models/Business';
import { isAiChat as isAiChatHelper } from '../../../../../helpers/chat';

import adam from '../../../../../assets/adam-face.png';

interface Props {
  chatId: string;
}

const UserInfoPanel = memo(({ chatId }: Props) => {
  const { setDetailPanelWidth } = useDetailPanelWidth();
  const { chats, setChats } = useChatsContext();

  const chat: Chat | undefined = useMemo(
    () => chats.find((c) => c.id === chatId),
    [chats, chatId],
  );

  const isAiChat = useMemo(() => isAiChatHelper(chat), [chat]);

  const [customerSectionExpanded, setCustomerSectionExpanded] = useState<ItemsExpanded>({});
  const onCustomerSectionOpenClicked = (id: string) => {
    setCustomerSectionExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const manageCustomerModalWrapperRef = useRef(null);

  const onManageCustomerButtonClick = useCallback(() => {
    if (manageCustomerModalWrapperRef.current) {
      manageCustomerModalWrapperRef.current.onManageCustomerButtonClick();
    }
  }, []);

  useEffect(() => {
    setDetailPanelWidth(DetailPanelWidthType.SMALL);
  }, [setDetailPanelWidth]);

  const onCustomerReconciliationDone = useCallback(
    (business: Business) => {
      setChats((_chats) => _chats.map((_chat) => {
        if (_chat.id === chatId) {
          return {
            ..._chat,
            business,
          };
        }
        return _chat;
      }),
      );
    },
    [chatId, setChats],
  );

  if (!chat?.business) return null;

  if (isAiChat) {
    return (
      <div className="h-full w-full pt-lg">
        <div>
          <div className="border-b border-blue-gray-50 pb-lg">
            <div className="flex w-full justify-center">
              <div className="flex aspect-square w-[20%] shrink-0 items-center justify-center overflow-hidden rounded-full bg-gray-300">
                <img
                  src={adam}
                  alt="adam"
                  className="w-full aspect-square rounded-full object-cover"
                />
              </div>
            </div>
            <div className="pt-sm text-center text-title-md">
              Adam
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <ManageCustomerSidebarWrapper
      ref={manageCustomerModalWrapperRef}
      customer={chat.business}
      onComplete={onCustomerReconciliationDone}
    >
      <div className="h-full w-full pt-lg">
        <div>
          <div className="border-b border-blue-gray-50 pb-lg">
            <div className="flex w-full justify-center">
              <div className="flex aspect-square w-[20%] shrink-0 items-center justify-center overflow-hidden rounded-full bg-gray-300">
                <UserIcon className="aspect-square w-[80%] text-white" />
              </div>
            </div>
            <div className="pt-sm text-center text-title-md">
              {chat.business.name}
            </div>

            {chat.business.customer && !chat.business.customer.isConfirmed && (
              <div className="py-lg">
                <FlashCard
                  theme="warning"
                  size="small"
                  title="New customer detected"
                  description="This chat is associated with an unrecognized customer and is awaiting confirmation."
                >
                  <div>
                    <Button
                      title="Manage"
                      onClick={onManageCustomerButtonClick}
                      theme="warning"
                      variant="small"
                    />
                  </div>
                </FlashCard>
              </div>
            )}
          </div>

          <ProfileSection
            chat={chat}
            isExpanded={customerSectionExpanded.profile}
            onCustomerSectionOpenClicked={onCustomerSectionOpenClicked}
          />
        </div>
      </div>
    </ManageCustomerSidebarWrapper>
  );
});

export default UserInfoPanel;
