import { ProtectedScreen } from '../../components/ui/ProtectedScreen';
import CreateInstructionPage from '../../pages/CreateInstructionPage';

const CreateInstructionScreen = () => (
  <ProtectedScreen>
    <CreateInstructionPage />
  </ProtectedScreen>
);

export default CreateInstructionScreen;
