import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { TeamMembersListPage } from '../../pages/TeamMembersListPage';
import { ProtectedScreen } from '../../components/ui/ProtectedScreen';
import { ROUTES } from '../../config/routes';

const TeamMemberListScreen = () => {
  const navigate = useNavigate();

  const navigateToAddTeamMember = useCallback(() => {
    navigate(ROUTES.TEAM_MEMBER_ADD);
  }, [navigate]);

  const navigateToTeamMember = useCallback(
    (userId: string) => {
      navigate(ROUTES.TEAM_MEMBER_BY_ID(userId));
    },
    [navigate],
  );

  return (
    <ProtectedScreen>
      <TeamMembersListPage
        navigateToAddTeamMember={navigateToAddTeamMember}
        navigateToTeamMember={navigateToTeamMember}
      />
    </ProtectedScreen>
  );
};

export { TeamMemberListScreen };
