import { useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';

import { Carousel } from '@mantine/carousel';
import { twMerge } from 'tailwind-merge';
import { Card } from '../../common/Card';
import { Button } from '../../ui/Button';
import { IntentProps } from './types';

import classes from './IntentCard.module.css';

interface Props {
  cardTitle: string;
  cardSubtitle: string;
  intentProps: IntentProps[];
  className?: string;
}

const IntentCard = ({
  cardTitle, cardSubtitle, intentProps, className,
}: Props) => {
  const autoplay = useRef(Autoplay({ delay: 4000 }));

  const indicatorsStyle = intentProps.length < 2
    ? { display: 'none' }
    : {
      bottom: '100%',
      justifyContent: 'end',
      insetInlineEnd: '35%',
      gap: '3px',
    };

  const controlsStyle = intentProps.length < 2 ? { display: 'none' } : {};

  return (
    <Card
      className={twMerge(
        'col-span-4 row-span-1',
        className,
      )}
    >
      <Card.Body>
        <h4 className="card-title flex flex-col items-start">
          <span className="text-sm font-semibold text-gray-800">
            {cardTitle}
          </span>
          <span className="mt-1 text-xs text-gray-500">{cardSubtitle}</span>
        </h4>
        <div className="w-full overflow-hidden">
          <Carousel
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
            withIndicators
            classNames={classes}
            styles={{
              // indicator: {
              // backgroundColor: 'blue',
              // },
              indicators: indicatorsStyle,
              controls: controlsStyle,
            }}
          >
            {intentProps.map((messageIntent, i) => (
              <Carousel.Slide className="relative" key={`${messageIntent.cardTitle}-slide-${i.toString()}`}>
                <div className="w-full py-4 flex justify-center items-center">
                  {messageIntent.cardTitle}
                </div>
                <div className="flex gap-2">
                  {messageIntent.buttons.map((button, index) => (
                    <Button
                      key={`${messageIntent.cardTitle}-button-${index.toString()}`}
                      title={button.title}
                      theme={button.theme}
                      onClick={button.onClick}
                    />
                  ))}
                </div>
              </Carousel.Slide>
            ))}
            {/* {intentProps.map((messageIntent, i) => (
              <Carousel.Slide key={`${messageIntent.cardTitle}-slide-${i.toString()}`}>
                <div className="mb-5 flex items-center">
                  <div className="me-2 w-[80px]">
                    <DonutChart
                      size={80}
                      thickness={8}
                      data={messageIntent.cardData || []}
                      paddingAngle={0}
                      tooltipDataSource="segment"
                    />
                  </div>
                  <div className="m-0 flex-1">
                    <OverflowEllipsisParagraph
                      maxLines={1}
                      isTooltipEnabled
                      className="mb-3 text-sm font-semibold text-gray-800"
                    >
                      {messageIntent.cardTitle}
                    </OverflowEllipsisParagraph>
                    <div className="grid grid-cols-2 gap-2">
                      {messageIntent.cardSubtitles.map((subtitle, index) => (
                        <span
                          key={`${messageIntent.cardTitle}-subtitle-${index.toString()}`}
                          className="fw-bold mb-2 flex items-center text-xs text-gray-500"
                        >
                          <span className="me-1.5 rounded-sm border border-gray-300 text-gray-400">
                            <ChevronRightIcon className="h-3 w-3" />
                          </span>
                          {' '}
                          {subtitle}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex gap-2">
                  {messageIntent.buttons.map((button, index) => (
                    <Button
                      key={`${messageIntent.cardTitle}-button-${index.toString()}`}
                      title={button.title}
                      theme={button.theme}
                      onClick={button.onClick}
                    />
                  ))}
                </div>
              </Carousel.Slide>
            ))} */}
          </Carousel>
        </div>
      </Card.Body>
    </Card>
  );
};

export default IntentCard;
