import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { SigninPage } from '../../pages/SigninPage';
import { ROUTES } from '../../config/routes';

const SigninScreen = () => {
  const { username } = useParams();
  const navigate = useNavigate();

  const navigateToHome = useCallback(() => {
    navigate(ROUTES.HOME);
  }, [navigate]);

  return <SigninPage username={username} navigateToHome={navigateToHome} />;
};

export { SigninScreen };
