import { useParams } from 'react-router-dom';

import { ProtectedScreen } from '../../components/ui/ProtectedScreen';
import { TeamMemberPage } from '../../pages/TeamMemberPage';

const TeamMemberScreen = () => {
  const { userId } = useParams();

  return (
    <ProtectedScreen>
      <TeamMemberPage userId={userId} />
    </ProtectedScreen>
  );
};

export { TeamMemberScreen };
