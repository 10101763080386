import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { CustomersListPage } from '../../pages/CustomersListPage';
import { ProtectedScreen } from '../../components/ui/ProtectedScreen';

const CustomersListScreen = () => {
  const navigate = useNavigate();

  const navigateToCustomersImport = useCallback(() => {
    navigate('/customers/import');
  }, [navigate]);

  return (
    <ProtectedScreen>
      <CustomersListPage navigateToCustomersImport={navigateToCustomersImport} />
    </ProtectedScreen>
  );
};

export { CustomersListScreen };
