/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ChangeEvent,
  memo,
  ReactNode,
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import {
  Accordion, LoadingOverlay, Textarea, Tooltip,
} from '@mantine/core';
import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import { useOrderContext } from '../../../contexts/useOrderContext';
import { groupFields } from '../../../helpers/prompt';
import { useSchemasContext } from '../../../contexts/useSchemasContext';
import { GroupedFields } from '../fields';
import { Button } from '../../ui/Button';
import { FieldSpec, Schema } from '../../../models/Schema';
import { CustomerSelect } from '../../order/order-draft/OrderDraftPanel/OrderDraftPanelBody/HeaderSection/CustomerSelect';
import { setValueByPath } from '../../../helpers/schema';
import { Order, ProductWithQuantity } from '../../../models/Order';

interface Props {
  isInnerLoading: boolean;
}

const ButtonSection = memo(({
  TooltipLabel,
  hideTooltip,
  groupKey,
  isGroupKeyIncluded,
  onMarkAsCheckedButtonClick,
}: {
  TooltipLabel: ReactNode,
  hideTooltip: boolean,
  groupKey: string,
  isGroupKeyIncluded: boolean,
  onMarkAsCheckedButtonClick: (key: string) => void;
}) => (
  <div className="pt-4 flex justify-end">
    <Tooltip
      label={TooltipLabel}
      hidden={hideTooltip}
    >
      <Button
        title={`Mark as ${isGroupKeyIncluded ? 'unchecked' : 'checked'}`}
        onClick={() => onMarkAsCheckedButtonClick(groupKey)}
        disabled={!hideTooltip}
      />
    </Tooltip>
  </div>
));

const IconWithTooltip = memo(({
  hideTooltip,
  isGroupKeyIncluded,
  TooltipLabel,
}: {
  hideTooltip: boolean,
  isGroupKeyIncluded: boolean,
  TooltipLabel: ReactNode,
}) => {
  const Icon = useMemo(() => {
    if (!hideTooltip) {
      return <ExclamationTriangleIcon className="w-5 h-5 text-warning-500" />;
    }

    if (isGroupKeyIncluded) {
      return <CheckIcon className="w-5 h-5 text-success-700" />;
    }

    return null;
  }, [hideTooltip, isGroupKeyIncluded]);
  return (
    <Tooltip
      label={TooltipLabel}
      hidden={hideTooltip}
    >
      <div>
        {Icon}
      </div>
    </Tooltip>
  );
});

const Item = memo(({
  groupKey,
  confirmedFields,
  groupedFields,
  selectedOrder,
  schemas,
  typeRef,
  isInnerLoading,
  showItem,
  setAccordionValue,
  onMarkAsCheckedButtonClick,
  updateValueByPath,
  updateProductByIndex,
  addNewProduct,
  onCommentChange,
}: {
  groupKey: string;
  confirmedFields: string[];
  showItem: boolean;
  groupedFields: Record<string, FieldSpec[]>;
  selectedOrder: Order;
  schemas: Record<string, Schema>;
  typeRef: string;
  isInnerLoading: boolean;
  setAccordionValue: React.Dispatch<React.SetStateAction<string[]>>;
  onMarkAsCheckedButtonClick: (key: string) => void;
  updateValueByPath: (value: any, path: string, indices?: number[]) => void;
  updateProductByIndex: (index: number, values: any) => void;
  addNewProduct: (product: ProductWithQuantity) => void;
  onCommentChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}) => {
  const [errors, setErrors] = useState<Record<string, string>>({});

  const TooltipLabel = useMemo(() => (
    <ul className="list-disc px-3">
      {
        Object.values(errors || {}).reduce(
          (acc, value) => (value ? [...acc, value] : acc), [])
          .map((err) => (
            <li key={err}>{err}</li>
          ))
      }
    </ul>
  ), [errors]);
  const hideTooltip = useMemo(() => !Object.values(errors || {})?.some((error) => error), [errors]);

  const isGroupKeyIncluded = useMemo(() => confirmedFields.includes(groupKey), [confirmedFields, groupKey]);

  const CommentField = useMemo(() => (groupKey === 'Standard fields' ? (
    <Textarea
      placeholder="Add some comment if needed..."
      label="Comment"
      minRows={2}
      maxRows={4}
      autosize
      autoComplete="off"
      className="pt-4"
      disabled={isInnerLoading}
      value={selectedOrder?.draft?.comment || ''}
      onChange={onCommentChange}
    />
  ) : null), [groupKey, isInnerLoading, onCommentChange, selectedOrder?.draft?.comment]);

  const setError = useCallback((key: string, error: string) => {
    setErrors((prev) => {
      // update only if the error is different
      if (prev[key] !== error) {
        return ({ ...prev, [key]: error });
      }

      return prev;
    });
  }, [setErrors]);

  const onClick = useCallback(() => {
    setAccordionValue((prev) => {
      if (prev.includes(groupKey)) {
        return prev.filter((item) => item !== groupKey);
      }

      return [...prev, groupKey];
    });
  }, [groupKey, setAccordionValue]);

  return (
    <Accordion.Item value={groupKey}>
      <Accordion.Control
        icon={(
          <IconWithTooltip
            hideTooltip={hideTooltip}
            isGroupKeyIncluded={isGroupKeyIncluded}
            TooltipLabel={TooltipLabel}
          />
        )}
        onClick={onClick}
      >
        <p className={`${isGroupKeyIncluded ? 'text-success-700' : ''} text-lg -semibold`}>
          {groupKey}
        </p>
      </Accordion.Control>
      <Accordion.Panel>
        {
          showItem && (
            <>
              <GroupedFields
                fieldSpecs={groupedFields[groupKey]}
                obj={selectedOrder}
                schema={schemas[typeRef]}
                updateValueByPath={updateValueByPath}
                updateProductByIndex={updateProductByIndex}
                addNewProduct={addNewProduct}
                setError={setError}
              />

              {CommentField}

              <ButtonSection
                TooltipLabel={TooltipLabel}
                hideTooltip={hideTooltip}
                groupKey={groupKey}
                isGroupKeyIncluded={isGroupKeyIncluded}
                onMarkAsCheckedButtonClick={onMarkAsCheckedButtonClick}
              />
            </>
          )
        }
      </Accordion.Panel>
    </Accordion.Item>
  );
});

const OrderDraftRender = ({
  isInnerLoading,
}: Props) => {
  const { groupOrders, setGroupOrders, selectedIndex } = useOrderContext();
  const { schemas } = useSchemasContext();

  const selectedOrder = useMemo(() => {
    const _order = groupOrders[selectedIndex];
    return {
      ..._order,
      products: (_order?.products || []).map((product) => ({ ...product })),
    };
  }, [groupOrders, selectedIndex]);

  const [typeRef, setTypeRef] = useState<string>('');
  const [groupedFields, setGroupedFields] = useState<Record<string, FieldSpec[]>>({});
  const [confirmedFields, setConfirmedFields] = useState<string[]>([]);
  const [accordionValue, setAccordionValue] = useState<string[]>([]);

  const updateValueByPath = useCallback((value: any, path: string, indices: number[] = []) => {
    setGroupOrders((_groupOrders) => _groupOrders.map((_order, i) => {
      if (i === selectedIndex) {
        const newOrder = setValueByPath(_order, value, path, indices);
        return {
          ...newOrder,
          // dirty way to force re-render of the product field
          products: (newOrder.products || []).map((product: ProductWithQuantity) => ({ ...product })),
          didChangeMade: true,
        };
      }
      return _order;
    }));
  }, [selectedIndex, setGroupOrders]);

  const addNewProduct = useCallback((product: ProductWithQuantity) => {
    setGroupOrders((_groupOrders) => _groupOrders.map((_order, i) => (i === selectedIndex
      ? { ..._order, products: [product, ...(_order.products || [])] }
      : _order),
    ),
    );
  }, [selectedIndex, setGroupOrders]);

  const updateProductByIndex = useCallback((index: number, values: any) => {
    setGroupOrders((_groupOrders) => _groupOrders.map((_order, i) => (i === selectedIndex
      ? {
        ..._order,
        products: (_order.products || []).map((_product, j) => (j === index
          ? {
            ..._product,
            ...values,
          }
          : _product),
        ),
      }
      : _order),
    ),
    );
  }, [selectedIndex, setGroupOrders]);

  const onCommentChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setGroupOrders((_groupOrders) => _groupOrders.map((_order, i) => (i === selectedIndex
      ? {
        ..._order,
        draft: { ..._order.draft, comment: event.target.value },
        didChangeMade: true,
      }
      : _order),
    ),
    );
  }, [selectedIndex, setGroupOrders]);

  const onMarkAsCheckedButtonClick = useCallback((key: string) => {
    setConfirmedFields((prev) => {
      if (prev.includes(key)) {
        return prev.filter((item) => item !== key);
      }

      setAccordionValue((_prev) => _prev.filter((item) => item !== key));
      const keyIdx = Object.keys(groupedFields).indexOf(key);
      if (keyIdx + 1 < Object.keys(groupedFields).length
        && !confirmedFields.includes(Object.keys(groupedFields)[keyIdx + 1])) {
        setAccordionValue((_prev) => [..._prev, Object.keys(groupedFields)[keyIdx + 1]]);
      }

      return [...prev, key];
    });
  }, [confirmedFields, groupedFields, setAccordionValue]);

  useEffect(() => {
    setAccordionValue([]);
    setConfirmedFields([]);
  }, [selectedOrder?.id]);

  useEffect(() => {
    const _typeRef = selectedOrder?.typeSpecs?.[0]?.typeRef;

    setTypeRef(_typeRef);

    const _groupedFields = groupFields(selectedOrder?.typeSpecs?.[0]?.fields, schemas[_typeRef]);

    setGroupedFields(_groupedFields);

    setAccordionValue((prev) => {
      if (prev.length > 0) return prev;

      const firstKey = Object.keys(_groupedFields)[0];

      if (!firstKey) return [];

      return [firstKey];
    });
  }, [schemas, selectedOrder?.typeSpecs]);

  if (isInnerLoading) {
    return (
      <LoadingOverlay
        visible
        loaderProps={{ type: 'dots' }}
        overlayProps={{ blur: 2 }}
      />
    );
  }

  if (Object.keys(groupedFields).length === 0) {
    return (
      <div>
        <div className="pb-xl">
          <h1 className="pb-sm text-title-sm font-semibold">
            Customer selection required
          </h1>
          <div className="text-label-caption-md text-description">
            Please select a customer to create a new order.
            The corresponding prompt will be automatically selected, and the processing view will open.
          </div>
        </div>
        <CustomerSelect />
      </div>
    );
  }

  return (
    <Accordion
      multiple
      transitionDuration={300}
      value={accordionValue}
    >
      {
          Object.keys(groupedFields).map((key) => (
            <Item
              key={key}
              groupKey={key}
              confirmedFields={confirmedFields}
              showItem={accordionValue.includes(key)}
              groupedFields={groupedFields}
              selectedOrder={selectedOrder}
              schemas={schemas}
              typeRef={typeRef}
              isInnerLoading={isInnerLoading}
              setAccordionValue={setAccordionValue}
              onMarkAsCheckedButtonClick={onMarkAsCheckedButtonClick}
              updateValueByPath={updateValueByPath}
              updateProductByIndex={updateProductByIndex}
              addNewProduct={addNewProduct}
              onCommentChange={onCommentChange}
            />
          ))
      }
    </Accordion>
  );
};

export default OrderDraftRender;
