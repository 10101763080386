import { createContext, useContext } from 'react';

enum DetailPanelContentType {
  USER_INFO = 'user info',
  ORDER_DRAFT_PROCESSING = 'order draft processing', // for order draft processing mode
  NEW_ORDER_DRAFT_PROCESSING = 'new order draft processing', // for order draft creation mode
  ORDER_DRAFT_CHAT = 'order draft chat', // for detail panel in chat interface
  ORDER_DETAIL = 'order detail',
}

type DetailPanelContextType = {
  detailPanelContent: DetailPanelContentType; // Might add "collapsed" in the future.,
  setDetailPanelContent: React.Dispatch<
  React.SetStateAction<DetailPanelContentType>
  >;
};

const DetailPanelContentContext = createContext<
DetailPanelContextType | undefined
>(undefined);

const useDetailPanelContent = () => {
  const context = useContext(DetailPanelContentContext);

  if (context === undefined) {
    throw new Error('Must be wrapped by DetailPanelContentContext provider.');
  }

  return context;
};

export {
  DetailPanelContentContext,
  useDetailPanelContent,
  DetailPanelContentType,
};
