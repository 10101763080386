import Dayjs from 'dayjs';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { observer } from 'mobx-react-lite';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';

import { BusinessType } from '../models/Business';
import { Button } from '../components/ui/Button';
import { ChatActions } from '../components/ChatActions';
import { ChatMessage } from '../components/ChatMessage';
import { Currency } from '../models/Product';
import { FieldDisplay } from '../components/FieldDisplay';
import { HStack } from '../components/ui/HStack';
import { ListNavigator } from '../components/common/ListNavigator';
import { MessageRefType, MessageSource, MessageType } from '../models/Message';
import { OrderStatus } from '../models/Order';
import { Page } from '../components/layout/Page/Page';
import { ProductOrderPreview } from '../components/ProductOrderPreview';
import { ProductsTable } from '../components/ProductsTable';
import { Spacer } from '../components/ui/Spacer';
import { ThreeDots } from '../components/ThreeDots';
import { formatOrderStatus } from '../helpers/enums';

const styles = {
  productsWrapper: {
    gap: 5,
    paddingTop: 15,
    paddingBottom: 5,
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  badgeWrapper: {
    display: 'flex',
    gap: 5,
  },
  statusWrapper: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    gap: 10,
  },
  statusDot: {
    width: 16,
    height: 16,
    backgroundColor: '#AAE5BB',
    borderRadius: 50,
  },
  totalWrapper: {
    borderTopColor: '#E8E8E8',
    borderTopWidth: 1,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'flex-end',
    padding: 5,
    marginTop: 5,
  },
  detailsWrapper: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap: 15,
  },
  statusTrailWrapper: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    borderRadius: 5,
    gap: 15,
    marginTop: 15,
    backgroundColor: 'rgb(248, 250, 252)',
    color: '#546E7A',
  },
};

const MockedAssistanceRequstPage = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [isBotThinking, setIsBotThinking] = useState(true);

  const [chatText, setChatText] = useState('');
  const [currentIndex] = useState(0);
  const scrollableListRef = useRef<HTMLInputElement>();

  const messages = useMemo<string[][]>(
    () => [
      [
        'Guten Tag\n',
        'Für nächsten Dienstag würden wir gerne exotische Tomaten aus Madagascar bestellen. Hätten Sie diese ab lager?\n',
        'Vielen Dank für Ihre schnelle Rückmeldung',
        'Johannes',
      ],
    ],
    [],
  );

  useEffect(() => {
    setTimeout(() => {
      setIsBotThinking(false);
    }, 1300);
    setIsLoading(false);
  }, [setIsBotThinking]);

  return (
    <Page isLoading={isLoading} isBeta>
      <div className="flex-1">
        <ListNavigator
          listLength={1}
          currentIndex={currentIndex}
          onPrev={() => {}}
          onNext={() => {}}
        />
        <HStack className="items-start">
          <Spacer />
          <div
            style={{ height: 'calc(100vh - 96px)' }}
            className="flex w-full flex-col justify-between grow items-center"
          >
            <div
              ref={scrollableListRef}
              className="flex h-full w-full flex-col gap-y-3xl overflow-y-auto pb-12 pr-smd pt-4"
            >
              <div>
                <div className="mb-1 ml-1 text-sm font-semibold text-hoshii">
                  Hoshii Restaurant
                </div>
                <ChatMessage
                  message={{
                    businessSentBy: '',
                    businessSentTo: '',
                    chatId: '',
                    chatGroupId: '',
                    createdAt: '',
                    originalCreatedAt: '',
                    id: '',
                    message: messages[currentIndex].join('\n'),
                    messageType: MessageType.EMAIL,
                    source: MessageSource.INTERNAL,
                    ref: '',
                    refType: MessageRefType.ORDER,
                    readAt: '',
                    userSentBy: '',
                    userSentTo: '',
                    intents: [],
                    context: {
                      attachments: [],
                    },
                  }}
                  direction="RECEIVED"
                  onMessageMoved={() => {}}
                  onKeywordMouseOver={() => {}}
                  onKeywordMouseLeave={() => {}}
                />
              </div>
            </div>
            <ChatActions
              value={chatText}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setChatText(e.target.value);
              }}
              onSendButtonClick={() => {}}
              bottom={0}
              disabled
            />
          </div>
          <Spacer />
          <div
            style={{
              minWidth: 650,
              maxWidth: 650,
              display: 'flex',
              paddingBottom: 0,
              margin: 5,
              overflow: 'hidden',
              overflowX: 'hidden' as 'hidden',
              overflowY: 'auto' as 'auto',
              backgroundColor: 'white',
              height: 'calc(100vh - 100px)',
            }}
            className="flex-col justify-between overflow-hidden rounded-lg border border-solid p-lg"
          >
            {isBotThinking ? (
              <div className="flex h-full w-full flex-col items-center justify-center">
                Hoshii Bot is thinking...
                <ThreeDots className="mt-4" />
              </div>
            ) : (
              <>
                <div className="mb-4 flex items-center">
                  <span className="flex aspect-square w-10 items-center justify-center rounded-full bg-[#539af7] text-[20px] font-bold text-white">
                    h
                  </span>
                  <div className="flex items-center justify-start">
                    <div className="w-3 overflow-hidden">
                      <div className="h-4 origin-bottom-right rotate-45 transform rounded-sm bg-blue-200 bg-opacity-65" />
                    </div>
                    <p className="flex-1 rounded-lg bg-blue-200 bg-opacity-65 p-lg">
                      I&apos;ve found some information from your products that
                      could be helpful for you!
                    </p>
                  </div>
                </div>
                {currentIndex === 0 ? (
                  <div className="h-full">
                    <ProductsTable
                      products={[
                        {
                          id: 'dfa213',
                          idOrSku: '234123',
                          minOrderQty: 0,
                          name: 'Madagascar Tomato',
                          price: { amount: 10, currency: Currency.Chf },
                          category: 'Tomato',
                          provenanceCode: 'CH',
                          supplier: {
                            id: '',
                            externalId: '',
                            name: '',
                            phones: [],
                            email: '',
                            address: '',
                            type: BusinessType.Supplier,
                            contactUsers: [],
                            createdAt: '',
                            createdBy: '',
                            external: true,
                            updatedAt: '',
                            emails: [],
                            oauthBindings: new Map(),
                          },
                          supplierId: '',
                          baseUnit: {
                            symbol: 'Kg',
                            baseConversionFactor: 1,
                          },
                          salesUnits: [
                            {
                              symbol: 'Kg',
                              baseConversionFactor: 1,
                            },
                          ],
                          units: ['Kg', 'Ifco'],
                          createdBy: '',
                          createdAt: '',
                          updatedAt: '',
                          disabled: false,
                        },
                      ]}
                      openDialog={() => {}}
                      isLoading={false}
                      onScrolledEnd={() => {}}
                    />
                  </div>
                ) : (
                  <>
                    <div className="flex max-w-[600px] items-center space-x-2">
                      <span className="flex aspect-square w-10 items-center justify-center rounded-full bg-blue-300 text-[20px] font-bold text-white">
                        h
                      </span>
                      <div className="flex items-center justify-start">
                        <div className="w-3 overflow-hidden">
                          <div className="h-4 origin-bottom-right rotate-45 transform rounded-sm bg-blue-200 bg-opacity-65" />
                        </div>
                        <p className="my-6 flex-1 rounded-lg bg-blue-200 bg-opacity-65 p-lg">
                          I&apos;ve found some information that could be helpful
                          for you!
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 flex w-full max-w-[600px] flex-col gap-lg">
                      <div style={styles.detailsWrapper}>
                        <FieldDisplay label="Customer">
                          <div>Hoshii Supplier</div>
                        </FieldDisplay>

                        <FieldDisplay label="Created at">
                          <div>
                            {Dayjs('2024-04-08T21:59:55.643Z').format('LL')}
                          </div>
                        </FieldDisplay>

                        <FieldDisplay label="Requested delivery">
                          <div>
                            {Dayjs('2024-04-12T10:00:00.643Z').format('llll')}
                          </div>
                        </FieldDisplay>

                        <FieldDisplay label="Comment">
                          <div className="flex items-center space-x-3">
                            <ExclamationCircleIcon
                              className="aspect-square w-8"
                              style={{ stroke: 'rgb(240, 46, 46)' }}
                            />
                            <div>
                              Swiss Tomato was out of stock. We need to send
                              Madagascar one.
                            </div>
                          </div>
                        </FieldDisplay>
                      </div>

                      <FieldDisplay label="Products">
                        <div style={styles.productsWrapper}>
                          <ProductOrderPreview
                            id=""
                            name="Swiss Tomato"
                            idOrSku="233113"
                            unit="Kg"
                            price={20}
                            currency="CHF"
                            quantity={20}
                          />
                        </div>
                        <div style={styles.totalWrapper}>
                          <div className="self-end">20 CHF</div>
                        </div>
                      </FieldDisplay>

                      <div style={styles.detailsWrapper}>
                        <FieldDisplay label="Events">
                          <div
                            style={styles.statusTrailWrapper}
                            className="p-lg"
                          >
                            <div
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div>{`Order ${formatOrderStatus(OrderStatus.New)}`}</div>
                              <div>
                                {Dayjs('2024-04-08T21:59:55.643Z').format(
                                  'lll',
                                )}
                              </div>
                            </div>
                          </div>

                          <div
                            style={styles.statusTrailWrapper}
                            className="p-lg"
                          >
                            <div
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div>{`Order ${formatOrderStatus(OrderStatus.Confirmed)}`}</div>
                              <div>
                                {Dayjs('2024-04-08T23:01:55.643Z').format(
                                  'lll',
                                )}
                              </div>
                            </div>
                          </div>
                        </FieldDisplay>
                      </div>
                    </div>
                  </>
                )}

                <div className="sticky bottom-0 mt-4 flex items-center justify-end border-t border-solid bg-white pb-4 pt-4">
                  <Button title="Resolve" onClick={() => {}} />
                </div>
              </>
            )}
          </div>
        </HStack>
      </div>
    </Page>
  );
});

export { MockedAssistanceRequstPage };
