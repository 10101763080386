import {
  getFieldByPath, getValueByPath, inputTypeToUniversalFieldType,
} from '../../../../helpers/schema';
import { FieldSpec, Schema } from '../../../../models/Schema';
import { UniversalField } from '../../../common/UniversalField';
import { CustomerSelect } from '../../../order/order-draft/OrderDraftPanel/OrderDraftPanelBody/HeaderSection/CustomerSelect';

interface Props {
  fieldSpecs: FieldSpec[];
  updateValueByPath: (value: any, path: string, indices?: number[]) => void;
  setError?: (key: string, error: string) => void;
  obj: any;
  schema: Schema;
}

const CustomerFields = ({
  fieldSpecs, updateValueByPath, setError, obj, schema,
}: Props) => (
  <div className="space-y-4">
    <CustomerSelect setError={(error) => setError?.('customer', error)} />
    {fieldSpecs.map((fieldSpec) => {
      const field = getFieldByPath(schema?.fields, fieldSpec.path);
      return (
        fieldSpec.modelPath.split('.')[1] === '*'
          ? (
            // TODO
            <div key={fieldSpec.path}>
              {getValueByPath(obj, fieldSpec.modelPath.split('.')[0]).map((value: any) => (
                <div key={value.uiId}>
                  <pre>{JSON.stringify(value, null, 2)}</pre>
                </div>
              ))}
            </div>
          )
          : (
            <UniversalField
              key={fieldSpec.path}
              type={inputTypeToUniversalFieldType(field?.inputType, field?.type)}
              validation={fieldSpec.validation}
              label={fieldSpec.name}
              value={getValueByPath(obj, fieldSpec.modelPath)}
              shouldScroll={false}
              setError={(error) => setError?.(fieldSpec.path, error)}
              onValueChange={(value) => updateValueByPath(value, fieldSpec.modelPath)}
            />
          )
      );
    })}
  </div>
);

export default CustomerFields;
