import { useMemo } from 'react';

interface Props {
  isVisible: boolean;
  type: 'STANDARD' | 'READY' | 'WARNING' | 'ALERT';
}

const Blinker = ({ isVisible, type }: Props) => {
  const blinkerColor: string = useMemo(() => {
    switch (type) {
      case 'WARNING':
        return 'bg-[#f7a043]';
      case 'ALERT':
        return 'bg-[#f7564a]';
      case 'READY':
        return 'bg-[#74e594]';
      default:
        return 'bg-primary';
    }
  }, [type]);

  return isVisible ? (
    <span className="relative ml-2 flex h-3 w-3 items-center">
      <span
        className={`absolute inline-flex h-full w-full animate-ping rounded-full ${blinkerColor} opacity-75`}
      />
      <span
        className={`relative inline-flex h-3 w-3 rounded-full ${blinkerColor}`}
      />
    </span>
  ) : null;
};

export { Blinker };
