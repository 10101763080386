import { useState, useCallback } from 'react';

import { httpPost } from '../helpers/xhr';
import { genericErrorFeedback } from '../helpers/errors';
import { globalAlertData } from '../state/globalAlertData';

const useConfirmOrderDraftsByGroupId = () => {
  const [isLoading, setIsLoading] = useState(false);

  const confirmOrderDraftsByGroupId = useCallback(
    (id: string): Promise<void> => {
      setIsLoading(true);
      return httpPost(`/orders/drafts/group/${id}/confirm`, { id })
        .then(() => {
          globalAlertData.create('Order confirmed successfully');
        })
        .catch((error) => {
          genericErrorFeedback('Error confirming order draft')(error);
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return { isLoading, confirmOrderDraftsByGroupId };
};

export { useConfirmOrderDraftsByGroupId };
