import { Formik } from 'formik';
import { Select } from '@mantine/core';
import { object, string } from 'yup';
import { useEffect, useRef, useState } from 'react';

import { Button } from '../components/ui/Button';
import { FlashCard } from '../components/FlashCard';
import { FormInput } from '../components/FormInput';
import { Page } from '../components/layout/Page/Page';
import { UserRole } from '../models/User';
import { genericErrorFeedback } from '../helpers/errors';
import { httpPost } from '../helpers/xhr';

const formInitialValues = {
  email: '',
  role: '',
};

const formValidationSchema = object({
  email: string().required(),
  role: string().required(),
});

interface Props {
  navigateToTeamMembersList: () => void;
}

const TeamMemberInvitePage = ({ navigateToTeamMembersList }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (buttonRef.current && event.key === 'Enter') {
        buttonRef.current.click();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Page isLoading={isLoading}>
      <div className="flex-1">
        <div
          className="m-4 flex w-96 flex-col gap-lg overflow-y-auto rounded-lg border border-solid bg-white p-lg pb-0"
          style={{ maxHeight: 'calc(100vh - 90px)' }}
        >
          <FlashCard
            title="Invite team member"
            description="An invite email will be sent to this email address with simple instructions to create the user account."
          />

          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={(values) => {
              setIsLoading(true);
              const roles = [values.role];
              if (values.role === UserRole.SupplierAdmin) {
                roles.push(UserRole.SupplierUser);
              }
              httpPost('/users/invite', {
                email: values.email,
                roles,
              })
                .then(() => {
                  navigateToTeamMembersList();
                })
                .catch(genericErrorFeedback('Error inviting user'))
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              touched,
            }) => (
              <div className="flex flex-col gap-smd">
                <FormInput
                  field="email"
                  disabled={isLoading}
                  label="Member email"
                  value={values.email}
                  error={errors.email}
                  touched={touched.email}
                  required
                  onChange={handleChange('email')}
                  onBlur={handleBlur('email')}
                />
                <Select
                  value={values.role || ''}
                  data={[
                    { label: 'User', value: UserRole.SupplierUser },
                    { label: 'Admin', value: UserRole.SupplierAdmin },
                  ]}
                  onChange={handleChange('role')}
                  onBlur={handleBlur('role')}
                  label="Role"
                  clearable
                  required
                />

                <div className="sticky bottom-0 z-10 mt-4 w-full border-t border-solid bg-white pb-4 pt-4">
                  <Button
                    ref={buttonRef}
                    title="Send invite"
                    disabled={isLoading}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </Page>
  );
};

export { TeamMemberInvitePage };
