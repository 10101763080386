import { createContext, useContext } from 'react';

// For order drafts. To highlight on hover or scroll on click keyword.
type KeywordContextType = {
  hoverKeyword: string;
  setHoverKeyword: React.Dispatch<React.SetStateAction<string>>;
  scrollKeyword: string;
  setScrollKeyword: React.Dispatch<React.SetStateAction<string>>;
};

const KeywordContext = createContext<KeywordContextType | undefined>(undefined);

const useKeywordContext = () => {
  const context = useContext(KeywordContext);

  if (context === undefined) {
    throw new Error('Must be wrapped by KeywordContext provider.');
  }

  return context;
};

export { KeywordContext, useKeywordContext };
