import {
  action, makeObservable, observable, runInAction,
} from 'mobx';

import { debounce } from 'lodash';
import { GetDocumentsQueryType } from '../../models/GetDocumentsQueryType';
import { globalSseSources } from '../globalSseSources';
import { httpGet } from '../../helpers/xhr';

class OrderDrafts {
  count: number = 0;

  isLoading: boolean = false;

  isLoaded: boolean = false;

  _userId: string;

  constructor() {
    makeObservable(this, {
      count: observable,
      isLoading: observable,
      loadOrderDraftsCount: action,
      _onNewOrderSseReceived: action,
    });
  }

  loadOrderDraftsCount = async () => {
    runInAction(() => {
      this.isLoading = true;
    });
    const response = await httpGet('/orders/drafts', {
      params: {
        assignee_id: this._userId,
        include_unassigned: true,
        query_type: GetDocumentsQueryType.count,
      },
    });

    runInAction(() => {
      this.count = response.data.count;
      this.isLoading = false;
      this.isLoaded = true;
    });
  };

  _debouncedLoadOrderDraftsCount = debounce(this.loadOrderDraftsCount, 2000);

  processOrder = () => {
    runInAction(() => {
      this.count -= 1;
    });
  };

  setUserId = (id: string) => {
    this._userId = id;
  };

  // eslint-disable-next-line class-methods-use-this
  removeSseSourcesHandler = () => {
    globalSseSources.removeSourcesHandler('orders_drafts/new_order');
  };

  _onNewOrderSseReceived = () => {
    this._debouncedLoadOrderDraftsCount();
  };

  addSseSourcesHandler = () => {
    globalSseSources.addSourcesHandler(
      'orders_drafts/new_order',
      ['new_order'],
      this._onNewOrderSseReceived,
    );
  };
}

export { OrderDrafts };
