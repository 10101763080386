import { memo, useCallback, useMemo } from 'react';
import { Tabs } from '@mantine/core';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { useDragDropContext } from '../../../../../../../contexts/useDragDropContext';

import { useOrderContext } from '../../../../../../../contexts/useOrderContext';
import { globalAlertData } from '../../../../../../../state/globalAlertData';
import { Order } from '../../../../../../../models/Order';
import { globalUser } from '../../../../../../../state/globalUser';

const Tab = memo(({
  index,
  groupOrder,
  onDragOver,
  onDeleteButtonClick: _onDeleteButtonClick,
  onDrop: _onDrop,
}: {
  index: number;
  groupOrder: Order;
  onDragOver: (e: React.DragEvent<HTMLButtonElement>) => void;
  onDeleteButtonClick: (index: number, orderId: string) => void;
  onDrop: (index: number) => void;
}) => {
  const onDrop = useCallback(() => {
    _onDrop(index);
  }, [_onDrop, index]);

  const onDeleteButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    _onDeleteButtonClick(index, groupOrder.id);
  }, [index, _onDeleteButtonClick, groupOrder.id]);

  const onKeyDown = useCallback(() => {}, []);

  return (
    <Tabs.Tab
      value={index.toString()}
      onDragOver={onDragOver}
      onDrop={onDrop}
      className="group"
    >
      <div className="flex items-center">
        {groupOrder.customer?.name
            || `New${index > 0 ? `-${index}` : ''}`}
        <div
          role="button"
          tabIndex={0}
          className={`${index === 0 ? 'hidden' : ''}`}
          onClick={onDeleteButtonClick}
          onKeyDown={onKeyDown}
        >
          <XMarkIcon className="invisible ml-2 h-3 w-3 group-hover:visible" />
        </div>
      </div>
    </Tabs.Tab>
  );
});

const _Tabs = memo(() => {
  const {
    draggedItemId, dragging, setDraggedItemId, setDragging,
  } = useDragDropContext();

  const {
    groupOrders,
    setGroupOrders,
    selectedIndex: tabIndex,
    setSelectedIndex: setTabIndex,
    setDeletedOrderIds,
  } = useOrderContext();

  const totalOrders = useMemo(() => groupOrders.length, [groupOrders]);
  const currentOrder = useMemo(() => groupOrders[tabIndex], [groupOrders, tabIndex]);

  const onChange = useCallback((value: string) => setTabIndex(Number(value)), [setTabIndex]);

  const onDragOver = useCallback((e: React.DragEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (index: number) => {
      if (index === tabIndex || !dragging) {
        return;
      }

      // Get the dragged product
      const draggedProduct = currentOrder.products.find(
        (_product) => _product.uiId === draggedItemId,
      );

      if (!draggedProduct) {
        globalAlertData.create(
          'Product not found',
          'The product was not found in the base order',
          'warning',
        );
        setDragging(false);
        setDraggedItemId(null);
      } else {
        setGroupOrders((_groupOrders) => _groupOrders.map((_order, i) => {
          // Remove the product from the current order
          if (i === tabIndex) {
            return {
              ..._order,
              products: _order.products.filter(
                (_product) => _product.uiId !== draggedItemId,
              ),
            };
          }

          // Add the product to the target order
          if (i === index) {
            return {
              ..._order,
              products: [...(_order.products || []), draggedProduct],
            };
          }

          return _order;
        }),
        );
      }

      setGroupOrders((_groupOrders) => _groupOrders.map((_order, i) => {
        if (i === index || i === tabIndex) {
          return { ..._order, didChangeMade: true };
        }
        return _order;
      }),
      );

      setDragging(false);
      setDraggedItemId(null);
    },
    [tabIndex, dragging, currentOrder?.products, setGroupOrders, setDragging, setDraggedItemId, draggedItemId],
  );

  const onDeleteButtonClick = useCallback(
    (index: number, orderId: string) => {
      if (orderId && !orderId.startsWith('new-')) {
        setDeletedOrderIds((_deletedOrderIds) => [
          ..._deletedOrderIds,
          orderId,
        ]);
      }

      setGroupOrders((_groupOrders) => _groupOrders.filter((_, i) => i !== index),
      );

      if (index < totalOrders - 1) {
        setTabIndex(index);
      } else {
        setTabIndex(index - 1);
      }
    },
    [setDeletedOrderIds, setGroupOrders, setTabIndex, totalOrders],
  );

  const onAddButtonClick = useCallback(() => {
    const newOrder = new Order();
    newOrder.id = `new-${totalOrders}`;
    newOrder.assigneeId = globalUser.id;
    newOrder.isCreated = true;
    newOrder.didChangeMade = true;
    setGroupOrders((_groupOrders) => [..._groupOrders, newOrder]);
    setTabIndex(totalOrders);
  }, [totalOrders, setGroupOrders, setTabIndex]);

  return (
    <Tabs
      value={tabIndex.toString()}
      onChange={onChange}
    >
      <Tabs.List>
        {groupOrders.map((groupOrder, index) => (
          <Tab
            key={groupOrder.id}
            index={index}
            groupOrder={groupOrder}
            onDragOver={onDragOver}
            onDeleteButtonClick={onDeleteButtonClick}
            onDrop={onDrop}
          />
        ))}
        <Tabs.Tab value="addOrder" onClick={onAddButtonClick}>
          <PlusIcon className="h-5 w-5" />
        </Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
});

export default _Tabs;
