import {
  useState, useCallback, useRef, useEffect,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import { httpGet } from '../../helpers/xhr';
import { Order, ProductWithQuantity } from '../../models/Order';
import { genericErrorFeedback } from '../../helpers/errors';
import { globalUser } from '../../state/globalUser';
import { GetDocumentsQueryType } from '../../models/GetDocumentsQueryType';
import { isZeroId } from '../../helpers/objectId';

interface FetchOrderDraftsProps {
  preventInitialFetch?: boolean;
}

const useFetchOrderDrafts = ({
  preventInitialFetch = false,
}: FetchOrderDraftsProps) => {
  const [orderDrafts, setOrderDrafts] = useState<Order[]>([]);
  const [isLoading, setIsLoading] = useState(!preventInitialFetch);

  const dataPaginationCursor = useRef();

  const resetParams = useCallback(() => {
    setOrderDrafts([]);
    dataPaginationCursor.current = null;
  }, []);

  const loadOrderDrafts = useCallback(
    async (
      include_unassigned: boolean = true,
      giveErrorFeedback: boolean = true,
      groupId?: string,
    ): Promise<Order[]> => {
      setIsLoading(true);

      const isGroupNull = !groupId || isZeroId(groupId);

      return httpGet('/orders/drafts', {
        params: {
          assignee_id: globalUser.id,
          include_unassigned,
          group_id: !isGroupNull ? groupId : null,
          cursor: dataPaginationCursor.current,
          query_type: GetDocumentsQueryType.data,
          limit: isGroupNull ? null : 5,
        },
      })
        .then((response) => {
          const resultDrafts: Order[] = (response.data.result || []).map((draft: Order) => ({
            ...draft,
            products: draft.products.map((product: ProductWithQuantity) => ({
              ...product,
              uiId: uuidv4(),
            })),
          }));

          if (isGroupNull) {
            setOrderDrafts((_orderDrafts) => {
              const newDrafts = [
                ..._orderDrafts,
                ...resultDrafts,
              ];

              // Ensure uniqueness of orderIds
              // TODO(ntauth): @chihirokuya why this?
              const uniqueDrafts = newDrafts.filter(
                (draft, index, self) => index === self.findIndex((d) => d.id === draft.id),
              );
              return uniqueDrafts;
            });
            dataPaginationCursor.current = response.data.cursor;
          }

          return resultDrafts;
        })
        .catch((error) => {
          if (giveErrorFeedback) genericErrorFeedback('Error loading orders drafts')(error);
          return Promise.reject(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [],
  );

  const reloadOrderDrafts = useCallback(() => {
    // Initialise messages
    resetParams();
    return loadOrderDrafts();
  }, [resetParams, loadOrderDrafts]);

  useEffect(() => {
    if (preventInitialFetch) return () => {};

    const controller = new AbortController();

    reloadOrderDrafts();

    return () => {
      controller.abort(); // Abort the fetch when chatId changes or component unmounts
    };
  }, [reloadOrderDrafts, preventInitialFetch]);

  return {
    orderDrafts,
    setOrderDrafts,
    isLoading,
    loadOrderDrafts,
    reloadOrderDrafts,
  };
};

export { useFetchOrderDrafts };
