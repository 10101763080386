import { ProtectedScreen } from '../../components/ui/ProtectedScreen';
import InstructionsPage from '../../pages/InstructionsPage';

const InstructionsScreen = () => (
  <ProtectedScreen>
    <InstructionsPage />
  </ProtectedScreen>
);

export { InstructionsScreen };
